import { PageProps } from "gatsby";
import * as React from "react"
// import { graphql } from "gatsby"
import Layout from "../layout"
import Page from "./page"

// TODO: uncomment once migration away from @reflexjs is complete
// export const query = graphql`
//   query($id: String) {
//     page(id: { eq: $id }) {
//       ...PageFragment
//     }
//   }
// `

type Props = PageProps<GatsbyTypes.PageQuery>;

const PageTemplate = ({ data }: Props): React.ReactElement => (
  <Layout pathname={data?.page?.slug}>
    {data.page ? <Page {...data.page} /> : null}
  </Layout>
)
export default PageTemplate


/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { graphql, PageProps } from "gatsby";
import { GlossaryTerm } from "../types";
import GlossaryPage from "../templates/glossary";

export type GlossaryQueryData = {
  terms: { nodes: GlossaryTerm[] };
};

export const pageQuery = graphql`
  {
    terms: allGlossaryTerm(sort: { fields: [term], order: [ASC] }) {
      nodes {
        ...GlossaryTermFragment
      }
    }
  }
`;

// Separate query definition from template implementation for easier shadowing without Gatsby runtime warnings.

const GlossaryQuery: React.FC<PageProps<GlossaryQueryData>> = (props) => <GlossaryPage {...props} />;

export default GlossaryQuery;

import _ from "lodash";
import React, { useContext } from "react";
import { IChildImageSharpParent, ImageDataLike } from "../util/get-image";

export type File = IChildImageSharpParent & {
  relativePath: string;
  url?: string;
};

type ImagesContextType = {
  // Map of relative path to image
  images: Map<string, File>;
};
export const ImagesContext = React.createContext({ images: new Map() } as ImagesContextType);

export const useImages = () => useContext(ImagesContext);

export const useImage = (filter: File | string | undefined): File | undefined => {
  const { images } = useImages();
  if (filter === undefined) {
    return undefined;
  }

  const post = _.isString(filter) ? images.get(filter) : filter;
  if (!post) {
    console.error(`useImage: No image found: ${String(filter)}`);
  }
  return post;
};

interface Props {
  images?: readonly File[];
}

export const ImagesProvider: React.FC<Props> = ({ images = [], children }) => (
  <ImagesContext.Provider
    value={{
      images: new Map(_.map(images, (image) => [image.url ?? image.relativePath, image])),
    }}
  >
    {children}
  </ImagesContext.Provider>
);

export const ImagesConsumer = ImagesContext.Consumer;

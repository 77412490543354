/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Link } from "gatsby-theme-themed-gatsby-link";
import _ from "lodash";
import React from "react";
import { Text, jsx } from "theme-ui";

type DisplayNameAndLink = [string, string?];
type License = "cc-by-sa-4.0";

const licenses: Record<License, DisplayNameAndLink> = {
  "cc-by-sa-4.0": ["CC BY-SA 4.0", "https://creativecommons.org/licenses/by-sa/4.0/legalcode"],
};

export type AttributionProps = {
  prefix?: React.ReactNode;
  creator?: string | DisplayNameAndLink;
  source?: string | DisplayNameAndLink;
  license?: License | DisplayNameAndLink;
  className?: string;
  rel?: string;
};

const DisplayNameLink: React.FC<{ nameAndLink: DisplayNameAndLink; variant: string; rel?: string }> = ({
  nameAndLink: [displayName, link],
  variant,
  rel,
}) => {
  const displayNameContent = <Text variant={variant}>{displayName}</Text>;
  return link ? <Link href={link} rel={rel}>{displayNameContent}</Link> : <>{displayNameContent}</>;
};

export const Attribution: React.FC<AttributionProps> = ({
  prefix = "Credit: ",
  creator,
  source,
  license,
  rel,
  className,
}) => {
  const creatorNameLink: DisplayNameAndLink | undefined = _.isString(creator) ? [creator] : creator;
  const sourceNameLink: DisplayNameAndLink | undefined = _.isString(source) ? [source] : source;
  const licenseNameLink: DisplayNameAndLink | undefined = _.isString(license) ? licenses[license] : license;

  return (
    <Text className={className}>
      {prefix}
      {creatorNameLink && <DisplayNameLink nameAndLink={creatorNameLink} variant="attribution_creator" rel={rel} />}
      {creatorNameLink && sourceNameLink && ", "}
      {sourceNameLink && <DisplayNameLink nameAndLink={sourceNameLink} variant="attribution_source" rel={rel} />}
      {(creatorNameLink || sourceNameLink) && licenseNameLink && ", "}
      {licenseNameLink && <DisplayNameLink nameAndLink={licenseNameLink} variant="attribution_license" rel={rel} />}
    </Text>
  );
};

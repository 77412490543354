import React from "react";
import { Organization } from "schema-dts";
import { helmetJsonLdProp } from "react-schemaorg";
import { buildAbsoluteURL } from "url-toolkit";
import { Helmet } from "react-helmet-async";
import siteConfig from "../../../data/site-config";

const OrganizationJsonLd: React.FC = () => (
  <Helmet
    script={[
      helmetJsonLdProp<Organization>({
        "@context": "https://schema.org",
        "@type": "Organization",
        "@id": buildAbsoluteURL(siteConfig.siteUrl, "#"),
        name: siteConfig.siteTitle,
        url: siteConfig.siteUrl,
        sameAs: [
          siteConfig.socialProfiles.facebook.profile,
          siteConfig.socialProfiles.twitter.profile,
          siteConfig.socialProfiles.pinterest.profile,
        ],
      }),
    ]}
  />
);

export default OrganizationJsonLd;

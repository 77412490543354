/* @jsx jsx */
import React from "react";
import { Box, IconButton, jsx } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";

type NavMenuPopupProps = {
  show: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

const NavMenuPopup: React.FC<NavMenuPopupProps> = ({ show, onClose, children }) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: "1000",
      bg: "background",
      p: "3",
      rounded: "sm",
      overflow: "auto", // "scroll",
      boxShadow: "3xl",
      border: "1px solid",
      borderColor: "border",
      borderTopColor: "primary",
      transform: `scale(${show ? 1 : 0.95})`,
      visibility: show ? "visible" : "hidden",
      opacity: show ? 1 : 0,
      transition: "all .15s ease-in",
      transformOrigin: "100% 0",
      maxHeight: "95vh",
    }}
  >
    {onClose && (
      <IconButton onClick={onClose} sx={{ float: "right", size: 4, ml: 4, alignItems: "start", color: "heading" }}>
        <FontAwesomeIcon icon={faWindowClose} sx={{ mr: -3 }} />
      </IconButton>
    )}
    {children}
  </Box>
);

export default NavMenuPopup;

import { filterProducts } from "./utils/filter-products";
import { useProductsContext } from "./components/products-context";
import { isProduct, isProductArray, Product, ProductBase, ProductsOrSelector } from "./types";

// eslint-disable-next-line import/prefer-default-export
export function useProducts<TProduct extends ProductBase = Product>(
  productsOrFilters?: ProductsOrSelector<TProduct>,
  allowMissing = false
): readonly (Product | TProduct)[] {
  const productsContext = useProductsContext();
  if (isProductArray<Product>(productsOrFilters) || isProductArray<TProduct>(productsOrFilters)) {
    return productsOrFilters;
  }
  if (isProduct(productsOrFilters)) {
    return [productsOrFilters];
  }

  const result = filterProducts<Product | TProduct>(productsContext, productsOrFilters);
  if (!allowMissing && result.length === 0 && productsOrFilters !== undefined) {
    // eslint-disable-next-line no-console
    console.error(`useProduct: No product found: ${String(productsOrFilters)}`);
  }
  return result;
}

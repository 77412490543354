/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Flex, jsx, Text } from "theme-ui";
import React, { ReactNode } from "react";
import _ from "lodash";
import wrapPureProductComponent from "./wrap-pure-product-component";
import { NamedProduct, ProductPropsFrom, PureProductProps } from "../types";
import { ProductImageLink } from "./product-image-link";
import { CheckProduct } from "./check-product";
import { useRoundupContext } from "./roundup-context";
import { describeFields } from "../describe-fields";
import { ProductProvider } from "./product-context";
// import getProductFieldDescriptions, { PropertyDescriptions } from "./product-field-descriptions";

export type PureProductSummaryBoxProps = PureProductProps<NamedProduct> & {
  useImage?: boolean;
  summaryFields?: readonly string[];
};

const nbsp = String.fromCharCode(160);

const SummaryField: React.FC<{ label: ReactNode; value: ReactNode }> = ({ label, value }) => (
  <>
    <Text variant="product_summary_label">{_.isString(label) ? label.replace(" ", nbsp) : label}: </Text>
    <Text variant="product_summary_value">{value}</Text>
  </>
);

const SummaryFields: React.FC<
  Pick<PureProductSummaryBoxProps, "productData" | "summaryFields"> & { className?: string }
> = ({ productData, className, summaryFields }) => {
  const renderedFields = describeFields(
    productData ?? [],
    (key, label, [value]) => <SummaryField key={key} label={label} value={value} />,
    { fields: summaryFields }
  );

  return renderedFields.length > 0 ? (
    <Text className={className}>{renderedFields.reduce((prev, curr) => [prev, `${nbsp}| `, curr])}</Text>
  ) : null;
};

export const PureProductSummaryBox: React.FC<PureProductSummaryBoxProps> = ({
  productData,
  summaryFields,
  useImage = true,
}) => {
  if (!productData) {
    return null;
  }

  const image = useImage ? (
    <ProductImageLink key="image" sx={{ flex: 3, img: { height: "auto", width: "100%" } }} />
  ) : null;

  return (
    <ProductProvider product={productData}>
      <Flex sx={{ width: "100%", mb: 8, flexDirection: ["column", "row"], alignItems: "center" }}>
        {image}
        <Flex
          sx={{ width: "100%", height: "100%", flex: 2, flexDirection: ["column"], gap: [4, 12], alignItems: "center" }}
        >
          <SummaryFields sx={{ flex: 1 }} productData={productData} summaryFields={summaryFields} />
          <CheckProduct sx={{ flex: 1 }} />
        </Flex>
      </Flex>
    </ProductProvider>
  );
};

export type ProductSummaryBoxProps = ProductPropsFrom<PureProductSummaryBoxProps>;
export const ProductSummaryBox: React.FC<ProductSummaryBoxProps> = wrapPureProductComponent(
  PureProductSummaryBox,
  ({ summaryFields, ...props }) => {
    const { state } = useRoundupContext() ?? {};
    return { ...props, summaryFields: summaryFields ?? state?.summaryFields ?? [] };
  }
);

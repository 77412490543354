/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { FAQPage, Question } from "schema-dts";
import { JsonLd } from "react-schemaorg";
import _ from "lodash";
import { useMDXComponents } from "@mdx-js/react";
import { MDXProvider, MDXProviderComponents } from "@theme-ui/mdx";
import { FAQ } from "../types";
import { SchemaMarkupProvider } from "./schema-markup-context";

const faqToQuestion = (faq: FAQ, mdxCommponents: MDXProviderComponents, Render: React.FC): Question => ({
  "@type": "Question",
  name: faq.title,
  acceptedAnswer: {
    "@type": "Answer",
    text: ReactDOMServer.renderToStaticMarkup(
      <MDXProvider components={mdxCommponents}>
        <Render>
          <SchemaMarkupProvider inSchemaMarkup>{faq.body}</SchemaMarkupProvider>
        </Render>
      </MDXProvider>
    ),
  },
});

type Props = {
  faqs: readonly FAQ[];
  render?: React.FC;
};

const FAQJsonLd: React.FC<Props> = ({ faqs, render = ({ children }) => <>{children}</> }) => {
  // Because we render the body to static markup, we need to explicitly provide whatever context it needs.
  const mdxCommponents = useMDXComponents();

  return (
    <JsonLd<FAQPage>
      item={{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: _.map(faqs, (faq) => faqToQuestion(faq, mdxCommponents, render)),
      }}
    />
  );
};

export default FAQJsonLd;

/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import _ from "lodash";

const fonts = {
  arvo: ["latin-400-normal", "latin-700-normal"],
  cabin: ["latin-400-normal", "latin-700-normal"],
  "open-sans": ["latin-400-normal", "latin-600-normal", "latin-700-normal"],
};

/*
  This relies on Asset Optimization on Netlify being turned OFF. Otherwise, Netlify will push the font
  files to Cloudfront and rewrite CSS, and the URLs we preload don't match the URLs actually being used anymore.
*/

const PreloadFonts = (): React.ReactElement => (
  <Helmet>
    {_.map(fonts, (variants, font) =>
      _.map(variants, (variant) => {
        // eslint-disable-next-line import/no-dynamic-require,global-require,@typescript-eslint/no-var-requires
        const url = require(`./files/${font}-${variant}.woff2`).default;
        return <link rel="preload" href={url} as="font" crossOrigin="anonymous" type="font/woff2" />;
      })
    )}
  </Helmet>
);

export default PreloadFonts;

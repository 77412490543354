/** @jsx jsx */
import { jsx, SxProp } from "theme-ui";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import React from "react";

export type Props = {
  alt: string;
  imageUrl?: string | undefined;
  imageFile?: ImageDataLike | undefined;
  className?: string;
} & SxProp;

const ArticleImage: React.FC<Props> = ({ imageUrl, imageFile, alt, className }) => {
  let image = null;

  if (imageFile) {
    const imageData = getImage(imageFile);
    if (!imageData) throw Error("ArticleImage: Failed to getImage from imageFile.");

    image = <GatsbyImage image={imageData} alt={alt} />;
  } else if (imageUrl) {
    image = <img src={imageUrl} alt={alt} sx={{ minWidth: "50%" }} />;
  }

  return image ? (
    <figure className={className} sx={{ margin: "0" }}>
      {image}{" "}
    </figure>
  ) : null;
};

export default ArticleImage;

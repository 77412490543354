export interface MerchantConfig {
  displayName?: string;
  affiliateID?: string;
}
export interface PluginConfig {
  merchants?: Record<string, MerchantConfig>;
  productsPerCompareGroup?: number | undefined;
  sourceType?: string | string[];
  imagesSubdirectory?: string;
  propagateProductsTo?: string[];
  additionalTags?: string[];
}

export const withDefaults = (pluginOptions?: PluginConfig): Required<PluginConfig> => ({
  merchants: {},
  productsPerCompareGroup: Number.POSITIVE_INFINITY,
  sourceType: [],
  imagesSubdirectory: "products",
  propagateProductsTo: [],
  additionalTags: [],
  ...pluginOptions,
});

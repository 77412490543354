/* eslint-disable import/prefer-default-export */
import { useStaticQuery, graphql } from "gatsby";
import { ReallyRequired } from "../utils/types";

export type SiteMetadata = ReallyRequired<GatsbyTypes.SiteSiteMetadata>

export const useSiteMetadata = (): SiteMetadata => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const { siteMetadata } = data.site;

  if (!siteMetadata.title) {
    throw new Error("siteMetadata.title is not configured.");
  }
  if (!siteMetadata.description) {
    throw new Error("siteMetadata.title is not configured.");
  }
  if (!siteMetadata.description) {
    throw new Error("siteMetadata.title is not configured.");
  }
  return siteMetadata;
};

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { PostBase } from "../types";
import { usePost } from "./posts-context";
import { PostLink } from "./post-link";
import { Callout } from "./callout";

export type PureRelatedPostProps = {
  className?: string;
  prefix?: string;
  post: PostBase;
  variant?: string;
};

export const PureRelatedPost: React.FC<PureRelatedPostProps> = ({
  className,
  post,
  prefix = "Related:",
  variant = "related_post",
}) => (
  <Callout className={className} variant={variant} prefix={prefix}>
    <PostLink className="related_post_link" post={post} />
  </Callout>
);

export type RelatedPostProps = {
  className?: string;
  prefix?: string;
  post: PostBase | string;
  variant?: string;
};

export const RelatedPost: React.FC<RelatedPostProps> = ({ post: postOrSlug, ...props }) => {
  const post = usePost(postOrSlug);
  if (!post) {
    return null;
  }
  return <PureRelatedPost post={post} {...props} />;
};

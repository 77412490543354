/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { useProduct } from "../use-product";
import { Product } from "../types";

// type FieldNames<T> = {
//   [K in keyof T]: T[K] extends GatsbyTypes.Maybe<ReadonlyArray<string | number | undefined>> ? K : never;
// }[keyof T];

export interface ProductFieldProps {
  product?: string;
  field: keyof Product;
}

export const ProductField: React.FC<ProductFieldProps> = ({ product, field, children }) => {
  const data = useProduct(product)?.[field];
  if (!data) {
    return null;
  }

  return (
    <>
      {children}
      {data}
    </>
  );
};

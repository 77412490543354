/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import _ from "lodash";
import { Link } from "gatsby-theme-themed-gatsby-link";
import { ProductName, ProductNameType } from "./product-name";
import {
  isNamedProduct,
  LinkableProduct,
  ProductID,
  productIDForMerchant,
  ProductPropsFrom,
  PureProductProps,
} from "../types";
import wrapPureProductComponent from "./wrap-pure-product-component";

export type PureProductLinkProps = PureProductProps<LinkableProduct> & {
  nameType?: ProductNameType;
  merchant?: string;
  className?: string;
  variant?: string;
};

function firstValidProductID(ids: readonly ProductID[] = []): ProductID | undefined {
  return _.find(ids, (id) => id.productID !== undefined);
}

const productURL = ({ merchant: { key: merchantKey, affiliateID }, productID }: ProductID) => {
  let url: URL;

  switch (merchantKey) {
    case "amazon":
      url = new URL(`https://www.amazon.com/dp/${productID}`);
      url.searchParams.append("ref", "nosim");
      if (affiliateID) {
        url.searchParams.append("tag", affiliateID);
      }
      break;

    default:
      throw new Error(`Unhandled merchant: ${merchantKey}`);
  }

  return url;
};

export const PureProductLink: React.FC<PureProductLinkProps> = ({
  productData,
  merchant,
  nameType,
  children,
  className,
  variant = "product_link",
}) => {
  if (!children) {
    if (!isNamedProduct(productData)) {
      console.error("If children not explicitly specified, product must be a NamedProduct.");
      return null;
    }

    // eslint-disable-next-line no-param-reassign
    children = <ProductName product={productData} nameType={nameType} />;
  }

  const productID = merchant ? productIDForMerchant(productData?.ids, merchant) : firstValidProductID(productData?.ids);
  if (!productID) {
    console.warn(`ProductLink: no retailer IDs found for product: ${productData?.key}`);
    return <span className={className}>{children}</span>;
  }

  const url = productURL(productID);
  return (
    <span className={className}>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <Link to={url.href} target="_blank" rel="sponsored nofollow noopener" variant={variant}>
        {children}
      </Link>
    </span>
  );
};

export type ProductLinkProps = ProductPropsFrom<PureProductLinkProps, LinkableProduct>;
export const ProductLink: React.FC<ProductLinkProps> = wrapPureProductComponent(PureProductLink);

/** @jsx jsx */
import { Box, jsx, Text } from "theme-ui";
import React from "react";
import { PropsOf } from "@emotion/react";
import { Attribution } from "./attribution";

type VariantPropsOf<C extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>> = PropsOf<C> & {
  variant?: string;
  children?: React.ReactNode;
};

export type FigureProps = VariantPropsOf<typeof Box>;
export const Figure = React.forwardRef<HTMLDivElement, FigureProps>(({ variant = "default", ...props }, ref) => (
  <Box as="figure" ref={ref} variant={`figures.${variant}`} {...props} />
));

export type FigCaptionProps = VariantPropsOf<typeof Text>;
export const FigCaption = React.forwardRef<HTMLDivElement, FigCaptionProps>(
  ({ children, variant = "figcaption", ...props }, ref) => (
    <Text as="figcaption" ref={ref} sx={{ display: "block" }} variant={variant} {...props}>
      {children}
    </Text>
  )
);

export type FigAttributionProps = VariantPropsOf<typeof Attribution>;
export const FigAttribution = React.forwardRef<HTMLDivElement, FigAttributionProps>((props, ref) => (
  <FigCaption ref={ref}>
    <Attribution {...props} />
  </FigCaption>
));

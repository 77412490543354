import { isNetlifyProduction } from "../src/utils/netlify-env";

const config = {
  siteTitle: "Toolbox Trends", // Site title.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://toolboxtrends.com", // Domain of your website without pathPrefix.
  siteDescription:
    "The Right Tool for Every Project! Our informative guides and unbiased, no-nonsense reviews will help you confidently equip your shop, no matter the budget.", // Website description used for RSS feeds/meta description tag.
  //   siteRss: "/rss.xml", // Path to the RSS file.
  //   siteRssTitle: "Toolbox Trends RSS feed", // Title of the RSS feed
  //   siteFBAppID: "TODO", // FB Application ID for using app insights
  googleTagManagerID: "GTM-KB6KF54",
  //   disqusShortname: "", // Disqus shortname.
  //   dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  //   dateFormat: "DD/MM/YYYY", // Date format for display.
  //   postsPerPage: 9, // Amount of posts displayed per listing page.
  //   copyright: "Copyright © 2020 Toolbox Trends", // Copyright string for the footer of the website and RSS feed.
  merchants: {
    amazon: {
      affiliateID: isNetlifyProduction ? "toolboxtr-20" : "toolboxtr-20-DEVELOPMENT",
    },
  },
  socialProfiles: {
    facebook: { profile: "https://facebook.com/toolboxtrends", appID: null },
    twitter: { profile: "https://twitter.com/toolboxtrends", siteUsername: "@ToolboxTrends", creatorUsername: null },
    pinterest: { profile: "https://www.pinterest.com/toolboxtrends/" },
  },
};

// Validate

// Make sure pathPrefix is empty if not needed
// if (config.pathPrefix === "/") {
//   config.pathPrefix = "";
// } else {
//   // Make sure pathPrefix only contains the first forward slash
//   config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
// }

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/") config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
// if (config.siteRss && config.siteRss[0] !== "/") config.siteRss = `/${config.siteRss}`;

export default config;

import React from "react";
import { Article } from "schema-dts";
import { helmetJsonLdProp } from "react-schemaorg";
import { buildAbsoluteURL } from "url-toolkit";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import siteConfig from "../../../data/site-config";

export type ArticleJsonLdProps = {
  about?: string | string[];
  date?: string;
  title: string;
};

const ArticleJsonLd: React.FC<ArticleJsonLdProps> = ({ about, date, title }) => (
  <Helmet
    script={[
      helmetJsonLdProp<Article>({
        "@context": "https://schema.org",
        "@type": "Article",
        headline: title,
        datePublished: date,
        publisher: {
          "@id": buildAbsoluteURL(siteConfig.siteUrl, "#"),
        },
        about: _.map(_.isArray(about) ? about : [about], (a) => ({ "@type": "Thing", name: a })),
      }),
    ]}
  />
);

export default ArticleJsonLd;

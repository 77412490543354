import { Theme, ThemeUIStyleObject } from "theme-ui";
import { TypographyOptions } from "typography";
import { toTheme } from "@theme-ui/typography";
import deepmerge from "deepmerge";

const typography: TypographyOptions = {
  baseFontSize: "17px",
  baseLineHeight: 1.625, // relaxed
  bodyFontFamily: ["Open Sans", "sans-serif"],
  headerFontFamily: ["Arvo", "sans-serif"],
  headerWeight: 700,
  googleFonts: [],
  scaleRatio: 3,
};
const typographyTheme = toTheme(typography);

export const palette = {
  black: "#1A1B1C",
  blue: "#1670A0",
  darkGray: "#4C5C68",
  deepSea: "#157A6E",
  forestGreen: "#228B22",
  green: "#0F0",
  lightBlue: "#3AA8E4",
  lightestBlue: "#EDF7FD",
  lightestGray: "#EEE",
  lightGray: "#DCDCDD",
  midBlue: "#81C8EE",
  red: "#F00", // TODO better red
  redKite: "#913228",
  white: "#FFF",
};

const colors = {
  ...palette,
  text: palette.black,
  textLighter: "#777",
  background: palette.white,
  backgroundTinted: palette.lightestBlue,
  heading: palette.blue,
  primary: palette.blue,
  primaryHover: palette.lightGray,
  primaryText: palette.lightestGray,
  secondary: palette.lightBlue,
  secondaryHover: palette.lightGray,
  secondaryText: palette.blue,
  accent: palette.deepSea,
  accentHover: palette.lightGray,
  selection: palette.lightGray, // "#ff70a0",
  muted: palette.darkGray,
  mutedBorder: palette.lightGray,
  mutedHover: "#ebebeb",
  border: palette.midBlue,
  borderHover: palette.lightGray,
  input: palette.lightestGray,
  errorText: palette.red,
  okay: palette.forestGreen,
  notOkay: palette.redKite,
  prism: {
    text: "#d6deeb",
    lineNumber: "#88a0d6",
    background: "#031528",
    comment: "#93b4b4",
    string: "#addb67",
    var: "#d6deeb",
    number: "#f78c6c",
    constant: "#82aaff",
    punctuation: "#c792ea",
    className: "#ffcb8b",
    tag: "#7fdbca",
    boolean: "#ff5874",
    property: "#80cbc4",
    namespace: "#b2ccd6",
    highlight: "#243E73",
    file: "#92B5B2",
  },
  modes: {
    dark: {
      black: palette.black,
      text: palette.lightGray,
      background: palette.black,
      heading: palette.blue,
      primary: palette.blue,
      primaryHover: palette.lightGray,
      secondary: "#07070a",
      secondaryHover: "#191924",
      accent: "#2B59C3",
      accentHover: "#253C78",
      muted: "#2a2a3c",
      mutedHover: "#212130",
      border: "#2a2a3c",
      borderHover: "#434360",
    },
  },
};

const paragraphMargin = 7;

const theme: Theme & { figures?: Record<string, ThemeUIStyleObject> } = {
  colors,
  // breakpoints: ["640px", "768px", "1024px", "1280px"],
  breakpoints: ["640px", "768px", "1024px", "1110px"],
  fonts: {
    body: "'Open Sans', sans-serif",
    // heading: "'Arvo', sans-serif",
    heading: "'Cabin', 'Open Sans', sans-serif",
    monospace: "SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
  },
  fontSizes: {
    // TODO: settle on numeric fontSizes (theme-ui) or strings (leftover from reflexjs), but having both is confusing
    ...typographyTheme.fontSizes,
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeights: {
    none: "1",
    tightest: "1.1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    // relaxed: "1.625",
    relaxed: "1.625", // TODO probably should be "1.75",
    loose: "2",
    3: ".75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
  },
  space: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    18: "4.5rem",
    20: "5rem",
    22: "5.5rem",
    24: "6rem",
    26: "6.5rem",
    28: "7rem",
    30: "7.5rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
  },
  sizes: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    18: "4.5rem",
    20: "5rem",
    22: "5.5rem",
    24: "6rem",
    26: "6.5rem",
    28: "7rem",
    30: "7.5rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    auto: "auto",
    half: "50%",
    full: "100%",
    screen: "100vw",
    container: 1110,
  },
  borders: [
    0,
    "1px solid",
    "2px solid",
    "3px solid",
    "4px solid",
    "5px solid",
    "6px solid",
    "7px solid",
    "8px solid",
    "9px solid",
    "10px solid",
  ],
  radii: {
    none: "0",
    sm: "0.125rem",
    md: "0.25rem",
    lg: "0.375rem",
    xl: "0.5rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    "4xl": "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    md: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    lg: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    xl: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    "2xl": "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "3xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
  },
  styles: {
    root: {
      color: "text",
      p: {
        mb: paragraphMargin,
        ":last-child": {
          mb: 0,
        },
      },
      li: {
        mb: 0,
      },
      "p + ul, p + ol": {
        // Pull lists closer to the preceding paragraph, if any.
        mt: -4,
      },
      ".gatsby-img-attributes": {
        // m: 4,
        // border: 1,
      },
    },
    a: {
      // This is the base style for <Link>; variants are defined in "links" section below.
      color: "primary",
      textDecoration: "none",
      "&:hover, &:focus, &:active": {
        color: "secondary",
      },
      "&[aria-current=page]": {},
      // "&::before": { content: '"styles.a"' },
    },
    pre: {
      variant: "text.pre",
    },
    code: {
      variant: "text.code",
    },
    heading: {
      color: "heading",
      fontFamily: "heading",
      fontWeight: "bold",
      lineHeight: "relaxed",
      letterSpacing: "normal",
      // Safari doesn't support scroll-padding-top the same as other browsers, so force headings to appear below sticky header.
      pt: "3em",
      mt: "-3em",
      // "&::before": { content: '"styles.heading"' },
      "div + &, section + &": {
        mt: 7,
      },
    },
    h1: {
      variant: "styles.heading",
      // fontSize: [4, 4, 5],
      fontFamily: "Arvo, serif",
      pt: "0",
      mt: "0",
      // "&::before": { content: '"styles.h1"' },
      counterReset: "h2counter",
      "&.counted::before": {
        content: 'counter(h1counter) ".\\0000a0\\0000a0"',
      },
    },
    h2: {
      variant: "styles.heading",
      // fontSize: [3, 3, 4],
      // "&::before": { content: '"styles.h2"' },
      counterReset: "h3counter",
      counterIncrement: "h2counter",
      "&.counted::before": {
        content: 'counter(h2counter) ".\\0000a0\\0000a0"',
      },
    },
    h3: {
      variant: "styles.heading",
      // fontSize: [3, 3, 3],
      // fontSize: ["xl", "2xl", "3xl"],
      // "&::before": { content: '"styles.h3"' },
      counterReset: "h4counter",
      counterIncrement: "h3counter",
      "&.counted::before": {
        content: 'counter(h3counter) ".\\0000a0\\0000a0"',
      },
    },
    h4: {
      variant: "styles.heading",
      // fontSize: ["lg", "xl", "2xl"],
      // "&::before": { content: '"styles.h4"' },
      counterReset: "h5counter",
      counterIncrement: "h4counter",
      "&.counted::before": {
        content: 'counter(h4counter) ".\\0000a0\\0000a0"',
      },
    },
    h5: {
      variant: "styles.heading",
      // fontSize: ["md", "lg", "xl"],
      // "&::before": { content: '"styles.h5"' },
      counterReset: "h6counter",
      counterIncrement: "h5counter",
      "&.counted::before": {
        content: 'counter(h5counter) ".\\0000a0\\0000a0"',
      },
    },
    h6: {
      variant: "styles.heading",
      // fontSize: ["sm", "md", "lg"],
      // "&::before": { content: '"styles.h6"' },
      counterIncrement: "h6counter",
      "&.counted::before": {
        content: 'counter(h6counter) ".\\0000a0\\0000a0"',
      },
    },
    ul: {
      padding: 0,
      listStylePosition: "outside",
      margin: undefined, // unset margin set by typography
      ml: 7,
    },
    inlineCode: {
      variant: "text.pre",
      color: "primary",
    },
    stickyTop: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
    },
    ProductSummaryTable: {
      variant: "styles.ProductTable",
      width: "80%",
    },
    ProductTable: {
      "td, th": {
        // borderColor: palette.lightGray,
        border: "0px",
        fontSize: "sm",
        padding: 2,
        verticalAlign: "top",
        // theme-ui's typography package sets pl: 0 for these:
        ":first-of-type": {
          pl: 2,
        },
        ":last-of-type": {
          pr: 2,
        },
      },
      th: {
        fontWeight: 600,
      },
      "tbody th": {
        textAlign: "left",
      } as const, // to treat "left" as a literal
      "tbody td, thead th, thead td": {
        textAlign: "center",
      } as const, // to treat "center" as a literal
      "tbody td, tbody th, thead th:nth-of-type(1n+2), thead td, tfoot td:nth-of-type(1n+1)": {
        // border: "1px solid",
        // borderColor: palette.lightGray,
        verticalAlign: "middle",
      },
      "tbody tr:nth-of-type(odd)": {
        background: palette.lightestGray,
      },
      "tbody tr:nth-of-type(even)": {
        background: palette.white,
      },
      ul: {
        display: "inline-block",
        listStylePosition: "outside",
        margin: "0",
      },
      li: {
        marginLeft: "6",
        textAlign: "left",
      },
    },
    PagesNavMenu: {
      gap: [0, 1],
      textTransform: "uppercase",
      ml: 0,
    },
    comparison_link: {
      borderBottom: "1px dashed",
      borderBottomColor: "primary",
    },
    glossary_term: {
      borderBottom: "1px dashed",
      borderBottomColor: "primary",
      ".tooltip-container": {
        "--tooltipBackground": colors.backgroundTinted,
        "--tooltipBorder": colors.border,
        fontSize: "sm",
        borderRadius: "5px",
        maxWidth: ["80%", "50%"],
      },
    },
  },
  layout: {
    container: {
      px: [4, 6, 6, 4],
      mx: "auto",
      // maxWidth: 1280,
      // maxWidth: 1110,
    },
  },
  text: {
    lead: {
      fontSize: "xl",
    },
    code: {
      fontFamily: "monospace",
    },
    pre: {
      color: "prism.text",
      backgroundColor: "prism.background",
      fontFamily: "monospace",
      fontSize: "sm",
      lineHeight: 7,
      tabSize: 2,
      hyphens: "none",
      my: 8,
      py: 4,
      overflow: "auto",
      borderRadius: "md",
      whiteSpace: "pre-wrap",
      ".plain": {
        minHeight: "1ch",
        display: "inline-block",
      },
      ".attr-name": {
        fontStyle: "italic",
      },
      ".comment": {
        color: "prism.comment",
      },
      ".attr-name, .string, .url": {
        color: "prism.string",
      },
      ".variable": {
        color: "prism.var",
      },
      ".number": {
        color: "prism.number",
      },
      ".builtin, .char, .constant, .function": {
        color: "prism.constant",
      },
      ".punctuation, .selector, .doctype": {
        color: "prism.punctuation",
      },
      ".class-name": {
        color: "prism.className",
      },
      ".operator, .keyword": {
        color: "prism.tag",
      },
      ".boolean": {
        color: "prism.boolean",
      },
      ".property": {
        color: "prism.property",
      },
      ".namespace": {
        color: "prism.namespace",
      },
      ".highlight": {
        bg: "prism.highlight",
      },
    },
    attribution_creator: {
      fontWeight: "semibold",
    },
    attribution_source: {
      fontStyle: "italic",
    },
    figcaption: {
      fontSize: "xs",
      textAlign: "center",
      m: 2,
      mt: 4,
    },
    product_summary_label: {
      fontWeight: "semibold",
      fontSize: "sm",
    },
    product_summary_value: {
      fontSize: "sm",
    },
    callout: {
      fontFamily: "heading",
      mx: 4,
      ".callout_prefix": {
        fontSize: "md",
        // fontWeight: "semibold",
        textTransform: "uppercase",
        mr: 1,
      },
      "& + &": {
        // Multiple related posts in a row should not appear double-spaced
        mt: -paragraphMargin,
      },
    },
    jump_to: {
      variant: "text.callout",
      // a: {
      //   fontWeight: "semibold",
      //   fontSize: "lg",
      // },
      ul: {
        display: "inline",
        px: 0,
        listStyleType: "none",
        fontFamily: "body",
      },
      li: {
        display: "inline",
        "&:after": { px: "2", content: `"·"`, fontSize: "sm", verticalAlign: "top" },
        "&:last-of-type:after": { content: '""', px: "0" },
      },
    },
    related_post: {
      variant: "text.callout",
      ".related_post_link": {
        fontWeight: "semibold",
        fontSize: "lg",
      },
    },
  },

  buttons: {
    sm: {
      px: 3,
      py: 2,
      fontSize: ["xs", "sm", "md"],
    },

    lg: {
      px: 5,
      py: 4,
      fontSize: ["md", "lg", "xl"],
    },

    primary: {
      bg: "primary",
      // color: "white",
      color: "black",
      borderColor: "primary",

      "&:hover, &:focus": {
        bg: "primaryHover",
        color: "black",
        borderColor: "primaryHover",
      },
    },

    outlinePrimary: {
      bg: "transparent",
      color: "primary",
      borderColor: "primary",

      "&:hover, &:focus": {
        bg: "primaryHover",
        color: "white",
        borderColor: "primaryHover",
      },
    },

    secondary: {
      bg: "secondary",
      color: "white",
      borderColor: "secondary",

      "&:hover, &:focus": {
        bg: "secondaryHover",
        color: "white",
        borderColor: "secondaryHover",
      },
    },

    outlineSecondary: {
      bg: "transparent",
      color: "secondary",
      borderColor: "secondary",

      "&:hover, &:focus": {
        bg: "secondaryHover",
        color: "white",
        borderColor: "secondaryHover",
      },
    },

    accent: {
      bg: "accent",
      color: "text",
      borderColor: "accent",

      "&:hover, &:focus": {
        bg: "accentHover",
        color: "text",
        borderColor: "accentHover",
      },
    },

    outlineAccent: {
      bg: "transparent",
      color: "accent",
      borderColor: "accent",

      "&:hover, &:focus": {
        bg: "accentHover",
        color: "white",
        borderColor: "accentHover",
      },
    },

    link: {
      bg: "transparent",
      color: "text",
      borderColor: "transparent",
      display: "flex",

      "&:hover, &:focus": {
        // bg: "muted",
        bg: "transparent",
        color: "text",
        borderColor: "transparent",
      },
    },

    icon: {
      bg: "transparent",
      borderColor: "transparent",
      p: 0,
    },
    "&:disabled": {
      backgroundColor: "muted",
    },
  },
  cards: {},
  links: {
    // Allow <Link variant="default"/> to be a synonmym for (harder-to-remember) variant="styles.a".
    //  Make any desired changes to "styles.a" rather than "default".
    default: { variant: "styles.a" },
    nav_menu: {
      textDecoration: "none",
      fontSize: "sm",
      py: 0,
      color: "text",
      "&:hover": {
        textShadow: "0 0 .65px, 0 0 .65px",
        color: "text",
      },
    },
    product_link: {
      variant: "links.default",
      fontWeight: "semibold",
      "&:hover": { textDecoration: "underline" },
    },
  },
  figures: {
    default: {
      mb: 7,
      // Note: Output from gatsby-remark-image[-attributes] is of the shape:
      //
      // <span class="gatsby-img-attributes">      [Not present if no attributes were parsed by g-r-i-a.]
      //   <span class="gatsby-resp-image-wrapper">
      //     <span class="gatsby-resp-image-background-image">
      //     <img class="gatsby-resp-image-image">
      ".gatsby-img-attributes": {
        // backgroundColor: "red",
        mx: "auto",
      },
      ".gatsby-resp-image-image": {},
      // backgroundColor: "green",
    },
    centered: {
      variant: "figures.default",
      mx: "auto",
      textAlign: "center",
      width: "80%",
    },
    floatRight: {
      variant: "figures.default",
      boxShadow: ["none", "md"],
      float: ["none", "right"],
      mx: ["auto", 7],
      p: 2,
      maxWidth: ["80%", "calc(100% / 6)"],
      width: "fit-content",
    },
  },
};

// NOTE: The order of "variant" in a style matters, even though they are objects! It will override any properties set before it.
//  The default behavior of deepmerge preserves the order of the first object (which doesn't include any variants). So any
//  variants specified in our theme below will override any properties set by toTheme(), _even if_ those properties are
//  also overridden below. (In other words, the settings in our theme will be ignored.)
// Hack around this by first merging with our theme first and typographyTheme second, to set the order of fields. Then merge
//  the "right" way, with our theme overriding the settings from the typographyTheme.
// See: https://github.com/system-ui/theme-ui/issues/723#issuecomment-594014853
const mergedTheme = deepmerge(deepmerge(theme, typographyTheme), theme);

export default mergedTheme;

/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import _ from "lodash";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { productIDForMerchant, ProductPropsFrom, PureProductProps } from "../types";
import wrapPureProductComponent from "./wrap-pure-product-component";

export type PureProductImageProps = PureProductProps & {
  className?: string;
};

export const PureProductImage: React.FC<PureProductImageProps> = ({ productData, className }) => {
  // TODO move hook usage out of PureProductImage
  if (!productData) {
    return null;
  }

  let imageElement: any = null;
  const { image, name } = productData;

  const amazonProductId = productIDForMerchant(productData.ids, "amazon");
  if (image && image.childImageSharp) {
    const {
      childImageSharp: { gatsbyImageData },
    } = image;
    // TODO: support string URL images?
    // imageElement = <img className={className} src={image} alt={productData.name} sx={imageSx} />;
    const imageData = getImage(gatsbyImageData);
    if (!imageData) throw Error("ArticleImage: Failed to getImage from imageFile.");

    imageElement = <GatsbyImage image={imageData} alt={name} imgStyle={{ marginLeft: "auto", marginRight: "auto" }} />;
  } else if (amazonProductId) {
    if (!amazonProductId?.merchant.affiliateID) {
      console.warn("No affiliate ID found for merchant: amazon");
    }
    const asin = amazonProductId.productID;
    const sizes = [100, 200, 400, 800];
    const srcs = _.map(sizes, (size) => ({
      size,
      url: `https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${asin}&Format=_SL${size}_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=${amazonProductId?.merchant.affiliateID}&language=en_US`,
    }));

    const srcSet = _.join(
      _.map(srcs, ({ url, size }) => `${url} ${size}w`),
      ", "
    );

    const largest = _.last(sizes);
    imageElement = (
      <img
        className={className}
        src={srcs[0].url}
        alt={productData.name}
        // We don't know the aspect ratio for sure, but they're usually square
        height="100"
        width="100"
        srcSet={srcSet}
        sizes={`(min-width: ${largest}px) ${largest}px, 100vw`}
      />
    );
  }

  return imageElement ? <figure sx={{ margin: 1 }}>{imageElement}</figure> : null;
};

export type ProductImageProps = ProductPropsFrom<PureProductImageProps>;
export const ProductImage: React.FC<ProductImageProps> = wrapPureProductComponent(PureProductImage);

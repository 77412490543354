/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import * as React from "react";
import { GatsbySeo, GatsbySeoProps, OpenGraphImages } from "gatsby-plugin-next-seo";
import { StaticImage } from "gatsby-plugin-image";
import { buildAbsoluteURL } from "url-toolkit";
import { SiteMetadata, useSiteMetadata } from "../../sitemetadata";
import { SEOProps } from "./types";
import { getImage } from "../../utils/get-image";
import OrganizationJsonLd from "./organization-json-ld";

const defaultImage = (
  <StaticImage src="../../../content/images/canstockphoto6693997.jpg" alt="A variety of tools lying on a workbench." />
);
// TODO a more elegant way to get the imageData for a static image?
// eslint-disable-next-line no-underscore-dangle
const defaultImageData = defaultImage.props.__imageData;

function seoPropsToOpenGraphImages(
  siteMetadata: SiteMetadata,
  { image, imageFile = defaultImageData }: SEOProps
): OpenGraphImages[] {
  let openGraphImage: OpenGraphImages | undefined;
  if (image) {
    openGraphImage = { url: image };
  } else {
    const { images, ...imageData } = getImage(imageFile) || {};
    const src = images?.fallback?.src;
    if (src) {
      openGraphImage = { url: buildAbsoluteURL(siteMetadata.siteUrl, src), ...imageData };
    }
  }
  return openGraphImage ? [openGraphImage] : [];
}

function seoPropsToGatsbySeoProps(
  siteMetadata: SiteMetadata,
  {
    pathname,
    title,
    excerpt: description,
    // author,
    date,
    titleTemplate = "%s",
    ...props
  }: SEOProps
): GatsbySeoProps {
  const images = seoPropsToOpenGraphImages(siteMetadata, props);
  // TODO author support
  const gatsbySeoProps: GatsbySeoProps = {
    language: "en-US",
    ...(pathname && { canonical: buildAbsoluteURL(siteMetadata.siteUrl, pathname) }),
    title,
    titleTemplate,
    description,
    openGraph: {
      type: "article",
      article: {
        ...(date && { publishedTime: date, modifiedTime: date }),
      },
      images,
    },
  };

  return gatsbySeoProps;
}

const SEO = (props : SEOProps): React.ReactElement => {
  const siteMetadata = useSiteMetadata();
  const gatsbySeoProps = seoPropsToGatsbySeoProps(siteMetadata, props);

  return (
    <>
      <GatsbySeo {...gatsbySeoProps} />
      <OrganizationJsonLd />
    </>
  );
};

export default SEO;

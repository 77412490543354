/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import {
  Product,
  ProductPropsFrom,
  PureProductProps,
  getProductName,
  useReviewAlternatives,
  ProductLink,
} from "gatsby-plugin-products";
import wrapPureProductComponent from "gatsby-plugin-products/src/components/wrap-pure-product-component";
import { InlineFAQ } from "gatsby-theme-faq";
import _ from "lodash";

export type PureAlternativesFAQProps = PureProductProps & {
  alternativesData?: readonly Product[];
};

export const PureAlternativesFAQ: React.FC<PureAlternativesFAQProps> = ({ productData, alternativesData }) => {
  if (!productData || !alternativesData) {
    return null;
  }
  const productName = getProductName(productData);
  return productData && !_.isEmpty(alternativesData) ? (
    <InlineFAQ title={`What are alternatives to the ${productName}?`} render="hidden">
      Some similar products to the {productName} are:
      <ul>
        {_.map(alternativesData, (p) => (
          <li key={p.key}>
            <ProductLink product={p} />
          </li>
        ))}
      </ul>
    </InlineFAQ>
  ) : null;
};

export type AlternativesFAQProps = ProductPropsFrom<Omit<PureAlternativesFAQProps, "alternativesData">> & {};

export const AlternativesFAQ: React.FC<AlternativesFAQProps> = wrapPureProductComponent(
  PureAlternativesFAQ,
  (props) => {
    const alternativesData = useReviewAlternatives();
    if (_.isEmpty(alternativesData)) {
      return props;
    }
    return { ...props, alternativesData };
  }
);

const components = [
  "CheckProduct",
  "CompareProduct",
  "FullReviewLink",
  "ProductAlternatives",
  "ProductProvider",
  "ProductFieldList",
  "ProductField",
  "ProductImageLink",
  "ProductImage",
  "ProductLink",
  "ProductLinks",
  "ProductName",
  "ProductProsCons",
  "ProductSummaryBox",
  "ProductTable",
  "ProductsProvider",
  "Roundup",
  "ShortReview"
]

export default components
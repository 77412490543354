/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, Themed } from "theme-ui";
import React from "react";
import _ from "lodash";
import { Product, ProductPropsFrom, PureProductProps } from "../types";
import wrapPureProductComponent from "./wrap-pure-product-component";

type ArrayPropertyNames<T> = {
  [K in keyof T]: T[K] extends ReadonlyArray<string> | undefined ? K : never;
};
type ArrayProperties<T> = ArrayPropertyNames<T>[keyof T];

export type PureProductFieldListProps = PureProductProps & {
  field: ArrayProperties<Required<Omit<Product, "ids">>>;
};

export const PureProductFieldList: React.FC<PureProductFieldListProps> = ({ productData, field, children }) => {
  if (!productData || _.isEmpty(productData[field])) {
    return null;
  }

  return (
    <>
      {children}
      <Themed.ul>
        {productData[field]?.map((entry: string, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Themed.li key={index}>{entry}</Themed.li>
        ))}
      </Themed.ul>
    </>
  );
};

export type ProductFieldListProps = ProductPropsFrom<PureProductFieldListProps>;
export const ProductFieldList: React.FC<ProductFieldListProps> = wrapPureProductComponent(PureProductFieldList);

/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useEffect } from "react";
import { FullReviewLink } from "./full-review-link";
import { Paragraphs } from "./paragraphs";
import { ProductLink } from "./product-link";
import { ProductPropsFrom, PureProductProps } from "../types";
import { ProductProvider } from "./product-context";
import { DynamicHeading } from "./dynamic-heading";
import { ProductSummaryBox } from "./product-summary-box";
import { useProduct } from "../use-product";
import { useReviewContext } from "./review-context";

export type PureCompareProductProps = PureProductProps & {
  headingLevel?: 1 | 2 | 3 | 4 | 5;
  addFullReviewLink?: boolean;
  summaryFields?: readonly string[];
};

// export type PureCompareProduct = PureProductProps<CompareProductProps>;

export const PureCompareProduct: React.FC<PureCompareProductProps> = ({
  productData,
  addFullReviewLink = true,
  children,
  summaryFields,
  headingLevel = 3,
}) => {
  if (!productData) {
    return null;
  }

  return (
    <ProductProvider product={productData}>
      <section sx={{ border: 1, borderColor: "border", mx: 10, p: 4, mb: 4 }}>
        <header>
          <DynamicHeading headingLevel={headingLevel} id={productData.key}>
            <ProductLink variant="styles.a" />
          </DynamicHeading>
        </header>
        <ProductSummaryBox summaryFields={summaryFields} />
        <Paragraphs
          appending={addFullReviewLink && productData.review ? <FullReviewLink product={productData} /> : undefined}
        >
          {children}
        </Paragraphs>
      </section>
    </ProductProvider>
  );
};

export type CompareProductProps = ProductPropsFrom<PureCompareProductProps>;
export const CompareProduct: React.FC<CompareProductProps> = ({ product, ...props }) => {
  const productData = useProduct(product);
  const { addAlternative } = useReviewContext() ?? {};
  useEffect(() => {
    if (addAlternative && productData) {
      addAlternative(productData);
    }
  });
  return <PureCompareProduct productData={productData} {...props} />;
};

/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import _ from "lodash";
import { ComparisonPost, Product, ProductPropsFrom, wrapPureProductComponent } from "gatsby-plugin-products";
import { NavMenu } from "../nav-menu";

export type PureComparisonsNavMenuProps = {
  productData?: Product;
  className?: string;
  ignoreSlug?: string;
  maxGrid?: number;
};

export const PureComparisonsNavMenu: React.FC<PureComparisonsNavMenuProps> = ({
  productData,
  className,
  ignoreSlug,
  maxGrid = 5,
}) => {
  // For each comparison, we want to list the product that is not the current product.
  const comparisons = _.filter(productData?.comparisons, (c) => !!c.slug && c.slug !== ignoreSlug);
  const getOtherProduct = (c: ComparisonPost) =>
    c.compared[0].key !== productData?.key ? c.compared[0] : c.compared[1];
  const items = _.map(comparisons, (comparison: ComparisonPost) => ({
    url: comparison.slug,
    value: getOtherProduct(comparison).name,
  }));

  if (_.isEmpty(items)) {
    return null;
  }
  return <NavMenu className={className} menu={{ items }} maxGrid={maxGrid} sx={{ fontSize: 1 }} />;
};

export type ComparisonsNavMenuProps = ProductPropsFrom<PureComparisonsNavMenuProps>;
const ComparisonsNavMenu: React.FC<ComparisonsNavMenuProps> = wrapPureProductComponent(PureComparisonsNavMenu);

export default ComparisonsNavMenu;

/* eslint-disable react/no-array-index-key */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Box, Button, jsx, Themed, ThemeUIStyleObject } from "theme-ui";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import React from "react";
import { useDropdown } from "./use-dropdown";
import { InternalLink } from "../../link";
import { Stylable } from "../../utils/stylable";

export type NavMenuLinkProps = Stylable & {
  dropdown?: boolean;
  direction?: "up" | "down";
  value: any;
  url: string;
  depth: number;
  isOpen: boolean;
};

export const NavMenuLink: React.FC<NavMenuLinkProps> = ({
  className = undefined,
  value,
  url,
  isOpen,
  dropdown,
  depth,
}) => {
  const activeStyle = {
    color: "primary",
  };

  const style: ThemeUIStyleObject = {
    color: "text",
    fontWeight: depth === 1 ? "light" : "normal",
    d: "block",
    w: "full",
    px: [4, 0],
    py: dropdown && 3,
    textAlign: ["left", !dropdown ? "left" : "center"],
  };

  const linkStyle = {
    ...style,
    ...(isOpen && activeStyle),
    _hover: {
      color: "primary",
    },
  };

  return url ? (
    <InternalLink className={className} to={url} activeStyle={activeStyle} sx={linkStyle} variant="nav_menu">
      {value}
    </InternalLink>
  ) : (
    <span className={className} sx={{ ...style, cursor: "auto" }}>
      {value}
    </span>
  );
};

export type NavMenuSubNavProps = Stylable & {
  dropdown?: boolean;
  direction?: "up" | "down";
  items: any[];
  isOpen: boolean;
};

export const NavMenuSubNav: React.FC<NavMenuSubNavProps> = ({
  className = undefined,
  items,
  dropdown,
  isOpen,
  direction,
}) => {
  if (!items?.length) return null;

  const topOrBottom = isOpen ? "100%" : "130%";
  const dropdownStyle: ThemeUIStyleObject = {
    position: ["relative", "absolute"],
    maxHeight: [isOpen ? "100vh" : 0, "none"],
    boxShadow: [null, "xl"],
    bg: ["muted", "primary"],
    borderRadius: [null, "lg"],
    overflow: "hidden",
    opacity: [1, isOpen ? 1 : 0],
    visibility: ["visible", isOpen ? "visible" : "hidden"],
    transition: "all .15s ease-in",
    transform: ["none", "translateX(-50%)"],
    left: "50%",
    top: direction === "down" ? topOrBottom : null,
    bottom: direction === "up" ? topOrBottom : null,
    zIndex: 500,
    border: [0, "1px solid"],
    borderColor: ["border", "border"],
    ul: {
      py: 1,
      minWidth: "180px",
      li: {
        mt: 0,
        a: {
          py: 0,
          px: 2,
          fontWeight: "normal",
          "&:hover": {
            // bg: "muted",
          },
        },
      },
    },
  };

  const item = (
    <Themed.ul className={className} sx={{ listStyle: "none", pl: 0, m: 0 }}>
      {items.map((it: any, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <NavMenuItem key={index} depth={1} dropdown={false} direction={direction} {...it} />
      ))}
    </Themed.ul>
  );
  return dropdown ? (
    <Box className={className} sx={dropdownStyle}>
      {item}
    </Box>
  ) : (
    item
  );
};

export type NavMenuItemProps = Stylable & {
  dropdown?: boolean;
  trigger?: string;
  vertical?: boolean;
  expand?: boolean;
  direction?: "up" | "down";
  value: any;
  url: string;
  items: any[];
  depth: number;
};

export const NavMenuItem: React.FC<NavMenuItemProps> = ({
  className,
  value,
  url,
  items,
  dropdown,
  trigger,
  vertical,
  expand,
  direction,
  depth = 0,
}) => {
  const [dropdownRef, isOpen, open, close] = useDropdown();
  const isMobile = useBreakpointIndex() <= 2;
  if (!value) return null;

  const usingHover = trigger === "hover";

  const liProps = {
    ...(usingHover &&
      !isMobile && {
        onMouseEnter: open,
        onMouseLeave: close,
      }),
  };
  // TODO: check icon => button.icon variant
  return (
    <Themed.li
      className={className}
      ref={dropdownRef}
      sx={{
        position: "relative",
        borderBottomWidth: [depth === 0 && !vertical && dropdown ? 1 : 0, 0],
        mt: [vertical || !dropdown ? 2 : 0, depth === 1 ? 2 : 0],
      }}
      {...liProps}
    >
      <NavMenuLink
        value={value}
        url={url}
        dropdown={dropdown}
        depth={depth}
        isOpen={isOpen}
        {...(dropdown && !usingHover && { onClick: isOpen ? close : open })}
        sx={{ cursor: "pointer" }}
      />
      {expand && items?.length && (
        <>
          {dropdown && (
            <Button
              variant="button.icon"
              type="button"
              onClick={() => (isOpen ? close() : open())}
              sx={{
                display: ["block", "none"],
                position: "absolute",
                right: "2",
                top: "0",
                rounded: "none",
                h: "8",
                w: "8",
              }}
            >
              <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
            </Button>
          )}
          <NavMenuSubNav items={items} dropdown={dropdown} direction={direction} isOpen={isOpen || !dropdown} />
        </>
      )}
    </Themed.li>
  );
};

export type NavMenuProps = Stylable & {
  menu: any;
  dropdown?: boolean;
  trigger?: string;
  vertical?: boolean;
  expand?: boolean;
  direction?: "up" | "down";
  maxGrid?: number;
};

export const NavMenu: React.FC<NavMenuProps> = ({
  menu,
  dropdown = true,
  trigger = "hover",
  vertical = false,
  expand = true,
  direction = "down",
  className = undefined,
  maxGrid = Number.POSITIVE_INFINITY,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // TODO clean up
  const nav = menu;
  if (!nav || !nav.items?.length) return null;

  const gridStyle = `repeat(${Math.min(maxGrid, nav.items.length)}, auto)`;
  return (
    <Themed.ul
      className={className}
      sx={{
        display: "grid",
        listStyle: "none",
        p: 0,
        gridTemplateRows: [gridStyle, vertical ? gridStyle : "none"],
        gridTemplateColumns: [null, vertical ? "none" : gridStyle],
        gap: [vertical || !dropdown ? 8 : 0, vertical ? 8 : 4],
        my: 0,
        mx: [0, 0],
      }}
    >
      {nav.items.map((item: any, index: number) => (
        <NavMenuItem
          key={index}
          dropdown={dropdown}
          trigger={trigger}
          vertical={vertical}
          expand={expand}
          direction={direction}
          {...item}
        />
      ))}
    </Themed.ul>
  );
};

/** @jsx jsx */
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import { Container, jsx, Themed } from "theme-ui";
import _ from "lodash";
import Posts from "../components/posts-template/posts";
import CategoryBreadcrumbs from "../components/category-breadcrumbs";
import Link from "../link";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql`
  query CategoryAndPosts($key: String) {
    category(key: { eq: $key }) {
      ...CategoryFragment

      contents {
        ...PostTeaserFragment
        category {
          key
          slug
          title
        }
      }
    }
  }
`;

type CategorySectionProps = {
  posts: GatsbyTypes.PostTeaserFragmentFragment[];
  title: React.ReactNode;
};

const CategorySection: React.FC<CategorySectionProps> = ({ posts, title, ...props }) => {
  if (!posts) {
    return null;
  }
  const titleElement = _.isString(title) ? <Themed.h2 sx={{ variant: "heading.h2" }}>{title}</Themed.h2> : title;
  return (
    <section sx={{ mt: "8" }}>
      <header>
        <Themed.hr sx={{ borderColor: "primary", borderTopWidth: "2px", borderTopStyle: "solid", mb: "2" }} />
        {titleElement}
      </header>
      <Posts posts={posts} {...props} />
    </section>
  );
};

type ChildCategorySectionProps = {
  href: string | undefined; // TODO should always be defined, fix types
} & CategorySectionProps;

const ChildCategorySection: React.FC<ChildCategorySectionProps> = ({ posts, title, href, ...props }) => {
  if (!posts) {
    return null;
  }
  if (!href) return null;

  const titleElement = (
    <Themed.div
      sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", alignContent: "center", mb: "7" }}
    >
      <Link to={href}>
        <Themed.h2 sx={{ variant: "heading.h2", mb: "0" }}>{title}</Themed.h2>
      </Link>
      <Link to={href} sx={{ fontSize: "sm" }}>
        See all in {title}
      </Link>
    </Themed.div>
  );
  return <CategorySection posts={posts} {...props} title={titleElement} />;
};

type Props = PageProps<GatsbyTypes.CategoryAndPostsQuery>;

const CategoryPageTemplate = ({ data: { category }, ...props }: Props): React.ReactNode => {
  if (!category) {
    return null;
  }

  const postSections = _.groupBy(category.contents, (post) => {
    if (category.featured?.includes(post.slug)) {
      return "featuredPosts";
    }
    if (post.category?.key !== category.key) {
      return post.category?.title;
    }
    return "ourPosts";
  });

  const { featuredPosts, ourPosts, ...childCategoryPosts } = postSections;

  const { body } = category || {};
  return (
    <Layout pathname={category.slug}>
      <SEO {...category} />
      <Container sx={{ py: [8, 12, 14] }}>
        <article sx={{ p: { fontSize: "lg", lineHeight: "relaxed" } }}>
          <header>
            <CategoryBreadcrumbs category={category} />
            <Themed.h1 sx={{ variant: "heading.h1", m: "0", mb: "3" }}>{category.title}</Themed.h1>
          </header>
          {body ? <MDXRenderer>{body}</MDXRenderer> : null}
        </article>

        {featuredPosts && <CategorySection title="Featured articles" posts={featuredPosts} />}
        <CategorySection title={featuredPosts && `More articles on ${category.title}`} posts={ourPosts} {...props} />
        {_.map(childCategoryPosts, (posts, title) => (
          <ChildCategorySection
            key={title}
            title={title}
            href={posts[0].category?.slug}
            posts={posts.slice(0, 4)}
          />
        ))}
      </Container>
    </Layout>
  );
};

export default CategoryPageTemplate;

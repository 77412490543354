/* eslint-disable import/prefer-default-export */
import { makeWrapPageElementWithChildReferences } from "gatsby-plugin-attribute-references";
import React from "react";
import { File, ImagesProvider } from "../src/components/images-context";
import { PostsProvider } from "../src/components/posts-context";
import { Post } from "../src/types";

export const wrapPageElementPosts = makeWrapPageElementWithChildReferences(
  "childReferencedPosts",
  "posts",
  (posts: Post[], { element }) => <PostsProvider posts={posts}>{element}</PostsProvider>
);

export const wrapPageElement = makeWrapPageElementWithChildReferences(
  "childReferencedImages",
  "files",
  (images: File[], args, options) => (
      <ImagesProvider images={images}>{wrapPageElementPosts(args, options)}</ImagesProvider>
    )
);

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { Link } from "gatsby-theme-themed-gatsby-link";
import { Post } from "../types";
import { usePost } from "./posts-context";

export type PurePostLinkProps = {
  className?: string
  post: Post;
};

export const PurePostLink: React.FC<PurePostLinkProps> = ({
  className,
  post: { title, slug },
  children,
  // variant = "post_link",
}) => <Link className={className} to={slug}>{children ?? title}</Link>;

export type PostLinkProps = {
  className?: string
  post: Post | string;
};

export const PostLink: React.FC<PostLinkProps> = ({ post: postOrSlug, ...props }) => {
  const post = usePost(postOrSlug);
  if (!post) {
    return null;
  }
  return <PurePostLink post={post} {...props} />;
};

/* eslint-disable import/prefer-default-export */
import { makeWrapPageElementWithChildReferences } from "gatsby-plugin-attribute-references";
import React from "react";
import { GlossaryProvider } from "../src/components/glossary-context";
import { GlossaryTerm } from "../src/types";

export const wrapPageElement = makeWrapPageElementWithChildReferences(
  "childReferencedGlossaryTerms",
  "glossaryTerms",
  (terms: GlossaryTerm[], { element }) => <GlossaryProvider terms={terms}>{element}</GlossaryProvider>
);

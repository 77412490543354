import slugify from "@sindresorhus/slugify";

export interface PluginConfig {
  contentPath?: string;
  slugify?: (pathComponents: string[]) => string;
}

export const withDefaults = (pluginOptions?: PluginConfig): Required<PluginConfig> => ({
  contentPath: "content/faqs",
  slugify: (pathComponents: string[]) => pathComponents.map((x) => slugify(x)).join("/"),
  ...pluginOptions,
});

/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import { jsx } from "theme-ui";
import { Glossary as DefaultGlossary } from "gatsby-theme-glossary/src/components/glossary";
import { PropsOf } from "@emotion/react";
import { InlineFAQ } from "gatsby-theme-faq";
import { GlossaryTerm } from "gatsby-theme-glossary";
import _ from "lodash";
import SEO from "../../components/seo";
import { ArticleRenderer } from "../../components/article/article-renderer";

// Create a FAQ entry for each glossary term, unless they have their own page, in which case we build the FAQ entry there.
//  We don't want multiple FAQs for the same term because Google wants each FAQ to only appear once per domain.
const GlossaryFAQs: React.FC<{ terms: readonly GlossaryTerm[] }> = ({ terms }) => (
  <>
    {_.map(
      _.filter(terms, (term) => !term.page && !term.primary),
      (term) => (
        <InlineFAQ title={term.title} render="hidden">
          {term.definition}
        </InlineFAQ>
      )
    )}
  </>
);

export const Glossary: React.FC<PropsOf<typeof DefaultGlossary>> = ({ terms, ...props }) => (
  <>
    <SEO title="Glossary" excerpt="All the terminology you need to know when choosing tools for your next project." />
    <ArticleRenderer article={{ title: "Glossary" }}>
      <DefaultGlossary
        terms={terms}
        {...props}
        sx={{ ul: { listStyleType: "none", mb: 4, pl: 0, li: { mb: 3 } }, ".glossary_term": { fontWeight: "bold" } }}
      />
      <GlossaryFAQs terms={terms} />
    </ArticleRenderer>
  </>
);

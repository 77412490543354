/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import wrapPureProductComponent from "./wrap-pure-product-component";
import { NamedProduct, ProductPropsFrom, PureProductProps } from "../types";

export type ProductNameType = "model" | "normal" | "full";

export const getProductName = (product: NamedProduct, nameType: ProductNameType = "normal") => {
  switch (nameType) {
    case "model":
      return product.model;

    case "full":
      return `${product.name} ${product.shortDescription || ""}`.trim();

    case "normal":
    default:
      return product.name;
  }
};

export type PureProductNameProps = PureProductProps<NamedProduct> & {
  nameType?: ProductNameType;
};

export const PureProductName: React.FC<PureProductNameProps> = ({ productData, nameType = "normal" }) => {
  if (!productData) {
    return null;
  }

  const name = getProductName(productData, nameType);

  return <>{name.trim()}</>;
};

export type ProductNameProps = ProductPropsFrom<PureProductNameProps>;
export const ProductName: React.FC<ProductNameProps> = wrapPureProductComponent(PureProductName);

/* eslint-disable import/prefer-default-export */
import { makeWrapPageElementWithChildReferences } from "gatsby-plugin-attribute-references";
import React from "react";
import { Template, TemplateProvider } from "./src/components/template";

export const wrapPageElement = makeWrapPageElementWithChildReferences(
  "templates",
  "",
  (templates: Template[], { element }) => <TemplateProvider templates={templates}>{element}</TemplateProvider>
);

/** @jsx jsx */
import { jsx } from "theme-ui";
import CookieConsent from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";

const CookieConsentBanner: React.FC = () => {
  const location = useLocation();

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      flipButtons
      cookieName="gatsby-gdpr-google-tagmanager"
      buttonStyle={{
        color: "white",
        backgroundColor: "green",
      }}
      style={{
        fontSize: 14,
      }}
      onAccept={() => {
          initializeAndTrack(location);
      }}
    >
      We use cookies and other tracking technologies to analyze our website traffic, to understand where our visitors
      are coming from, and to improve your browsing experience on our website.
    </CookieConsent>
  );
};

export default CookieConsentBanner;

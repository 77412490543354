/* @jsx jsx */
/** @jsxFrag React.Fragment */
import * as React from "react";
import { jsx, Themed } from "theme-ui";
import _ from "lodash";
import Posts from "../posts-template/posts";

type Props = {
  posts: readonly GatsbyTypes.PostTeaserFragmentFragment[];
};

const RelatedPosts: React.FC<Props> = ({ posts }: Props) => {
  if (_.isEmpty(posts)) {
    return null;
  }
  return (
    <>
      <hr sx={{ my: 5 }} />
      <section>
        <Themed.h2>Read more:</Themed.h2>
        {posts ? <Posts posts={posts} columns={[1, 2, 3, 4]} sx={{ my: 5 }} /> : null}
      </section>
    </>
  );
};

export default RelatedPosts;

import { graphql } from "gatsby";

export { default } from "../components/post-template";

// TODO: move this into src/components/posts-template/posts-template.tsx once migration away from @reflexjs is complete.
// Gatsby treats queries specially and won't recognize it as valid if it's imported from elsewhere.
export const query = graphql`
  query Post($id: String) {
    post(id: { eq: $id }) {
      ...PostFragment
    }
  }
`;

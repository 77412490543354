import { Product, ProductBase } from "./types";
import { useProduct } from "./use-product";
import { useProducts } from "./use-products";
import { isStringArray } from "./utils/is-string-array";

// Return in order:
//  1. Specified product (or nothing if not found)
/// 2. Specified products (or nothing if not found)
//  3. Current ProductContext, if any
//  4. Nothing.
// Note that we will never return all products in ProductsContext, as that is almost certainly not what is wanted.
// eslint-disable-next-line import/prefer-default-export
export function useProductOrProducts<TProduct extends ProductBase = Product>({
  product,
  products,
}: {
  product?: string | TProduct;
  products?: readonly string[] | readonly TProduct[];
}): readonly (TProduct | Product)[] {
  if (product && products) {
    throw new Error("Only one of product or products may be specified.");
  }

  const productDataFromKey = useProduct(product);
  const productDataFromKeys = useProducts<TProduct>(
    isStringArray(products) ? (p) => !!products?.includes(p.key) : products,
    !products
  );

  if (product || products === undefined) {
    // This is either the specified product or the result from useProduct(undefined) (i.e., the current ProductContext).
    return productDataFromKey ? [productDataFromKey] : [];
  }

  if (products) {
    return productDataFromKeys;
  }
  return [];
}

/* @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { ReactElement } from "react";
import { StaticImage } from "gatsby-plugin-image";
import BackgroundHero from "../background-hero";

const backgroundImage = (
  <StaticImage src="../../../content/images/canstockphoto6693997.jpg" alt="A variety of tools lying on a workbench." />
);
// TODO a more elegant way to get the imageData for a static image?
// eslint-disable-next-line no-underscore-dangle
const backgroundImageData = backgroundImage.props.__imageData;

const PostsHeader = (): ReactElement => (
  <section sx={{ pt: "4|4|4|10", bg: "primary", color: "black", py: "0", height: ["200px", "400px", "600px"] }}>
    <BackgroundHero image={backgroundImageData} style={{ width: "100%", height: "100%" }}>
      <Themed.h1
        sx={{ color: "white", textAlign: "center", my: "0", flexBasis: "100%", fontSize: ["4xl", "5xl", "6xl"] }}
      >
        The Right Tool for Every Project
      </Themed.h1>
      <Themed.h2 sx={{ color: "white", textAlign: "center", fontWeight: "normal", flexBasis: "100%" }}>
        Confidently equip your shop, no matter the budget
      </Themed.h2>
    </BackgroundHero>
  </section>
);

export default PostsHeader;

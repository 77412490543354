/** @jsx jsx */
import { jsx, SxProp } from "theme-ui";
import React, { ReactNode } from "react";

type Props = SxProp & {
  crumbs: ReactNode[];
  separator?: string;
  className?: string;
};

const Breadcrumbs = ({ crumbs, separator = "›", className }: Props): React.ReactElement => (
  <ul
    className={className}
    sx={{
      color: "muted",
      lineHeight: "0",
      listStyleType: "none",
      m: 0,
      mb: 4,
      p: 0,
      a: {
        color: "inherit",
        ":hover": {
          color: "inherit",
          textShadow: "0 0 .65px, 0 0 .65px",
        },
      },
    }}
  >
    {crumbs.map((c, index) => (
      <li
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        sx={{
          display: "inline",
          fontSize: "sm",
          textTransform: "uppercase",
          "&:after": { px: "2", content: `"${separator}"`, fontSize: "sm", verticalAlign: "top" },
          "&:last-of-type:after": { content: '""', px: "0" },
        }}
      >
        {c}
      </li>
    ))}
  </ul>
);

export default Breadcrumbs;

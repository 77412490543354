/* eslint-disable no-nested-ternary */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React, { ReactNode } from "react";
import _ from "lodash";
import { Product, ProductBase, ProductProps } from "../types";
import { clauseList } from "./utils/clause-list";
import { useProductOrProducts } from "../use-product-or-products";

export type PureProductClausesProps<TProduct> = {
  productData: readonly TProduct[];
  prefix?: string;
  suffix?: string;
  children: (product: TProduct, index: number) => ReactNode;
};

export function PureProductClauses<TProduct>({ productData, prefix, suffix, children }: PureProductClausesProps<TProduct>) {
  return _.isEmpty(productData) ? null : (
    <>
      {prefix && `${prefix} `}
      {clauseList(_.map(productData, (p, i) => children(p, i)))}
      {suffix}
    </>
  );
}

export type ProductClausesProps<TProduct> = ProductProps<TProduct> & {
  products?: string[] | TProduct[];
  prefix?: string;
  suffix?: string;
  children: (product: TProduct | Product, index: number) => ReactNode;
};

export function ProductClauses<TProduct extends ProductBase = Product>({ ...props }: ProductClausesProps<TProduct>) {
  const productData = useProductOrProducts(props);
  return <PureProductClauses<Product | TProduct> productData={productData} {...props} />;
}

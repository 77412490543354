/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { ReviewProvider, PureProductProps, ProductPropsFrom } from "gatsby-plugin-products";
import wrapPureProductComponent from "gatsby-plugin-products/src/components/wrap-pure-product-component";
import { AlternativesFAQ } from "./alternatives-faq";
import { ProductFAQ } from "./product-faq";

export type PureReviewProps = PureProductProps & {
  // summaryFields?: readonly string[];
  // alternatives?: readonly Product[];
  faqs?: boolean;
};

export const PureReview: React.FC<PureReviewProps> = ({
  // summaryFields = [],
  productData,
  faqs = true,
  children,
}) => (
  <ReviewProvider>
    {children}
    {productData && <AlternativesFAQ product={productData} />}
    {productData && faqs && <ProductFAQ product={productData} />}
  </ReviewProvider>
);

export type ReviewProps = ProductPropsFrom<PureReviewProps>;
export const Review: React.FC<ReviewProps> = wrapPureProductComponent(PureReview);

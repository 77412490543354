/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, { useState } from "react";
import { Box, IconButton, Flex, jsx, Themed } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown as faDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronUp as faUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import AnimateHeight from "react-animate-height";
import _ from "lodash";
import { FAQ } from "../types";

export type FAQPanelProps = {
  className?: string;
  faq: FAQ;
};

const FAQPanel: React.FC<FAQPanelProps> = ({ faq, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box className={className}>
      <Flex
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        sx={{ cursor: "pointer", justifyContent: "space-between" }}
      >
        <Themed.h4 id={`faq-${_.kebabCase(faq.title)}`} sx={{ mt: 0, pt: 0 }}>
          {faq.title}
        </Themed.h4>
        <IconButton sx={{ size: 8, color: "heading" }}>
          <FontAwesomeIcon icon={isOpen ? faUp : faDown} sx={{ mr: "2" }} />
        </IconButton>
      </Flex>
      <AnimateHeight height={isOpen ? "auto" : 0}>{isOpen ? faq.body : ""}</AnimateHeight>
    </Box>
  );
};

export default FAQPanel;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React, { Children } from "react";
import _ from "lodash";
import { Callout } from "./callout";

export type JumpToProps = {
  className?: string;
  prefix?: string;
  variant?: string;
};

export const JumpTo: React.FC<JumpToProps> = ({ className, prefix = "Jump To:", variant = "jump_to", children }) => (
  <Callout className={className} variant={variant} prefix={prefix}>
    <ul>
      {_.map(Children.toArray(children), (child) => (
        <li>{child}</li>
      ))}
    </ul>
  </Callout>
);

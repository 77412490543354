/* @jsx jsx */
import { Container, jsx } from "theme-ui";
// import { graphql } from "gatsby";
import Layout from "../layout";
import PostsHeader from "./posts-header";
import PostsFooter from "./posts-footer";
import Posts from "./posts";
import { Pager } from "./pager";
import { useSiteMetadata } from "../../sitemetadata";
import SEO from "../seo";

// TODO: uncomment once migration away from @reflexjs is complete
// export const query = graphql`
//   query PostsTemplate ($skip: Int!, $limit: Int!) {
//     allPost(
//       sort: { fields: [date, title], order: [DESC, ASC] }
//       filter: { published: { eq: true } }
//       skip: $skip
//       limit: $limit
//     ) {
//       posts: nodes {
//         ...PostTeaserFragment
//       }
//     }
//   }
// `;

const PostsTemplate = ({ data, ...props }: any): React.ReactNode => {
  let { description, title } = useSiteMetadata();
  const {
    pageContext: { previousPagePath, nextPagePath, humanPageNumber, skip, limit, total },
  } = props;

  if (humanPageNumber > 1) {
    // Give different title/excerpt for pages after the first, because we want bots to follow them but would prefer they not appear in SERPs.
    // Per: https://www.searchenginejournal.com/technical-seo/pagination
    title = `Articles Page ${humanPageNumber} for ${title}`;
    description = `(${skip + 1}-${Math.min(skip + limit, total)}) ${description}`;
  }

  return (
    <Layout pathname={props.location.pathname}>
      <SEO title={title} excerpt={description} />
      <PostsHeader />
      {data.allPost ? (
        <section sx={{ py: [8, 12, 14] }}>
          <Container>
            <Posts {...data.allPost} {...props} />
            <Pager previousPagePath={previousPagePath} nextPagePath={nextPagePath} />
          </Container>
        </section>
      ) : null}
      <PostsFooter />
    </Layout>
  );
};

export default PostsTemplate;

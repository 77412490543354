/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import _ from "lodash";
import { smartypantsu } from "smartypants";
import { RoundupProduct, useRoundupProducts } from "./roundup-context";
import { ProductLink } from "./product-link";
import { StylesFor, stylesFor } from "./variants";
import { ProductNameType } from "./product-name";

export type RoundupRecapVariant = "default";

export type PureRoundupRecapProps = {
  roundupProducts: readonly RoundupProduct[];
  nameType?: ProductNameType;
  variant?: RoundupRecapVariant;
};

const RecommendationListItem: React.FC<Pick<PureRoundupRecapProps, "nameType"> & { product: RoundupProduct }> = ({
  product: { recommendation, recDetails, product },
  nameType,
}) => (
  <li>
    <span className="recap_rec">{smartypantsu(recommendation, 1)}:</span>{" "}
    {recDetails ? (
      <>
        The <ProductLink product={product} nameType={nameType} />{" "}
        {_.isString(recDetails) ? smartypantsu(recDetails, 1) : recDetails}
      </>
    ) : (
      <ProductLink product={product} nameType={nameType} />
    )}
  </li>
);

export const PureRoundupRecap: React.FC<PureRoundupRecapProps> = ({
  nameType = "full",
  roundupProducts,
  variant = "default",
  children,
}) => {
  const styles: StylesFor<RoundupRecapVariant> = {
    default: { ".recap_rec": { fontWeight: "bold" } },
  };

  const [style] = stylesFor(variant, styles);

  return (
    <>
      {children && <p>{children}</p>}
      <ul sx={style}>
        {_.map(
          _.filter(roundupProducts, (rp) => !_.isEmpty(rp.recommendation)),
          (rp) => (
            <RecommendationListItem key={rp.product.key} nameType={nameType} product={rp} />
          )
        )}
      </ul>
    </>
  );
};

export type RoundupRecapProps = Omit<PureRoundupRecapProps, "roundupProducts">;

export const RoundupRecap: React.FC<RoundupRecapProps> = (props) => {
  const roundupProducts = useRoundupProducts();
  if (!roundupProducts) {
    return null;
  }
  return <PureRoundupRecap {...props} roundupProducts={roundupProducts} />;
};

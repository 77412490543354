/** @jsx jsx */
import { Box, Divider, jsx } from "theme-ui";
import React from "react";
import { DynamicHeading } from "gatsby-plugin-products";
import slugify from "@sindresorhus/slugify";
import { Link } from "gatsby-theme-themed-gatsby-link";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import YouTube from "react-youtube";
import { useImage, File } from "./images-context";
import { FigAttribution, Figure } from "./figure";

export type PurePostSummaryProps = {
  headingLevel?: 1 | 2 | 3 | 4 | 5;
  url: string;
  title: string;
  imageFile?: File;
  youtube?: string;
  site?: string;
};

export const PurePostSummary: React.FC<PurePostSummaryProps> = ({
  url,
  title,
  imageFile,
  youtube,
  site,
  children,
  headingLevel = 3,
}) => {
  let gatsbyImage;
  if (imageFile?.childImageSharp?.gatsbyImageData) {
    const imageData = getImage(imageFile.childImageSharp.gatsbyImageData);
    if (!imageData) throw Error(`PurePostSummary: Failed to getImage from image "${imageFile.relativePath}".`);

    gatsbyImage = <GatsbyImage image={imageData} alt={`Picture of ${title}`} />;
  }

  // The final link is a dofollow, others are nofollow.
  return (
    <section sx={{ "& + &": { mt: 7 } }}>
      <Divider sx={{ width: "90%", mx: "auto", color: "primary", mb: 7 }} />
      <header>
        {gatsbyImage && (
          <Figure variant="centered">
            <Link href={url} rel="nofollow">
              {gatsbyImage}
            </Link>
            <FigAttribution source={[site ?? title, url]} rel="nofollow" />
          </Figure>
        )}
        {youtube && (
          <Box sx={{ textAlign: "center" }}>
            <YouTube videoId={youtube} title={title} />
          </Box>
        )}
        <DynamicHeading className="counted" headingLevel={headingLevel} id={slugify(title)}>
          <Link href={url}>{title}</Link>
        </DynamicHeading>
      </header>
      {children}
      <p>
        (From <Link href={url}>{site ?? title}</Link>.)
      </p>
    </section>
  );
};

export type PostSummaryProps = Omit<PurePostSummaryProps, "imageFile"> & {
  image?: string;
};

export const PostSummary: React.FC<PostSummaryProps> = ({ image, ...props }) => {
  const imageFile = useImage(image);
  return <PurePostSummary {...props} imageFile={imageFile} />;
};

import { graphql } from "gatsby";

export { default } from "../components/posts-template";

// TODO: move this into src/components/posts-template/posts-template.tsx once migration away from @reflexjs is complete.
// Gatsby treats queries specially and won't recognize it as valid if it's imported from elsewhere.
export const query = graphql`
  query Posts($skip: Int!, $limit: Int!) {
    allPost(
      sort: { fields: [date, title], order: [DESC, ASC] }
      filter: { indexed: { eq: true } }
      skip: $skip
      limit: $limit
    ) {
      posts: nodes {
        ...PostTeaserFragment
      }
    }
  }
`;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Card, jsx } from "theme-ui";
import React from "react";
import { ProductPropsFrom, PureProductProps } from "../types";
import wrapPureProductComponent from "./wrap-pure-product-component";
import { ProsCons } from "./pros-cons";

export type ProductProsConsVariant = 0 | 1 | 2;

export type PureProductProsConsProps = PureProductProps & {
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  variant?: ProductProsConsVariant;
  prosHeader?: string;
  consHeader?: string;
  className?: string;
};

export const PureProductProsCons: React.FC<PureProductProsConsProps> = ({
  productData,
  headingLevel = 3,
  prosHeader,
  consHeader,
  variant = 0,
  className,
}) => (
  <Card>
    <ProsCons
      className={className}
      prosHeader={prosHeader}
      pros={productData?.pros}
      consHeader={consHeader}
      cons={productData?.cons}
      headingLevel={headingLevel}
      variant={variant}
    />
  </Card>
);

export type ProductProsConsProps = ProductPropsFrom<PureProductProsConsProps>;
export const ProductProsCons: React.FC<ProductProsConsProps> = wrapPureProductComponent(PureProductProsCons);

/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import { ImageDataLike } from "gatsby-plugin-image";
import React from "react";
import ArticleImage from "./article-image";

export type Props = {
  alt: string;
  imageUrl?: string | undefined;
  imageFile?: ImageDataLike | undefined;
};

const ArticleHero: React.FC<Props> = ({ imageUrl, imageFile, alt }) => {
  const imageElement = <ArticleImage imageUrl={imageUrl} imageFile={imageFile} alt={alt} />;
  if (imageElement) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          // maxHeight: "300px",
          mx: "auto",
          my: "10",
          borderRadius: "lg",
          // boxShadow: "3xl",
          maxWidth: ["100%", "75%"],
        }}
      >
        {imageElement}
      </Box>
    );
  }
  return null;
};

export default ArticleHero;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Heading, jsx } from "theme-ui";
import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { useFAQContext } from "./faq-context";
import { FAQ, FAQRender } from "../types";

export type InlineFAQProps = {
  title: string;
  // Render as a header/paragraph here, or just add it to the list of FAQs to be rendered later?
  //  (Will generate schema no matter what.)
  render?: FAQRender;
  // Ignored if render is not "inline"
  headingLevel?: 1 | 2 | 3 | 4 | 5 | "none";
};

const InlineFAQ: React.FC<InlineFAQProps> = ({ headingLevel = 3, render = "inline", title, children }) => {
  const { addFAQ } = useFAQContext();

  const newFAQ: FAQ = useMemo(() => ({ title, body: children, render }), [children, render, title]);
  useEffect(() => {
    addFAQ(newFAQ);
  }, [newFAQ, addFAQ]);

  if (render !== "inline") {
    return null;
  }
  return (
    <>
      {headingLevel !== "none" && (
        <Heading as={`h${headingLevel}`} sx={{ variant: `styles.h${headingLevel}` }} id={_.kebabCase(title)}>
          {title}
        </Heading>
      )}
      {newFAQ.body}
    </>
  );
};

export default InlineFAQ;

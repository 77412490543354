/* eslint-disable no-nested-ternary */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { LinkableProduct, ProductProps } from "../types";
import { PureProductClauses } from "./product-clauses";
import { useProductOrProducts } from "../use-product-or-products";
import { ProductLink } from "./product-link";

export type PureProductLinksProps = {
  productData: readonly LinkableProduct[];
  prefix?: string;
  suffix?: string;
};

export const PureProductLinks: React.FC<PureProductLinksProps> = ({ productData, prefix, suffix }) => (
  <PureProductClauses prefix={prefix} suffix={suffix} productData={productData}>
    {(product, index) => <ProductLink product={product} key={index} />}
  </PureProductClauses>
);

export type ProductLinksProps = ProductProps & {
  products?: readonly string[] | readonly LinkableProduct[];
  prefix?: string;
  suffix?: string;
};

export const ProductLinks: React.FC<ProductLinksProps> = (props) => {
  const productData = useProductOrProducts(props);
  return <PureProductLinks productData={productData} {...props} />;
};

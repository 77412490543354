/** @jsx jsx */
import { Grid, jsx } from "theme-ui";
import { ReactElement } from "react";
import { hasPresentKey } from "ts-is-present";
import PostTeaser from "./post-teaser";

// type PageContext = {
//   humanPageNumber?: number;
//   pageNumber?: number;
// };

type Props = {
  // pageContext?: PageContext;
  posts: readonly GatsbyTypes.PostTeaserFragmentFragment[];
  columns?: number[];
  className?: string;
};

const Posts = ({ posts, className, columns = [1, 2, 3, 4] }: Props): ReactElement | null => {
  // TODO better typing to avoid need for this hack to make TS happy
  const postsWithSlug = posts.filter(hasPresentKey("slug"));
  return postsWithSlug.length ? (
    <Grid
      className={className}
      columns={columns}
      sx={{ gridAutoFlow: "dense", columnGap: [2, 3, 3], rowGap: [4, 6, 8, 10] }}
    >
      {postsWithSlug &&
        postsWithSlug.map((post) => (
          // eslint-disable-next-line react/no-array-index-key
          <PostTeaser key={post.slug} post={post} />
        ))}
    </Grid>
  ) : null;
};

export default Posts;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { Link } from "gatsby-theme-themed-gatsby-link";
import { GlossaryTerm } from "../types";

export type GlossaryDefinitionProps = {
  term: GlossaryTerm;
};

// eslint-disable-next-line arrow-body-style
export const GlossaryDefinition: React.FC<GlossaryDefinitionProps> = ({ term: { term, definition, path } }) => {
  return (
    <>
      <span className="glossary_term">{term}:</span> <span className="glossary_def">{definition}</span>
      {path && (
        <span>
          {" "}
          (<Link to={path}>Read more</Link>)
        </span>
      )}
    </>
  );
};

/* eslint-disable import/prefer-default-export */
import { PluginOptions, WrapPageElementNodeArgs } from "gatsby";
import { makeWrapPageElementWithChildReferences } from "gatsby-plugin-attribute-references";
import { flatMap, flow, mergeAll, values } from "lodash/fp";
import React from "react";
import { VariantProvider } from "../src/components";
import { ProductsProvider } from "../src/components/products-context";
import { Product } from "../src/types";

const wrapReferencedProducts = makeWrapPageElementWithChildReferences(
  "childReferencedProducts",
  "products",
  (products: Product[], { element }) => <ProductsProvider products={products}>{element}</ProductsProvider>
);

export const wrapPageElement = (args: WrapPageElementNodeArgs, options: PluginOptions) => {
  const { data, pageContext } = args.props;
  const componentVariants = flow(flatMap(values), flatMap("componentVariants"), mergeAll)([data, pageContext]);

  return <VariantProvider variants={componentVariants}>{wrapReferencedProducts(args, options)}</VariantProvider>;
};

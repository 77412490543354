/** @jsx jsx */
import { jsx } from "theme-ui";
import { Stylable } from "../../utils/stylable";
import { NavMenu } from "../nav-menu";

const footerNav = {
  items: [
    {
      url: "/",
      value: "Home",
    },
    {
      url: "/about",
      value: "About",
    },
    {
      url: "/contact",
      value: "Contact",
    },
    {
      url: "/privacy-policy",
      value: "Privacy Policy",
    },
    {
      url: "/sitemap",
      value: "Sitemap",
    },
  ],
};

const FooterNavMenu: React.FC<Stylable> = ({ className }) => (
  <NavMenu
    className={className}
    menu={footerNav}
    vertical
    direction="up"
    sx={{ gap: [0, 1], variant: "styles.PagesNavMenu" }}
  />
);

export default FooterNavMenu;

exports.components = {
  "component---packages-reflexjs-gatsby-theme-profile-src-profile-template-js": () => import("./../../../packages/@reflexjs/gatsby-theme-profile/src/profile-template.js" /* webpackChunkName: "component---packages-reflexjs-gatsby-theme-profile-src-profile-template-js" */),
  "component---plugins-gatsby-theme-glossary-src-queries-glossary-tsx": () => import("./../../../plugins/gatsby-theme-glossary/src/queries/glossary.tsx" /* webpackChunkName: "component---plugins-gatsby-theme-glossary-src-queries-glossary-tsx" */),
  "component---plugins-gatsby-theme-glossary-src-templates-glossary-term-tsx": () => import("./../../../plugins/gatsby-theme-glossary/src/templates/glossary-term.tsx" /* webpackChunkName: "component---plugins-gatsby-theme-glossary-src-templates-glossary-term-tsx" */),
  "component---src-gatsby-theme-affiliate-posts-post-template-tsx": () => import("./../../../src/gatsby-theme-affiliate-posts/post-template.tsx" /* webpackChunkName: "component---src-gatsby-theme-affiliate-posts-post-template-tsx" */),
  "component---src-gatsby-theme-affiliate-posts-posts-template-tsx": () => import("./../../../src/gatsby-theme-affiliate-posts/posts-template.tsx" /* webpackChunkName: "component---src-gatsby-theme-affiliate-posts-posts-template-tsx" */),
  "component---src-pages-sitemap-index-tsx": () => import("./../../../src/pages/sitemap/index.tsx" /* webpackChunkName: "component---src-pages-sitemap-index-tsx" */),
  "component---src-reflexjs-gatsby-theme-core-page-template-tsx": () => import("./../../../src/@reflexjs/gatsby-theme-core/page-template.tsx" /* webpackChunkName: "component---src-reflexjs-gatsby-theme-core-page-template-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/category-page.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */)
}


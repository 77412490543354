/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import * as React from "react";
import Footer from "../footer";
import Header from "../header";
import SEO from "../seo";
import { PreloadFonts } from "../../fonts";

type Props = {
  children: React.ReactNode;
  pathname?: string;
};

const Layout: React.FC<Props> = ({ children, pathname }) => (
  <Themed.root sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
    <SEO pathname={pathname} />
    <PreloadFonts />
    <Header />
    <main sx={{ flex: "1 1 auto" }}>{children}</main>
    <Footer />
  </Themed.root>
);

export default Layout;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, Theme } from "theme-ui";
import React, { HTMLAttributes, useState } from "react";
import { Link } from "gatsby-theme-themed-gatsby-link";
import _ from "lodash";
import { useTheme } from "@emotion/react";
import { get } from "@theme-ui/css";
import { usePopperTooltip } from "react-popper-tooltip";
import { animated, useTransition } from "@react-spring/web";
import { useGlossaryTerm } from "./glossary-context";
import { GlossaryTerm } from "../types";
import "react-popper-tooltip/dist/styles.css";

export type PureTermProps = {
  term: GlossaryTerm;
  id: string;
  theme: Theme;
  variant?: string;
};

const ReadMoreLink: React.FC<{ path: string; title: string }> = ({ path, title }) => (
  <>
    {" "}
    Learn more:{" "}
    <Link to={path} sx={{ display: "inline" }}>
      {title}
    </Link>
  </>
);

export const PureTerm: React.FC<PureTermProps> = ({
  term: { term, definition, path, title },
  theme,
  variant = "styles.glossary_term",
  children,
}) => {
  const [controlledVisible, setControlledVisible] = useState(false);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    delayHide: 250,
    interactive: true,
    onVisibleChange: setControlledVisible,
    placement: "top",
    visible: controlledVisible,
  });
  const transitions = useTransition(controlledVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 150 },
  });
  const content = children ?? term;

  const style = get(theme, variant);

  return (
    <span ref={setTriggerRef} sx={{ ...style, a: { display: "inline" } }}>
      {content}
      {transitions(
        ({ opacity }, item) =>
          item && (
            <animated.span
              ref={setTooltipRef}
              {...(getTooltipProps({
                className: "tooltip-container",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                style: { opacity: opacity as any },
              }) as HTMLAttributes<HTMLSpanElement>)}
            >
              <span {...(getArrowProps({ className: "tooltip-arrow" }) as HTMLAttributes<HTMLSpanElement>)} />
              <span>
                {definition}
                {path && <ReadMoreLink {...{ path, title }} />}
              </span>
            </animated.span>
          )
      )}
    </span>
  );
};

export type TermProps = {
  term: string | GlossaryTerm;
  variant?: string;
};

export const Term: React.FC<TermProps> = ({ term, children, variant }) => {
  const [id] = useState(_.uniqueId("term-"));
  const glossaryTerm = useGlossaryTerm(term);
  const theme = useTheme();

  if (!glossaryTerm) {
    return <>{children ?? term}</>;
  }

  return (
    <PureTerm id={id} term={glossaryTerm} theme={theme} variant={variant}>
      {children}
    </PureTerm>
  );
};

/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { ProductLink } from "./product-link";
import { ProductImage } from "./product-image";
import { ProductPropsFrom, PureProductProps } from "../types";
import wrapPureProductComponent from "./wrap-pure-product-component";

export type PureProductImageLinkProps = PureProductProps & {
  className?: string;
};

export const PureProductImageLink: React.FC<PureProductImageLinkProps> = ({ productData, className }) => (
  <ProductLink
    className={className}
    product={productData}
    sx={{ textAlign: "center", img: { width: ["100%", "75%", "50%"], height: "auto" } }}
  >
    <ProductImage product={productData} />
  </ProductLink>
);

export type ProductImageLinkProps = ProductPropsFrom<PureProductImageLinkProps>;
export const ProductImageLink: React.FC<ProductImageLinkProps> = wrapPureProductComponent(PureProductImageLink);

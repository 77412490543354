/** @jsx jsx */
import { Container, Flex, jsx } from "theme-ui";
import FollowButtons from "../follow-buttons";
import CookieConsentBanner from "../cookie-consent-banner";
import AffiliateDisclosure from "./affiliate-disclosure";
import FooterNavMenu from "./footer-nav-menu";

export const Footer: React.FC = () => (
  <footer sx={{ pt: "1", px: "0", borderTop: "1px solid", borderTopColor: "primary" }}>
    <CookieConsentBanner />
    <Container sx={{ display: "flex", flexFlow: "row wrap", padding: 0 }}>
      <Flex
        sx={{
          flexBasis: ["100%", "50%"],
          color: "darkGray",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: ["center", "start"],
          p: "3",
        }}
      >
        <nav sx={{ display: "flex", alignItems: "flex-start", mb: "4" }}>
          <span sx={{ mr: "1", my: "auto" }}>Follow Us:</span>
          <ul
            sx={{
              display: "flex",
              lineHeight: "0",
              justifyContent: "space-between",
              listStyleType: "none",
              m: "0",
              li: { mx: "3" },
            }}
          >
            <FollowButtons />
          </ul>
        </nav>
        <AffiliateDisclosure sx={{ fontSize: "16px", marginBottom: "0" }} />
      </Flex>
      <Flex
        sx={{
          alignItems: "center",
          flexBasis: ["100%", "50%"],
          flexDirection: "row",
          justifyContent: ["center", "flex-end"],
          p: "3",
        }}
      >
        <div id="pages-nav" sx={{ px: ["0", "4"] }}>
          <FooterNavMenu />
        </div>
        {/* <Box flexBasis={["100%", "33%"]} flexGrow="1">
            <FooterNavMenu name="categories" />
          </Box> */}
      </Flex>
    </Container>
  </footer>
);

export default Footer;

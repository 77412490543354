import Qty from "js-quantities";
import _ from "lodash";
import { isRecord } from "ts-is-record";
import Fraction from "fraction.js";
import { collapse } from "string-collapse-white-space";

// Move to separate types file

export type Quantity = {
  readonly value: number;
  readonly units: string;
};

export type QuantityWithNote = {
  readonly quantity?: Quantity;
  readonly note?: string;
};

export function reduce(numerator: number, denominator: number): number[] {
  // Euclid's algorithm
  function calcGCD(a: number, b: number): number {
    return b ? calcGCD(b, a % b) : a;
  }
  const gcd = calcGCD(numerator, denominator);
  return [numerator / gcd, denominator / gcd];
}

export function toFractionalInches(value: number, maxDenom = 64): string {
  // Only return exact conversions.
  if (!Number.isInteger(value * maxDenom)) {
    return `${Math.round(value * 1000) / 1000}"`;
  }

  const fraction = new Fraction(value);
  return `${fraction.toFraction(true)}"`;
}

const inchesRegExp = /^(?<fractional>.+)(?:\s*(?:in|"|″|”))$/;

// Try to convert fractional-inches-looking strings to a number.
//  If it looks like fractional inches, it must be valid; things that don't look like fractional inches return null.
export function fromFractionalInches(str: string): number | null {
  // Convert strings of the form 1-1/2" to 1 1/2"
  // eslint-disable-next-line no-param-reassign
  str = collapse(str.trim()).result.replace(/(\d)-(\d)/, "$1 $2");
  const groups = str.match(inchesRegExp)?.groups;
  if (!groups) {
    return null;
  }

  try {
    const fraction = new Fraction(groups.fractional);
    return fraction.valueOf();
  } catch (e: unknown) {
    return null;
  }
}

export function parseToUnits(str: string, tryFractionalInches = true): Qty | undefined {
  // eslint-disable-next-line no-param-reassign
  str = str.trim();
  if (_.isEmpty(str)) {
    return undefined;
  }

  let transformed = str.replace("°", " deg").replace("RPM", "rpm");
  if (transformed.endsWith(".")) {
    transformed = transformed.substr(0, str.length - 1)
  }
  let parsed = Qty.parse(transformed);
  if ((parsed === null || parsed.isUnitless()) && tryFractionalInches) {
    const inches = fromFractionalInches(transformed);
    if (inches !== null) {
      parsed = Qty(inches, "in");
    }
  }
  return parsed;
}

export function quantityToQty(value: Quantity): Qty {
  return Qty(value.value, value.units);
}

export function quantityWithNoteToQty(value: QuantityWithNote): Qty | undefined {
  return value.quantity ? quantityToQty(value.quantity) : undefined;
}

export function isQuantity(v: unknown): v is Quantity {
  return isRecord(v) && typeof v.value === "number" && typeof v.units === "string";
}

export function isQuantityWithNote(v: unknown): v is QuantityWithNote {
  return isRecord(v) && (isQuantity(v.quantity) || typeof v.note === "string");
}
interface QuantityToStringOptions {
  convertToImperial?: boolean;
  showOriginal?: boolean;
  replaceDeg?: boolean;
  units?: string;
}

export function quantityToString(
  value: Quantity,
  { convertToImperial = true, showOriginal = true, replaceDeg = true, units }: QuantityToStringOptions = {}
): string {
  const qty = quantityToQty(value);
  const formatter = units ? (s: number) => `${s} ${units}` : undefined;
  const asString = units ? qty.format(units, formatter) : qty.format();

  if (qty.kind() === "length" && convertToImperial) {
    const asInches = qty.to("in");
    const imperial = toFractionalInches(asInches.scalar);
    if (!showOriginal || qty.units() === "in") {
      return imperial;
    }
    return `${asString} (${imperial})`;
  }
  let result = asString;
  if (replaceDeg) {
    result = result.replace(" deg", "°");
  }
  return result;
}

export function quantityWithNoteToString(value: QuantityWithNote): string | undefined {
  if (isQuantityWithNote(value)) {
    if (value.quantity) {
      const qty = quantityToString(value.quantity);
      return value.note ? `${qty} (${value.note})` : qty;
    }
    if (value.note) {
      return value.note;
    }
  }
  return undefined;
}

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { ComparedProduct, ProductPropsFrom, PureProductProps, } from "gatsby-plugin-products";
import wrapPureProductComponent from "gatsby-plugin-products/src/components/wrap-pure-product-component"
import _ from "lodash";
import { RelatedPost } from "./related-post";

export type PureComparisonPostProps = PureProductProps<ComparedProduct> & {
  withProduct: string;
  className?: string;
  prefix?: string;
  variant?: string;
};

export const PureComparisonPost: React.FC<PureComparisonPostProps> = ({
  className,
  productData,
  withProduct,
  prefix = "Compare:",
  variant = "related_post",
}) => {
  const post = _.find(
    productData?.comparisons,
    ({ compared }) => _.difference(_.map(compared, "key"), [productData?.key, withProduct]).length === 0
  );
  if (!productData || !post) {
    return null;
  }

  return <RelatedPost className={className} prefix={prefix} post={post} variant={variant} />;
};

export type ComparisonPostProps = ProductPropsFrom<PureComparisonPostProps, ComparedProduct>;
export const ComparisonPost: React.FC<ComparisonPostProps> = wrapPureProductComponent(PureComparisonPost);

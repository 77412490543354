/** @jsx jsx */
import { Box, Card, jsx, Themed, ThemeUICSSObject } from "theme-ui";
import React, { ReactNode } from "react";
import _ from "lodash";
import { StylesFor } from "./variants";
import { DynamicHeading } from "./dynamic-heading";

export type ProsConsVariant = 0 | 1 | 2;

export type ProsConsProps = {
  pros?: readonly ReactNode[];
  prosHeader?: string;
  cons?: readonly ReactNode[];
  consHeader?: string;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  variant?: ProsConsVariant;
  className?: string;
};

export const ProsCons: React.FC<ProsConsProps> = ({
  pros = [],
  prosHeader = "Pros",
  cons = [],
  consHeader = "Cons",
  headingLevel = 3,
  variant = 0,
  className,
}) => {
  const proColor = variant === 1 ? "okay" : "primary";
  const conColor = variant === 1 ? "notOkay" : "primary";

  // Common style for two lists side-by-side (or stacked on mobile).
  const sideBySideStyle: ThemeUICSSObject = {
    display: "flex",
    flexDirection: ["column", "row"],
    fontWeight: "light",
    fontSize: "smaller",
    justifyContent: "space-between",
    ".pro_con_box": {
      maxWidth: ["100%", "calc(50% - 14px)"],
      flexBasis: ["100%", "calc(50% - 14px)"],
    },
    ".pro_con_heading": {
      fontFamily: "body",
      textAlign: "center",
      textTransform: "uppercase",
      p: 2,
    },
    ".pro_con_li": {
      pl: 2,
      mr: 2,
      listStyleType: "none",
      "&:before": { position: "relative", left: -2, ml: -4 },
      "&.pc_pro::before": { content: '"\\2714"', color: proColor },
      "&.pc_con::before": { content: '"\\2716"', color: conColor },
    },
  };

  const styles: StylesFor<ProsConsVariant> = [
    { display: "inherit", ".pro_con_heading": { variant: `styles.h${headingLevel}`, mb: "2" } },
    _.merge({}, sideBySideStyle, {
      ".pro_con_heading": {
        color: "primaryText",
        "&.pc_pro": { backgroundColor: "okay" },
        "&.pc_con": { backgroundColor: "notOkay" },
      },
    }),
    _.merge({}, sideBySideStyle, {
      ".pro_con_box": {
        border: 1,
        borderColor: "border",
        borderRadius: "5px",
        my: [4, 0],
      },
      ".pro_con_heading": {
        color: "primary",
        backgroundColor: "backgroundTinted",
        borderRadius: "5px 5px 0 0",
        mb: -2,
      },
    }),
  ];

  const style = styles[variant];

  const ProConBox: React.FC<{ items: readonly ReactNode[]; header: string; pcClass: string }> = ({
    items,
    header,
    pcClass,
  }) =>
    _.isEmpty(items) ? null : (
      <Box className="pro_con_box">
        <DynamicHeading className={`js-toc-ignore pro_con_heading ${pcClass}`} headingLevel={headingLevel} variant="">
          {header}
        </DynamicHeading>
        <Themed.ul>
          {items.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Themed.li className={`pro_con_li ${pcClass}`} key={index}>
              {entry}
            </Themed.li>
          ))}
        </Themed.ul>
      </Box>
    );

  return (
    <Card className={className} sx={{ ...style }}>
      <ProConBox items={pros} header={prosHeader} pcClass="pc_pro" />
      <ProConBox items={cons} header={consHeader} pcClass="pc_con" />
    </Card>
  );
};

import _ from "lodash";
import { isRecord } from "ts-is-record";
import { IGatsbyImageDataParent } from "gatsby-plugin-image/dist/src/components/hooks";
import { QuantityWithNote } from "./units";

export type Merchant = {
  key: string;
  displayName: string;
  affiliateID?: string;
};

export type ProductID = {
  merchant: Merchant;
  productID: string;
};

export type ProductDataValue = QuantityWithNote | string | boolean | number;
export type ProductData = Record<string, ProductDataValue>;

type ProductPost = {
  slug: string;
  title: string;
};

export type ProductBase = {
  key: string;
  // [x: string]: any;
};

export type NamedProduct = ProductBase & {
  model: string;
  brand: string;
  name: string;
  shortDescription?: string;
};

export type LinkableProduct = ProductBase & {
  ids?: readonly ProductID[];
  discontinued?: boolean;
};

export type ProductWithAlternatives = ProductBase & {
  alternatives?: readonly LinkableProduct[];
};

export type ComparisonTarget = NamedProduct & LinkableProduct;

export type ComparisonPost = ProductPost & {
  compared: readonly [ComparisonTarget, ComparisonTarget];
};

export type ComparedProduct = ProductBase & {
  comparisons?: readonly ComparisonPost[];
};

export type Product = ProductBase &
  NamedProduct &
  LinkableProduct &
  ComparedProduct & {
    pros?: readonly string[];
    cons?: readonly string[];
    compareGroup?: string;
    data?: ProductData;
    image?:
      | {
          childImageSharp?: IGatsbyImageDataParent<{}>;
        }
      | undefined;
    notes?: string;
    included?: readonly string[];
    review?: ProductPost;
  };

export function isProductBase(v: unknown): v is ProductBase {
  return isRecord(v) && typeof v.key === "string";
}

export function isProductBaseArray(v: unknown): v is ProductBase[] {
  return _.isArray(v) && _.every(v, isProductBase);
}

export function isNamedProduct(v: unknown): v is NamedProduct {
  return (
    isRecord(v) &&
    typeof v.model === "string" &&
    typeof v.brand === "string" &&
    typeof v.name === "string" &&
    isProductBase(v)
  );
}

export function isProduct<TProduct extends ProductBase = Product>(v: unknown): v is TProduct {
  return isRecord(v) && typeof v.key === "string";
}

export function isProductArray<TProduct extends ProductBase = Product>(v: unknown): v is readonly TProduct[] {
  return _.isArray(v) && _.every(v, isProduct);
}

export function isMerchant(v: unknown): v is Merchant {
  return isRecord(v) && typeof v.key === "string" && typeof v.displayName === "string";
}

export function isSpecificMerchant<T extends string>(v: unknown, merchant: T): v is Merchant & { key: T } {
  return isMerchant(v) && v.key === merchant;
}

export function isProductID(v: unknown): v is ProductID {
  return isRecord(v) && isMerchant(v.merchant) && typeof v.productID === "string";
}

export type SpecificMerchant<TMerchant extends string> = Merchant & { key: TMerchant };
export type ProductIDForMerchant<TMerchant extends string> = ProductID & { merchant: SpecificMerchant<TMerchant> };

export function isProductIDForSpecificMerchant<TMerchant extends string>(
  v: unknown,
  merchant: TMerchant
): v is ProductIDForMerchant<TMerchant> {
  return isProductID(v) && isSpecificMerchant(v.merchant, merchant);
}

export function productIDForMerchant<TMerchant extends string>(
  productIDs: readonly ProductID[] = [],
  merchant: TMerchant
): ProductIDForMerchant<TMerchant> | undefined {
  const result = _.find(productIDs, (pid) => isProductIDForSpecificMerchant(pid, merchant));
  // This should always succeed.
  return isProductIDForSpecificMerchant(result, merchant) ? result : undefined;
}

export type ProductsOrSelector<TProduct> =
  | _.ListIterateeCustom<TProduct | Product, boolean>
  | readonly string[]
  | TProduct
  | readonly TProduct[];
export interface ProductProps<TProduct = Product> {
  product?: string | TProduct;
}

export interface PureProductProps<TProduct = Product> {
  // TODO make this required, fix up helpers
  productData?: TProduct;
}

type ProductTypeFromPureProductProps<T> = T extends PureProductProps<infer TProduct> ? TProduct : never;

export type ProductPropsFrom<
  T extends PureProductProps<TProduct>,
  TProduct = ProductTypeFromPureProductProps<T>
> = Omit<T, keyof PureProductProps> & ProductProps<TProduct>;

export interface PluginConfig {
  contentPath?: string;
  basePath?: string;
  pagePath?: string;
  propagateTermsTo?: string[];
  termPageGqlFragmentPaths?: string[];
  termPageGqlFields?: string;
}

export const withDefaults = (pluginOptions?: PluginConfig): Required<PluginConfig> => ({
  contentPath: "content/glossary",
  basePath: "/glossary",
  pagePath: pluginOptions?.basePath ?? "/glossary",
  propagateTermsTo: [],
  termPageGqlFragmentPaths: [],
  termPageGqlFields: "",
  ...pluginOptions,
});

/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

type Props = {
  className?: string;
  adId: string;
};

const AmazonNativeAd: React.FC<Props> = ({ className, adId }) => (
  <div className={className}>
    <div id={`amzn-assoc-ad-${adId}`} />
    <script async src={`//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=${adId}`} />
  </div>
);

export default AmazonNativeAd;

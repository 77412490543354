import React, { useContext } from "react";
import { Product } from "../types";

export const ProductsContext = React.createContext([] as readonly Product[]);
interface Props {
  products?: readonly Product[];
}

export const useProductsContext = () => useContext(ProductsContext);

export const ProductsProvider: React.FC<Props> = ({ products = [], children }) => (
  <ProductsContext.Provider value={products}>{children}</ProductsContext.Provider>
);

export const ProductsConsumer = ProductsContext.Consumer;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, Themed } from "theme-ui";
import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GlossaryTermDetails } from "../types";

export type GlossaryTermArticleProps = {
  term: GlossaryTermDetails;
  className?: string;
};

export const GlossaryTermArticle: React.FC<GlossaryTermArticleProps> = ({ term: { body, title }, className }) => (
  <section className={className}>
    <Themed.h1>{title}</Themed.h1>
    <MDXRenderer>{body}</MDXRenderer>
  </section>
);

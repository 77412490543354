/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { MDXProvider } from "@theme-ui/mdx";
import { ProductProvider } from "gatsby-plugin-products";
import { Review } from "gatsby-theme-affiliate-posts";
import { FAQProvider } from "gatsby-theme-faq";
import React from "react";
import { jsx } from "theme-ui";
import { Article } from "./types";

const MaybeReview: React.FC<Pick<Article, "reviewed">> = ({ reviewed, children }) =>
  reviewed ? <Review>{children}</Review> : <>{children}</>;

export const ArticleContextProvider: React.FC<Article> = ({ reviewed, children }) => (
  <ProductProvider product={reviewed}>
    <FAQProvider>
      <MaybeReview reviewed={reviewed}>
        <MDXProvider>{children}</MDXProvider>
      </MaybeReview>
    </FAQProvider>
  </ProductProvider>
);

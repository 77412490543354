/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { Product, ProductName, ProductProps } from "gatsby-plugin-products";
import { useProductOrProducts } from "gatsby-plugin-products/src/use-product-or-products";
import _ from "lodash";
import { PureProductClauses } from "gatsby-plugin-products/src/components/product-clauses";
import ComparisonsLink from "./comparisons-link";

export type PureComparisonsLinksProps = {
  productData: readonly Product[];
  className?: string;
  ignoreSlug?: string;
  variant?: string;
};

export const PureComparisonsLinks: React.FC<PureComparisonsLinksProps> = ({ productData, ignoreSlug, ...props }) => {
  const comparisons = (product: Product) => _.filter(product.comparisons, (c) => !!c.slug && c.slug !== ignoreSlug);
  const productsWithComparisons = _.filter(productData, (product) => comparisons(product).length > 0);

  const overallPrefix = "You can view ";

  return (
    <PureProductClauses prefix={overallPrefix} suffix="." productData={productsWithComparisons}>
      {(product, index) => (
        <React.Fragment key={index}>
          comparisons of the <ProductName product={product} /> with{" "}
          <ComparisonsLink product={product} ignoreSlug={ignoreSlug} {...props} />
        </React.Fragment>
      )}
    </PureProductClauses>
  );
};

export type ComparisonsLinksProps = ProductProps & {
  products?: readonly string[] | readonly Product[];
  prefix?: string;
};

export const ComparisonsLinks: React.FC<ComparisonsLinksProps> = (props) => {
  const productData = useProductOrProducts<Product>(props);
  return <PureComparisonsLinks productData={productData} {...props} />;
};

export default ComparisonsLinks;

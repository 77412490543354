/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
import { Flex, jsx } from "theme-ui";
import * as React from "react";

type Props = {
  className?: string;
  post: GatsbyTypes.PostTeaserFragmentFragment;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PostMeta: React.FC<Props> = ({ post: { author, date, datetime }, className }) => (
  <Flex className={className}>
    {author && <span sx={{ mx: "1", fontWeight: "normal" }}>{author.name}</span>}
    {author && date && <span>{" • "}</span>}
    {date && (
      <time sx={{ mx: "1" }} dateTime={datetime}>
        {date}
      </time>
    )}
  </Flex>
);

import _ from "lodash";
import React, { useContext } from "react";
import { Template } from "./types";

type TemplateContextType = {
  // Map of lower-cased template name to its Template
  templates: Record<string, Template>;
};
export const TemplateContext = React.createContext({ templates: {} } as TemplateContextType);

export const useTemplate = (name: string): Template | undefined => {
  const context = useContext(TemplateContext);
  return context.templates[_.toLower(name)];
};

interface Props {
  templates?: readonly Template[];
}

export const TemplateProvider: React.FC<Props> = ({ templates = [], children }) => (
  <TemplateContext.Provider
    value={{ templates: _.keyBy(_.sortBy(templates, "timestamp"), (template) => _.toLower(template.name)) }}
  >
    {children}
  </TemplateContext.Provider>
);

export const TemplateConsumer = TemplateContext.Consumer;

/** @jsx jsx */
import { jsx } from "theme-ui";
import { Product, ProductProps, PureProductProps } from "../types";
import { useProduct } from "../use-product";

type PartialShallow<T> = {
  [P in keyof T]?: T[P] extends object ? object : T[P];
};

export type PropCustomizer<TProps, TProduct> = (
  props: Omit<TProps & ProductProps<TProduct>, "product">
) => TProps & PureProductProps<TProduct>;

function wrapPureProductComponent<TProps extends PureProductProps<TProduct>, TProduct extends PartialShallow<Product>>(
  WrappedComponent: React.ComponentType<TProps & PureProductProps<TProduct>>,
  customize: PropCustomizer<TProps, TProduct> = (x) => x as TProps
): React.FC<TProps & ProductProps<TProduct>> {
  const Wrapper = ({ product, ...props }: TProps & ProductProps<TProduct>) => {
    const productData = useProduct<Product>(product);
    const customizedProps = customize(props);

    return <WrappedComponent productData={productData} {...customizedProps} />;
  };

  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";
  Wrapper.displayName = displayName.replace("Pure", "");
  return Wrapper;
}

export default wrapPureProductComponent;

import * as React from "react";
import { graphql } from "gatsby";

const f = ({ data, ...props }) => <div />;

export const query = graphql`
  query ($id: String) {
    profile(id: { eq: $id }) {
      ...ProfileFragment
    }
  }
`;
export default f;

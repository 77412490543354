// Rely on GATSBY_NETLIFY_CONTEXT rather than reading a .env file so we can use it at runtime as well.
const { GATSBY_CONTEXT } = process.env;

if (!GATSBY_CONTEXT) {
  console.error("GATSBY_CONTEXT env var not set.")
  throw Error("GATSBY_CONTEXT env var not set.");
}

export const netlifyContext = GATSBY_CONTEXT || "development";
export const isNetlifyProduction = GATSBY_CONTEXT === "production";
export const isDevelopment = GATSBY_CONTEXT === "development";

/** @jsx jsx */
import { jsx, Link as ThemeUILink } from "theme-ui";
import React, { PropsWithoutRef } from "react";
import { Link as GatsbyLink } from "gatsby";
import isAbsoluteUrl from "is-absolute-url";
import { PropsOf } from "@emotion/react";

export type ExternalLinkProps = PropsOf<typeof ThemeUILink>;
export const ExternalLink: React.FC<ExternalLinkProps> = ThemeUILink;

export type InternalLinkProps = PropsWithoutRef<PropsOf<typeof GatsbyLink>> & {
  variant?: string;
};
export const InternalLink: React.FC<InternalLinkProps> = ({ ...props }) => <ThemeUILink {...props} as={GatsbyLink} />;

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  className?: string;
  activeClassName?: string;
  partiallyActive?: boolean;
  children: React.ReactNode;
  to?: string;
  href?: string;
  variant?: string;
  // ariaLabel?: string;
};

export const Link: React.FC<LinkProps> = ({ to, href, activeClassName, partiallyActive, className, ...props }) => {
  if (href && to) {
    throw new Error("Must specify only one of 'href' and 'to'.");
  }
  const url = to ?? href;

  // Use Gatsby Link for internal links, and <a> for others
  if (url) {
    if (!isAbsoluteUrl(url)) {
      return (
        <InternalLink
          className={className}
          to={url}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...props}
        />
      );
    }
  }
  return <ExternalLink href={url} className={className} {...props} />;
};

/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import React, { ReactElement, ReactNode } from "react";
import _ from "lodash";
import { useProducts } from "../use-products";
import { CheckProduct } from "./check-product";
import { ProductImageLink } from "./product-image-link";
import { Product, ProductsOrSelector } from "../types";
import { ProductsProvider } from "./products-context";
import { ProductName } from "./product-name";
import { describeFields, isNonApplicable } from "../describe-fields";
import { useProduct } from "../use-product";
import { Link } from "gatsby-theme-themed-gatsby-link";

const Row: React.FC<{
  label: ReactNode;
  values: ReactNode[];
  highlightMissingData?: boolean;
  missingDataCallback?: (i: number) => ReactElement | null;
}> = ({ label, values, highlightMissingData, missingDataCallback }) => {
  if (_.every(values, _.isNil)) {
    return null;
  }

  const missingColor = highlightMissingData ? "#ff8888" : undefined;
  return (
    <Themed.tr>
      <Themed.th scope="row" key="label">
        {label}
      </Themed.th>
      {values.map((value, key) => (
        <Themed.td
          // eslint-disable-next-line react/no-array-index-key
          key={key}
          sx={{
            background: value === undefined ? missingColor : undefined,
            color: isNonApplicable(value) ? "muted" : undefined,
          }}
        >
          {value ?? missingDataCallback?.(key)}
        </Themed.td>
      ))}
    </Themed.tr>
  );
};

type CommonProductTableProps = {
  className?: string;
  excludeFields?: string[];
  highlightMissingData?: boolean;
  includeCheckPrice?: boolean;
  includeImage?: boolean;
  includeUnknownData?: boolean;
  variant?: string;
};

export type PureProductTableProps = CommonProductTableProps & {
  productData?: readonly Product[];
};

const TableHead: React.FC<{ productData: readonly Product[]; includeImage?: boolean }> = ({
  productData,
  includeImage = true,
}) => {
  const nameHeaders = productData.map((product) => (
    <Themed.th scope="col" key={product.key}>
      <ProductName product={product} />
    </Themed.th>
  ));

  return (
    <thead>
      <Themed.tr key="header">
        <Themed.th scope="col" aria-label="feature" />
        {nameHeaders}
      </Themed.tr>
      {includeImage && (
        <Row
          key="picture"
          label=""
          values={productData.map((product) =>
            _.isEmpty(product.ids) ? null : (
              <ProductImageLink
                key={product.key}
                product={product}
                sx={{ maxWidth: "250px", img: { width: "100%", height: "auto" } }}
              />
            )
          )}
        />
      )}
    </thead>
  );
};

const TableBody: React.FC<{
  productData: readonly Product[];
  excludeFields: string[];
  highlightMissingData: boolean;
  includeUnknownData: boolean;
}> = ({ productData, highlightMissingData = false, includeUnknownData = false, excludeFields = [] }) => (
  <tbody>
    {describeFields(
      productData,
      (key, label, values) => (
        <Row
          key={key}
          label={label}
          values={values}
          highlightMissingData={highlightMissingData}
          missingDataCallback={(i) =>
            highlightMissingData ? (
              <Link to={`https://www.google.com/search?q=${productData[i].name} ${label}`} target="_blank">
                Search
              </Link>
            ) : null
          }
        />
      ),
      { excludeFields, includeUnknownData }
    )}
  </tbody>
);

const TableFoot: React.FC<{ productData: readonly Product[] }> = ({ productData }) => (
  <tfoot>
    <Row
      key="check"
      label=""
      values={productData.map((product) =>
        _.isEmpty(product.ids) ? null : (
          <CheckProduct textVariant="short" key={product.key} product={product.key} sx={{ marginBottom: "0" }} />
        )
      )}
    />
  </tfoot>
);

export const PureProductTable: React.FC<PureProductTableProps> = ({
  productData,
  variant = "styles.ProductTable",
  className,
  highlightMissingData = false,
  includeCheckPrice = true,
  includeImage = true,
  includeUnknownData = false,
  excludeFields = [],
}) => {
  if (!productData || _.isEmpty(productData)) {
    return null;
  }

  return (
    <ProductsProvider products={productData}>
      <Themed.table className={className} sx={{ variant }}>
        <TableHead productData={productData} includeImage={includeImage} />
        <TableBody
          productData={productData}
          highlightMissingData={highlightMissingData}
          includeUnknownData={includeUnknownData}
          excludeFields={excludeFields}
        />
        {includeCheckPrice && <TableFoot productData={productData} />}
      </Themed.table>
    </ProductsProvider>
  );
};

export type ProductTableProps = CommonProductTableProps & {
  products: ProductsOrSelector<Product>;
};

export const ProductTable: React.FC<ProductTableProps> = ({ products, ...props }) => {
  const productData = useProducts(products);
  if (_.isArray(products) && products.length !== productData.length) {
    // eslint-disable-next-line no-console
    console.error(`ProductTable: One or more of these products could not be found: ${products}`);
    return null;
  }

  return <PureProductTable productData={productData} {...props} />;
};

export type ProductSummaryTableProps = Omit<ProductTableProps, "products"> & {
  product?: string;
};

export const ProductSummaryTable: React.FC<ProductSummaryTableProps> = ({ product, ...props }) => {
  const productData = useProduct(product);
  if (!productData) {
    console.error(`ProductSummaryTable: Product could not be found: ${product}`);
    return null;
  }

  return (
    <ProductTable
      products={productData}
      includeImage={false}
      includeCheckPrice={false}
      excludeFields={["pros", "cons"]}
      variant="styles.ProductSummaryTable"
      {...props}
    />
  );
};

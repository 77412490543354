/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { graphql, PageProps } from "gatsby";
import React from "react";
import { Container, Flex, jsx, Themed } from "theme-ui";
import _ from "lodash";
import { Link } from "gatsby-theme-themed-gatsby-link";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

export const query = graphql`
  query Sitemap {
    allPage(sort: { fields: title }, filter: { slug: { nin: ["/contact-thanks"] } }) {
      nodes {
        slug
        title
      }
    }
    allPost(sort: { fields: title }, filter: { postType: { ne: comparison } }) {
      nodes {
        category {
          slug
          title
        }
        slug
        title
      }
    }
    allGlossaryTerm(filter: { page: { eq: true } }, sort: { fields: title }) {
      nodes {
        slug
        title
      }
    }
    allComparison: allPost(sort: { fields: title }, filter: { postType: { eq: comparison } }) {
      nodes {
        slug
        title
      }
    }
  }
`;

type Props = PageProps<GatsbyTypes.SitemapQuery>;

const SitemapPage = ({
  data: {
    allPage: { nodes: pages },
    allPost: { nodes: posts },
    allGlossaryTerm: { nodes: glossaryTerms },
    allComparison: { nodes: comparisons },
  },
}: Props): React.ReactNode => {
  const categorizedPosts = _.groupBy(posts, (p) => p.category?.title ?? "Other");

  // Pretend glossary term pages are regular articles.
  categorizedPosts.Terms = [
    ...(categorizedPosts.Terms ?? []),
    ..._.map(glossaryTerms, (term) => ({ ...term, category: { slug: "/terms", title: "Terms" } })),
  ];

  return (
    <Layout>
      <SEO title="Sitemap" excerpt="List of all the pages on the site." />
      <Container sx={{ py: [8, 12, 14], li: { listStyle: "none" }, ul: { ml: 0 } }}>
        <Themed.h1>Sitemap</Themed.h1>

        <Themed.h2>Pages</Themed.h2>
        <Themed.ul>
          {_.map(pages, (page) => (
            <Themed.li>
              <Link to={page.slug}>{page.title}</Link>
            </Themed.li>
          ))}
        </Themed.ul>

        <Themed.h2>Articles</Themed.h2>
        {_.map(_.sortBy(_.keys(categorizedPosts)), (categoryTitle) => {
          const categoryPosts = categorizedPosts[categoryTitle];
          const { category } = categoryPosts[0];

          return (
            <>
              <Themed.h3>
                {category ? <Link to={categoryPosts[0].category?.slug}>{categoryTitle}</Link> : categoryTitle}
              </Themed.h3>
              <Themed.ul>
                {_.map(categoryPosts, (post) => (
                  <Themed.li>
                    <Link to={post.slug}>{post.title}</Link>
                  </Themed.li>
                ))}
              </Themed.ul>
            </>
          );
        })}

        <Themed.h2>Comparisons</Themed.h2>
        <Flex as="ul" sx={{ flexWrap: "wrap", fontSize: "sm", p: 0 }}>
          {_.map(comparisons, (comparison) => (
            <Themed.li sx={{ flexBasis: ["100%", "50%", "33%"] }}>
              <Link to={comparison.slug}>{comparison.title}</Link>
            </Themed.li>
          ))}
        </Flex>
      </Container>
    </Layout>
  );
};

export default SitemapPage;

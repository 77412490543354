/* eslint-disable no-nested-ternary */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { hasPresentKey } from "ts-is-present";
import { Link } from "gatsby-theme-themed-gatsby-link";
import { Product, ProductProps } from "../types";
import { ProductName } from "./product-name";
import { PureProductClauses } from "./product-clauses";
import { useProductOrProducts } from "../use-product-or-products";

export type PureFullReviewLinkProps = {
  productData: readonly Product[];
  prefix?: string;
};

export const PureFullReviewLink: React.FC<PureFullReviewLinkProps> = ({ productData, prefix = "Read our" }) => {
  const productsWithReviews = productData.filter(hasPresentKey("review"));

  const count = productsWithReviews.length;
  const overallPrefix = count === 1 ? `${prefix} ` : `${prefix} full reviews `;
  const eachPrefix = count === 1 ? "full review of the " : "of the ";

  return (
    <PureProductClauses prefix={overallPrefix} suffix="." productData={productsWithReviews}>
      {(product, index) => (
        <Link to={product.review.slug} key={index}>
          {eachPrefix}
          <ProductName product={product} />
        </Link>
      )}
    </PureProductClauses>
  );
};

export type FullReviewLinkProps = ProductProps & {
  products?: readonly string[] | readonly Product[];
  prefix?: string;
};

export const FullReviewLink: React.FC<FullReviewLinkProps> = (props) => {
  const productData = useProductOrProducts<Product>(props);
  return <PureFullReviewLink productData={productData} {...props} />;
};

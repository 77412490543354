/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui";
import { ReactElement } from "react";
import { Link } from "../../link";
import ArticleImage from "../article/article-image";
import { PostMeta } from "./post-meta";

type Props = {
  // TODO more explicit type that doesn't rely on GatsbyTypes
  post: GatsbyTypes.PostTeaserFragmentFragment & { slug: string };
};

const PostTeaser = ({ post }: Props): ReactElement => {
  const { title, slug, image, imageFile /* , date, datetime, author */ } = post;
  return (
    <article
      sx={{
        minHeight: "100%",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 10px 0px;",
        transition: "box-shadow .25s ease",
        "&:hover": { boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 10px 0px;" },
      }}
    >
      <Grid sx={{ gridAutoFlow: "dense" }}>
        {(image || imageFile) && (
          <Link to={slug} sx={{ display: "block" }}>
            <Box
              sx={{
                pb: "66%",
                overflow: "hidden",
                position: "relative",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                "&:after": {
                  content: '""',
                  backgroundImage: "linear-gradient(0deg,rgba(0,0,0,.35),transparent 75%)",
                  bottom: "0",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                },
              }}
            >
              <ArticleImage
                imageUrl={image}
                imageFile={imageFile?.childImageSharp?.gatsbyImageData}
                alt={title || "TODO"}
                sx={{
                  width: "100%",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Box>
          </Link>
        )}
        {title && (
          <Link to={slug}>
            <Box
              sx={{
                variant: "styles.h3",
                mt: "2",
                px: "2",
                py: "0",
                color: "darkGray",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {title}
            </Box>
          </Link>
        )}
        {/* {excerpt && <P mt="1">{excerpt}</P>} */}
        <PostMeta
          post={post}
          sx={{
            px: "2",
            py: "1",
            borderTop: "1",
            borderTopColor: "lightGray",
            color: "lightGray",
            fontSize: "xs",
            textAlign: "center",
            justifyContent: "center",
          }}
        />
      </Grid>
    </article>
  );
};

export default PostTeaser;

/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";
import TocbotComponent from "../tocbot";

type Props = {
  open?: boolean;
  className?: string;
};

const TocDetails: React.FC<Props> = ({ open = false, children, className }) => (
  <details
    className={className}
    open={open}
    sx={{
      border: "1px solid",
      borderColor: "mutedBorder",
      padding: "1",
      mb: "0",
      px: "2",
      position: ["static", "static", "static", "sticky"],
      overflow: "auto",
      top: "65px",
      variant: "styles.PostExtra.details",
      maxHeight: "50vh",
      a: { textDecoration: "none" },
    }}
  >
    <summary
      sx={{
        margin: "0",
        textTransform: "uppercase",
        letterSpacing: "wider",
        fontWeight: "bold",
        color: "muted",
        fontSize: "sm",
        variant: "styles.PostExtra.title",
      }}
    >
      Table of contents
    </summary>
    {children}
  </details>
);

type TocProps = {
  replacements?: Record<string, string>;
  className?: string;
};

const TableOfContents: React.FC<TocProps> = ({ className, ...props }): React.ReactElement => {
  const tocOpen = useResponsiveValue([false, false, false, true]);
  const tocDiv = (
    <Themed.div
      id="toc"
      sx={{
        fontSize: "sm",
        li: {
          listStyle: "none",
        },
        // Keep h1 and h2 at same indent level, but indent h3
        ul: {
          m: 0,
          p: 0,
        },
        "ul ul ul": {
          pl: "2",
        },
        a: {
          color: "muted",
          ":hover": {
            color: "inherit",
            textShadow: "0 0 .65px, 0 0 .65px",
          },
        },
      }}
    />
  );

  return (
    <Themed.div className={className}>
      <TocbotComponent {...props} />
      <TocDetails sx={{ display: ["none", null, null, "block"] }} open>
        {tocOpen ? tocDiv : null}
      </TocDetails>
      <TocDetails sx={{ display: ["block", null, null, "none"] }}>{tocOpen ? null : tocDiv}</TocDetails>
    </Themed.div>
  );
};

export default TableOfContents;

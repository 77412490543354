import React from "react";
import {
  EmailShareButton as EmailShareBtn,
  EmailIcon,
  FacebookShareButton as FacebookShareBtn,
  FacebookShareCount,
  FacebookIcon,
  PinterestShareButton as PinterestShareBtn,
  PinterestShareCount,
  PinterestIcon,
  TwitterShareButton as TwitterShareBtn,
  TwitterIcon,
} from "react-share";
import { buildAbsoluteURL } from "url-toolkit";
import { useSiteMetadata } from "../sitemetadata";

type PostSharingInfo = Pick<GatsbyTypes.Post, "title" | "excerpt" | "image" | "slug">;

type ShareButtonProps = {
  className?: string;
  mobile?: boolean;
  post: PostSharingInfo;
  url: string;
  // eslint-disable-next-line react/no-unused-prop-types
  style?: React.CSSProperties;
};

const filter = (count: number) => (count > 0 ? count : "");
const renderShareCount = (count: number) => <div className="share-count">{filter(count)}</div>;
const iconSize = (mobile?: boolean) => (mobile ? 36 : 48);
const defaultShareButtonStyle = {
  margin: "10px 10px",
};

export const FacebookShareButton: React.FC<ShareButtonProps> = ({ className, mobile, url, post: { excerpt } }) => (
  <FacebookShareBtn className={className} url={url} quote={excerpt} style={defaultShareButtonStyle} aria-label="Share on Facebook">
    <FacebookIcon round size={iconSize(mobile)} />
    <FacebookShareCount url={url}>{(count) => renderShareCount(count)}</FacebookShareCount>
  </FacebookShareBtn>
);

export const TwitterShareButton: React.FC<ShareButtonProps> = ({ className, mobile, url, post: { title } }) => (
  <TwitterShareBtn className={className} url={url} title={title} style={defaultShareButtonStyle} aria-label="Share on Twitter">
    <TwitterIcon round size={iconSize(mobile)} />
  </TwitterShareBtn>
);

export const PinterestShareButton: React.FC<ShareButtonProps> = ({ className, mobile, url, post: { image } }) =>
  // TODO use site logo instead?
  image ? (
    <PinterestShareBtn className={className} url={url} media={image} style={defaultShareButtonStyle} aria-label="Share on Pinterest">
      <PinterestIcon round size={iconSize(mobile)} />
      <PinterestShareCount url={url}>{(count) => renderShareCount(count)}</PinterestShareCount>
    </PinterestShareBtn>
  ) : null;

export const EmailShareButton: React.FC<ShareButtonProps> = ({ className, mobile, url, post: { excerpt, title } }) => (
  <EmailShareBtn className={className} subject={title} body={excerpt} url={url} style={defaultShareButtonStyle} aria-label="Share via e-mail">
    <EmailIcon round size={iconSize(mobile)} />
  </EmailShareBtn>
);

// TODO Print button

type ShareButtonsProps = {
  className?: string;
  mobile?: boolean;
  post: PostSharingInfo;
  // eslint-disable-next-line react/no-unused-prop-types
  style?: React.CSSProperties;
};

const ShareButtons: React.FC<ShareButtonsProps> = ({ className, mobile, post, style }) => {
  const siteMetadata = useSiteMetadata();
  if (!post.slug) return null;

  const url = buildAbsoluteURL(siteMetadata.siteUrl, post.slug);
  const props = { mobile, post, url };
  return (
    <div className={className} style={style}>
      <FacebookShareButton {...props} />
      <TwitterShareButton {...props} />
      <PinterestShareButton {...props} />
      <EmailShareButton {...props} />
    </div>
  );
};
export default ShareButtons;

/* eslint-disable react/no-unescaped-entities */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";
import { navigate } from "gatsby";
import { ErrorMessage as ErrorMsg, Formik, Field } from "formik";
import Reaptcha from "reaptcha";
import * as Yup from "yup";
import { stringify } from "querystring";

const ErrorMessage = (props: { name: string }) => (
  <span sx={{ color: "errorText", ml: "4" }}>
    <ErrorMsg {...props} />
  </span>
);

const ContactForm: React.FC = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Enter a valid email address").required("Email address is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null);

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        subject: "",
        message: "",
      }}
      onSubmit={(data, { resetForm }) => {
        const body = stringify({ "form-name": "contact-form", ...data, "g-recaptcha-response": recaptchaResponse });
        fetch("/?no-cache=1", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body,
        })
          .then(() => {
            resetForm();
            navigate("/contact-thanks/");
          })
          .catch((/* error */) => {
            // console.log(error);
            // TODO
          });
      }}
      validationSchema={validationSchema}
    >
      {() => (
        <form name="contact-form" data-netlify data-netlify-honeypot="human" data-netlify-recaptcha="true">
          <p>
            <label htmlFor="name">Your name (required)</label>
            <ErrorMessage name="name" />
            <Field id="name" name="name" width="100%" />
          </p>
          <p>
            <label htmlFor="email">Your email address (required)</label>
            <ErrorMessage name="email" />
            <Field id="email" name="email" />
          </p>
          <p>
            <label htmlFor="subject">Subject (required)</label>
            <ErrorMessage name="subject" />
            <Field id="subject" name="subject" />
          </p>
          <p>
            <label htmlFor="message">Message (required)</label>
            <ErrorMessage name="message" />
            <Field name="message" component="textarea" rows="10" />
          </p>
          <p sx={{ display: "none" }}>
            <label htmlFor="human">Type "human" here to show you're not a bot</label>
            <input name="human" />
          </p>
          <div sx={{ mb: "2" }}>
            <Reaptcha
              sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY ?? ""}
              onVerify={(response) => {
                // console.log(`captcha recaptchaResponse: ${response}`);
                setRecaptchaResponse(response);
              }}
            />
          </div>
          <p>
            <button type="submit" disabled={!recaptchaResponse && process.env.NODE_ENV !== "development"}>
              Send
            </button>
          </p>
        </form>
      )}
    </Formik>
  );
};

export default ContactForm;

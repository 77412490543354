import _ from "lodash";
import { ReactNode } from "react";

/* eslint-disable import/prefer-default-export */
export function clauseList(nodes: ReactNode[], conjunction = "and"): ReactNode[] {
  const validNodes = _.filter(nodes);

  const count = validNodes.length;
  if (count < 2) {
    return validNodes;
  }

  return _.filter(
    _.flatMap(validNodes, (node, index) => [
      node,
      count > 2 && index < count - 1 ? ", " : undefined,
      index === count - 2 ? ` ${conjunction} ` : undefined,
    ])
  );
}

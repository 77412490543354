/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { ReactNode } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck as faTrueIcon } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes as faFalseIcon } from "@fortawesome/free-solid-svg-icons/faTimes";
import { smartypantsu } from "smartypants";
import { Product, ProductDataValue } from "./types";
import getProductFieldDescriptions, { PropertyDescriptions } from "./product-field-descriptions";
import { isQuantityWithNote, quantityWithNoteToString } from "./units";

export const isNonApplicable = (x: unknown) => x === "n/a";

const List = ({ values }: { values: ReactNode[] }) => (
  <Themed.ul>
    {values.map((v, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Themed.li key={index}>{v}</Themed.li>
    ))}
  </Themed.ul>
);

const BooleanIcon: React.FC<{ value: boolean }> = ({ value }) =>
  value ? (
    <FontAwesomeIcon icon={faTrueIcon} sx={{ color: "okay" }} />
  ) : (
    <FontAwesomeIcon icon={faFalseIcon} sx={{ color: "notOkay" }} />
  );

function formatValue(value: ProductDataValue) {
  if (_.isBoolean(value)) {
    return <BooleanIcon value={value} />;
  }
  if (_.isArray(value)) {
    return <List values={value} />;
  }

  let result: string | number | undefined;
  if (isQuantityWithNote(value)) {
    result = quantityWithNoteToString(value);
  } else {
    result = value;
  }

  if (_.isString(result)) {
    result = smartypantsu(result, 1);
  }
  return result;
}

const nonNil = (value: any) => !_.isNil(value);

const unknownFieldDescriptions = (descriptions: PropertyDescriptions, products: readonly Product[]) => {
  const hasNoDescription = (dottedPath: string) => !(dottedPath in descriptions);
  const missingFields = _.uniq(
    _.flatMap(products, (product) =>
      _.filter(
        _.map(_.keys(product.data), (key) => `data.${key}`),
        hasNoDescription
      )
    )
  );

  const missing = (key: string) => <span sx={{ color: "red" }}>{`Unknown: ${key}`}</span>;
  return _.mapValues(_.keyBy(missingFields), () => missing);
};

const getProductCategory = (products: readonly Product[]) => {
  const productCategories = _.uniq(_.map(products, "productCategory.name"));
  if (productCategories.length !== 1) {
    console.warn("Could not determine product category, using defaults.");
    return undefined;
  }

  const [productCategory] = productCategories;
  return productCategory;
};

export function describeFields(
  products: Product | readonly Product[],
  f: (key: string, label: string, values: ReactNode[]) => ReactNode,
  options: { excludeFields?: readonly string[]; fields?: readonly string[]; includeUnknownData?: boolean } = {}
) {
  const productArray = _.castArray(products);

  let descriptions = getProductFieldDescriptions(getProductCategory(productArray));

  if (options?.includeUnknownData) {
    // If we want to display known data for debugging purposes, add fake descriptions for any data fields that don't have a description.
    descriptions = _.merge(descriptions, unknownFieldDescriptions(descriptions, productArray));
  }

  const { excludeFields = [], fields = _.keys(descriptions) } = options;
  const validFields = _.difference(_.intersection(fields, _.keys(descriptions)), excludeFields);

  return _.flatMap(validFields, (field) => {
    const fieldDescription = descriptions[field];
    const [description, formatter = _.get] = _.isArray(fieldDescription) ? fieldDescription : [fieldDescription];

    const values = _.map(productArray, (product) => formatter(product, field));

    if (_.some(values, nonNil)) {
      const formattedValues = values.map((value) => (_.isNil(value) ? undefined : formatValue(value)));

      // If every value is an explicit "n/a", just don't show the row (i.e., only show "n/a" if some products have a real value).
      if (!_.every(formattedValues, isNonApplicable)) {
        const descriptionNode = _.isFunction(description) ? description(field) : description;

        return [f(field, descriptionNode, formattedValues)];
      }
    }

    return [];
  });
}

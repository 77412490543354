/* @jsx jsx */
/* @jsxFrag React.Fragment */
import { Box, BoxProps, Button, ButtonProps, Container, Grid, jsx, LinkProps } from "theme-ui";
import React, { useEffect, useLayoutEffect, ReactElement } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { NavMenu, NavMenuPopup } from "../nav-menu";
import siteConfig from "../../../data/site-config";
import Logo from "../../../content/images/Color Logo.svg";
import Wordmark from "../../../content/images/Black Wordmark no Background.svg";
import Link from "../../link";

const MenuButton: React.FC<ButtonProps> = ({ ...props }) => (
  <Button
    type="button"
    aria-label="menu"
    sx={{ display: "flex", alignItems: "center", variant: "buttons.link", py: 1 }}
    {...props}
  >
    <FontAwesomeIcon icon={faBars} />
  </Button>
);

export const Branding: React.FC<LinkProps> = ({ className }): ReactElement => {
  const { siteTitle } = siteConfig;
  return (
    <Link
      className={className}
      to="/"
      sx={{
        display: "flex",
        gap: "1",
        img: {
          height: "100%",
          width: "auto",
          display: "inline",
          border: "0",
          color: "transparent",
          verticalAlign: "middle",
        },
      }}
    >
      <img src={Logo} alt={`${siteTitle} logo`} height="150" width="150" />
      <img src={Wordmark} alt={siteTitle} height="150" width="215" />
    </Link>
  );
};

const Sticky: React.FC<BoxProps> = ({ children, className }) => (
  <>
    <Box
      className={className}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      {children}
    </Box>
    <Box className={className} sx={{ visibility: "hidden" }}>
      {children}
    </Box>
  </>
);

const headerNav = {
  items: [
    {
      url: "/table-saws",
      value: "Table Saws",
    },
    {
      url: "/circular-saws",
      value: "Circular Saws",
    },
    {
      url: "/about",
      value: "About Us",
    },
  ],
};

const Header: React.FC = (): ReactElement => {
  const [state, setState] = React.useState({ showMenu: false, isSticky: false });
  const { isSticky } = state;
  const normalHeight = 60;
  const stickyHeight = 35;
  const height = state.isSticky ? stickyHeight : normalHeight;
  const calcSticky = () => setState((prev) => ({ ...prev, isSticky: window.pageYOffset > stickyHeight }));

  useLayoutEffect(() => {
    calcSticky();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", _.throttle(calcSticky, 100));
    return () => {
      window.removeEventListener("scroll", () => calcSticky);
    };
  }, []);

  // We render a hidden div the same height as the header so the rest of the content is pushed down the appropriate amount.
  return (
    <header>
      <Sticky sx={{ bg: "white", borderBottom: "1", borderBottomColor: "primary", zIndex: "1000" }}>
        <Container sx={{ px: [0, 0, 0, 0] }}>
          <Grid
            gap="0"
            sx={{
              gridAutoFlow: "dense",
              gridTemplateColumns: ["4rem 1fr 4rem", null, isSticky ? "150px 1fr 150px" : "3"],
              gridTemplateAreas: [
                '"menu branding ."',
                null,
                isSticky ? '"branding nav nav"' : '". branding ." "nav nav nav"',
              ],
            }}
          >
            <MenuButton
              onClick={() => setState((prev) => ({ ...prev, showMenu: !prev.showMenu }))}
              sx={{
                display: ["flex", null, "none"],
                gridArea: "menu",
              }}
            />
            <Branding
              sx={{
                transition: "all 0.2s",
                gridArea: "branding",
                my: "1",
                height,
                justifyContent: ["center", null, isSticky ? "start" : "center"],
              }}
            />
            <NavMenu
              menu={headerNav}
              sx={{
                li: { mx: "auto" },
                display: ["none", null, "grid"],
                gridArea: "nav",
                m: "auto",
                variant: "styles.PagesNavMenu",
              }}
            />
          </Grid>
          <NavMenuPopup show={state.showMenu}>
            <NavMenu menu={headerNav} vertical direction="up" sx={{ gap: [0, 1], variant: "styles.PagesNavMenu" }} />
          </NavMenuPopup>
        </Container>
      </Sticky>
    </header>
  );
};

/*
export const ModeToggle: React.FC<ButtonProps> = ({ className }): ReactElement => {
  const [colorMode, setColorMode] = useColorMode();
  return (
    <button
      className={className}
      type="button"
      variant="button.icon"
      onClick={() => setColorMode(colorMode === "default" ? "dark" : "default")}
      sx={{
        ml: [2, 4],
        color: "lightGray",
        _hover: { color: "lightGray" },
        _focus: { color: "lightGray" },
      }}
    >
      <Icon name={colorMode === "default" ? "sun" : "moon"} size="5" />
      <VisuallyHidden>Toggle mode</VisuallyHidden>
    </button>
  );
};
*/

export default Header;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, Themed } from "theme-ui";
import React from "react";
import _ from "lodash";
import { GlossaryTerm } from "../types";
import { GlossarySection } from "./glossary-section";

export type GlossaryProps = {
  className?: string;
  terms: readonly GlossaryTerm[];
};

export const Glossary: React.FC<GlossaryProps> = ({ className, terms }) => {
  // Primary terms are those that do not have their own primary term (i.e., are not variants).
  const primaryTerms = _.filter(terms, (term) => !term.primary);
  const bySection = _.groupBy(primaryTerms, (term) => term.term[0]);
  const sections = _.sortBy(_.keys(bySection));

  return (
    <article className={className}>
      <Themed.h1 className="js-toc-ignore">Glossary</Themed.h1>
      <ul>
        {_.map(sections, (section) => (
          <li key={`section_${section}`}>
            <GlossarySection heading={section} terms={bySection[section]} />
          </li>
        ))}
      </ul>
    </article>
  );
};

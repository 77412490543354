/* eslint-disable react/no-unused-prop-types */
import React, { FunctionComponent } from "react";
import { FacebookIcon, PinterestIcon, TwitterIcon } from "react-share";
import Link from "../link";
import config from "../../data/site-config";

type FollowButtonProps = {
  className?: string;
  mobile?: boolean;
  href: string;
};

type FollowButtonsProps = Omit<FollowButtonProps, "href">;

const iconSize = (mobile?: boolean) => (mobile ? 36 : 36);

const FollowLink: FunctionComponent<{ className?: string; href: string; ariaLabel: string }> = ({
  className,
  href,
  children,
  ariaLabel,
}) => (
  <Link
    className={className}
    to={href}
    aria-label={ariaLabel}
    target="_blank"
    rel="noopener"
  >
    {children}
  </Link>
);

export const FacebookFollowButton: React.FC<FollowButtonProps> = ({ className, mobile, href }) => (
  <FollowLink className={className} href={href} ariaLabel="Visit our Facebook page">
    <FacebookIcon round display="block" size={iconSize(mobile)} />
  </FollowLink>
);

export const TwitterFollowButton: React.FC<FollowButtonProps> = ({ className, mobile, href }) => (
  <FollowLink className={className} href={href} ariaLabel="Visit our Twitter feed">
    <TwitterIcon round display="block" size={iconSize(mobile)} />
  </FollowLink>
);

export const PinterestFollowButton: React.FC<FollowButtonProps> = ({ className, mobile, href }) => (
  <FollowLink className={className} href={href} ariaLabel="Visit our Pinterest page">
    <PinterestIcon round display="block" size={iconSize(mobile)} />
  </FollowLink>
);

const FollowButtons: React.FC<FollowButtonsProps> = ({ mobile }) => {
  const { socialProfiles: { facebook = null, twitter = null, pinterest = null } = {} } = config;

  return (
    <>
      {facebook?.profile && (
        <li key="facebook">
          <FacebookFollowButton mobile={mobile} href={facebook.profile} />
        </li>
      )}
      {twitter?.profile && (
        <li key="twitter">
          <TwitterFollowButton mobile={mobile} href={twitter.profile} />
        </li>
      )}
      {pinterest?.profile && (
        <li key="pinterest">
          <PinterestFollowButton mobile={mobile} href={pinterest.profile} />
        </li>
      )}
    </>
  );
};
export default FollowButtons;

/** @jsx jsx */
import { jsx, Themed } from "theme-ui";

type AffiliateDisclaimerProps = {
  className?: string;
};
const AffiliateDisclaimer: React.FC<AffiliateDisclaimerProps> = ({ className }) => (
  <aside className={className} sx={{ fontSize: "xs", textAlign: "center", pt: "10px", pb: "20px", color: "primary" }}>
    <Themed.em>
      This post may include affiliate links that pay us a small commission on products you buy. This does not affect the
      price you pay.
    </Themed.em>
  </aside>
);

export default AffiliateDisclaimer;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Box, BoxProps, Divider, DividerProps, Flex, jsx, Paragraph, Text } from "theme-ui";
import _ from "lodash";
import { FAQSection, HideInSchema, InlineFAQ } from "gatsby-theme-faq";
import { Term, TermifyChildren } from "gatsby-theme-glossary";
import {
  ProductProvider,
  CheckProduct,
  CompareProduct,
  FullReviewLink,
  ProductAlternatives,
  ProductField,
  ProductFieldList,
  ProductImageLink,
  ProductLink,
  ProductLinks,
  ProductName,
  ProductProsCons,
  ProductSummaryBox,
  ProductSummaryTable,
  ProductTable,
  Recommendation,
  Roundup,
  RoundupRecap,
  ShortReview,
  VariantProvider,
  ProductNameProps
} from "gatsby-plugin-products";
import {
  Attribution,
  ComparisonPost,
  FigAttribution,
  FigCaption,
  Figure,
  JumpTo,
  PostLink,
  PostSummary,
  RelatedPost,
  Review,
} from "gatsby-theme-affiliate-posts";
import React from "react";
import { Link } from "gatsby-theme-themed-gatsby-link";
import ContactForm from "../components/contact-form";
import AuthorBio from "../components/author-bio";
import { RoundupRecapFAQ } from "../components/roundup-recap-faq";
import Template from "../components/template"
import { ComparisonsLink, ComparisonsLinks } from "../components/comparison";

// By default, theme-ui's MDXProvider does not support adding props to elements like <div>
//  Enable styling via sx prop by customizing the components.

// These tags will have glossary terms automatically highlighted.
const termifyTags = ["p"];

// From https://github.com/system-ui/theme-ui/blob/v0.12.0/packages/mdx/src/index.ts
const tags = [
  ...termifyTags,
  "b",
  "i",
  "a",
  "li",
  "th",
  "td",
  "em",
  "strong",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "img",
  "pre",
  "code",
  "ol",
  "ul",
  "blockquote",
  "hr",
  "em",
  "table",
  "tr",
  "del",
  // mdx
  "inlineCode",
  "thematicBreak",
  // other
  "div",
  // theme-ui
  "root",
];

// eslint-disable-next-line react/display-name
const termifyChildren = (tag: string) => (props: any) => <TermifyChildren {...props} tag={tag} />;

const componentsWithProps = _.mapValues(_.keyBy(tags), (tag) =>
  termifyTags.includes(tag) ? termifyChildren(tag) : (props: any) => jsx(tag, props)
);

const wrapper: React.FC = ({ children }) => {
  const updatedChildren = React.Children.map(children, (child, index) => {
    // Wrap any top-level raw images in a <figure>.
    // Leave alone any with attributes explicitly specified via gatsby-remark-image-attributes, which have a class of "gatsby-img-attributes" instead.
    if (React.isValidElement(child) && child.props.className === "gatsby-resp-image-wrapper") {
      return (
        <Figure key={`image-${index}`} variant="centered">
          {child}
        </Figure>
      );
    }
    return child;
  });
  return <>{updatedChildren}</>;
};

const MDXComponents = {
  Attribution,
  AuthorBio,
  Box,
  CheckProduct,
  CompareProduct,
  ComparisonsLink,
  ComparisonsLinks,
  ComparisonPost,
  ContactForm,
  FAQSection,
  FigAttribution,
  figcaption: FigCaption,
  figure: Figure,
  Flex,
  FloatContainer: (props: BoxProps) => <Box {...props} sx={{ overflow: "auto" }} />,
  FullReview: FullReviewLink,
  HideInSchema,
  InlineFAQ,
  JumpTo,
  Link,
  Paragraph,
  PModel: (props: ProductNameProps) => <ProductName {...props} nameType="model" />,
  PostLink,
  PostSummary,
  ProductAlternatives,
  ProductProvider,
  ProductField,
  ProductFieldList,
  ProductImageLink,
  ProductLink,
  ProductLinks,
  ProductName,
  ProductProsCons,
  ProductSummaryBox,
  ProductSummaryTable,
  ProductTable,
  Recommendation,
  RelatedPost,
  Review,
  Roundup,
  RoundupRecap,
  RoundupRecapFAQ,
  SectionDivider: (props: DividerProps) => <Divider {...props} sx={{ width: "90%", mx: "auto", color: "primary", mb: 7 }} />,
  ShortReview,
  Template,
  Term,
  Text,
  VariantProvider,
  ...componentsWithProps,
  wrapper,
};

export default MDXComponents;

/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import { ProductBase } from "../types";
import { isStringArray } from "./is-string-array";

export function filterProducts<TProduct extends ProductBase>(
  products: readonly TProduct[],
  filters?: _.ListIterateeCustom<TProduct, boolean> | readonly string[]
): TProduct[] {
  if (!products) return [];

  if (typeof filters === "string") {
    // eslint-disable-next-line no-param-reassign
    filters = [filters];
  }

  if (isStringArray(filters)) {
    const productDict = _.keyBy(products, "key");
    return _.filter(_.map(filters, (f) => productDict[f]));
  }

  return _.filter(products, filters);
}

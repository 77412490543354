/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Box, jsx, Themed } from "theme-ui";
import React from "react";
import { ProductLink } from "./product-link";
import { productIDForMerchant, ProductPropsFrom, PureProductProps } from "../types";
import wrapPureProductComponent from "./wrap-pure-product-component";
import { useVariant } from "./variants";

export type CheckProductVariant = 0 | 1 | 2;

export type PureCheckProductProps = PureProductProps & {
  className?: string;
  textVariant?: "long" | "short";
  variant?: CheckProductVariant;
};

export const PureCheckProduct: React.FC<PureCheckProductProps> = ({
  productData,
  textVariant = "long",
  variant = 0,
  className,
}) => {
  const amazonProductId = productIDForMerchant(productData?.ids, "amazon");
  let storeName: string;
  // TODO: centralize this logic so it doesn't get out of sync with ProductLink; use displayName
  if (amazonProductId) {
    storeName = "Amazon";
  } else {
    return <></>;
  }

  const borderRadius = variant === 0 ? "100px" : "5px";
  const fontWeight = variant === 0 ? "normal" : "semibold";

  return (
    <Box className={className} sx={{ textAlign: "center", width: "100%" }}>
      <ProductLink product={productData} variant="styles.a" sx={{ display: "inline-flex" }}>
        <Themed.div
          sx={{
            backgroundColor: "secondary",
            borderRadius,
            "&:hover": { backgroundColor: "primary" },
            fontSize: ["md", "lg"],
            fontWeight,
            color: "white",
            verticalAlign: "middle",
            py: "3",
            px: "5",
          }}
        >
          Check Price {textVariant === "long" ? `on ${storeName}` : null}
        </Themed.div>
      </ProductLink>
    </Box>
  );
};

export type CheckProductProps = ProductPropsFrom<PureCheckProductProps>;
export const CheckProduct: React.FC<CheckProductProps> = wrapPureProductComponent(PureCheckProduct, ({ ...props }) => ({
  ...props,
  variant: useVariant("checkProduct", props.variant),
  textVariant: useVariant("checkProductText", props.textVariant),
}));

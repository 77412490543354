import _ from "lodash";
import React, { useContext } from "react";
import { Product } from "../types";

type State = {
  alternatives: readonly Product[];
  // summaryFields?: readonly string[];
};
type Action = { type: "addAlternative"; payload: Product };

const reducer = (state: State, { type, ...action }: Action): State => {
  switch (type) {
    case "addAlternative":
      if (!_.find(state.alternatives, (p) => p.key === action.payload.key)) {
        return { ...state, alternatives: [...state.alternatives, action.payload] };
      }
      return state;

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const ReviewContext = React.createContext<
  { state: State; addAlternative: (roundupProduct: Product) => void } | undefined
>(undefined);

interface ReviewProviderProps {
  alternatives?: readonly Product[];
  // summaryFields?: readonly string[];
}

export const useReviewContext = () => useContext(ReviewContext);
export const useReviewAlternatives = () => useReviewContext()?.state.alternatives;

export const ReviewProvider: React.FC<ReviewProviderProps> = ({
  alternatives = [],
  /* summaryFields = [], */ children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, { alternatives /* summaryFields */ });

  const addAlternative = (alternative: Product) => {
    dispatch({ type: "addAlternative", payload: alternative });
  };

  return <ReviewContext.Provider value={{ state, addAlternative }}>{children}</ReviewContext.Provider>;
};

export const ReviewConsumer = ReviewContext.Consumer;

import _ from "lodash";
import React, { useContext } from "react";
import { Product } from "../types";
import { useProducts } from "../use-products";

export const ProductContext = React.createContext(undefined as Product | undefined);

export interface ProductProviderProps {
  product?: string | Product;
}

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider: React.FC<ProductProviderProps> = ({ product, children }) => {
  const isString = _.isString(product);
  const productDataFromString = _.first(useProducts<Product>(isString ? product : undefined, !isString));

  let productData;
  if (isString) {
    productData = productDataFromString;
  } else if (product) {
    productData = product;
  }

  return <ProductContext.Provider value={productData}>{children}</ProductContext.Provider>;
};

export const ProductConsumer = ProductContext.Consumer;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import stringHash from "@sindresorhus/string-hash";
import _ from "lodash";
import { useTemplate } from "./template-context";

type TemplateProps = {
  className?: string;
  partial?: string;
  template?: string;
  product?: string;
  [x: string]: any;
};

const Template = ({ className, ...props }: TemplateProps): React.ReactNode => {
  const attrs = _.map(props, (value, name) => ({ name, value }));
  const attrString = JSON.stringify(_.sortBy(attrs, "name"));
  const hash = stringHash(attrString);
  // console.log("Template, hash=", hash)
  const template = useTemplate(`${hash}`);

  return template ? <MDXRenderer>{template.body}</MDXRenderer> : null;
};

export default Template;

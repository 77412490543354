/* eslint-disable import/prefer-default-export */
import { Variant } from "./types";
import { useVariantContext } from "./variant-context";

export function useVariant<TVariant extends Variant>(component: string, override?: TVariant): TVariant | undefined {
  const variantContext = useVariantContext() ?? {};

  if (override !== undefined) {
    return override;
  }

  return variantContext[component] as TVariant;
}

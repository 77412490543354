/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import { Term as BaseTerm, TermProps } from "gatsby-theme-glossary/src/components/term";
import React from "react";
import { useSchemaMarkupContext } from "gatsby-theme-faq";

export const Term: React.FC<TermProps> = (props) => {
  const schemaMarkup = useSchemaMarkupContext();
  const { children } = props;
  return schemaMarkup ? <>{children}</> : <BaseTerm {...props} />;
};

/** @jsx jsx */
import { jsx, SxProp } from "theme-ui";

type Props = SxProp & {
  className?: string;
};
const GradientOverlay: React.FC<Props> = ({ className }) => (
  <div
    className={className}
    sx={{
      height: "100%",
      width: "100%",
      background: "linear-gradient(#1670A0, #6EC1E4)",
      opacity: "0.5",
      alignItems: "center",
      justifyContent: "center",
    }}
  />
);

export default GradientOverlay;

/* eslint-disable import/prefer-default-export */
import { PluginOptions, WrapPageElementNodeArgs } from "gatsby";
import { filter, flatMap, flow, isEmpty, negate, uniq, values } from "lodash/fp";
import React from "react";

type MaybeHasReferences<ReferencesField extends string, T> = Record<ReferencesField, T[]>;

export type MaybeHasChildReferences<ChildField extends string, ReferencesField extends string, T> = Record<
  ChildField,
  MaybeHasReferences<ReferencesField, T>
>;

export type WrapPageElementNodeArgsWithChildReferences<
  ChildField extends string,
  ReferencesField extends string,
  T
> = WrapPageElementNodeArgs<
  Record<string, MaybeHasChildReferences<ChildField, ReferencesField, T>>,
  Record<string, MaybeHasChildReferences<ChildField, ReferencesField, T>>
>;

export type PagePropsWithChildReferences<
  ChildField extends string,
  ReferencesField extends string,
  T
> = WrapPageElementNodeArgsWithChildReferences<ChildField, ReferencesField, T>["props"];

const findReferences = <ChildField extends string, ReferencesField extends string, T>(
  containers: Record<string, MaybeHasChildReferences<ChildField, ReferencesField, T>>[],
  childField: ChildField,
  referencesField: ReferencesField
): T[] =>
  flow(
    flatMap(values),
    flatMap((x) => x && x[childField]),
    flatMap((x) => x && (referencesField ? x[referencesField] : x)),
    filter(negate(isEmpty)),
    uniq
  )(containers);

const findReferencesForPage = <ChildField extends string, ReferencesField extends string, T>(
  { data, pageContext }: PagePropsWithChildReferences<ChildField, ReferencesField, T>,
  childField: ChildField,
  referencesField: ReferencesField
): T[] => findReferences([data, pageContext], childField, referencesField);

export const makeWrapPageElementWithChildReferences =
  <ChildField extends string, ReferencesField extends string, T>(
    childField: ChildField,
    referencesField: ReferencesField,
    wrapper: (references: T[], args: WrapPageElementNodeArgs, options: PluginOptions) => React.ReactElement
  ) =>
  // TODO: Skip wrapper if no references found?
  (args: WrapPageElementNodeArgs, options: PluginOptions): React.ReactElement => {
    const references = findReferencesForPage(
      args.props as PagePropsWithChildReferences<ChildField, ReferencesField, T>,
      childField,
      referencesField
    );
    return wrapper(references, args, options);
  };

/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import { jsx } from "theme-ui";
import { GlossaryTermArticle as DefaultGlossaryTermArticle } from "gatsby-theme-glossary/src/components/glossary-term-article";
import { PropsOf } from "@emotion/react";
import { InlineFAQ } from "gatsby-theme-faq";
import SEO from "../../components/seo";
import { ArticleRenderer } from "../../components/article/article-renderer";
import ArticleJsonLd from "../../components/article/article-json-ld";

export const GlossaryTermArticle: React.FC<PropsOf<typeof DefaultGlossaryTermArticle>> = (props) => {
  const { term } = props;

  return (
    <ArticleRenderer article={term}>
      <ArticleJsonLd title={term.title} about={term.term} />
      <SEO title={term.title} excerpt={term.definition} />
      <InlineFAQ title={term.title} render="hidden">{term.definition}</InlineFAQ>
      <DefaultGlossaryTermArticle {...props} />
    </ArticleRenderer>
  );
};

/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { useSchemaMarkupContext } from "./schema-markup-context";

export const HideInSchema: React.FC = ({ children }) => {
  const schemaMarkup = useSchemaMarkupContext();
  return schemaMarkup ? null : <>{children}</>;
};

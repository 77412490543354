import { useStaticQuery, graphql } from "gatsby"
import filter from "lodash/filter";

const filterNodes = (data, filters, mainProperty = "title") => {
  const key = Object.keys(data)[0]
  const nodes = data[key].nodes

  if (!nodes) return []

  if (typeof filters === "string") {
    filters = {
      [mainProperty]: filters,
    }
  }

  return filter(nodes, filters)
}

export const useProfile = (filters) => {
  const data = useStaticQuery(graphql`
    query {
      allProfile(sort: { fields: [name], order: [ASC] }) {
        nodes {
          ...ProfileFragment
        }
      }
    }
  `)

  return filterNodes(data, filters, "name")
}

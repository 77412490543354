/** @jsx jsx */
import { Paragraph, jsx, Text } from "theme-ui";
import React from "react";
import { HideInSchema } from "gatsby-theme-faq";


export type CalloutProps = {
  className?: string;
  prefix: string;
  variant?: string;
};

export const Callout: React.FC<CalloutProps> = ({
  className, prefix, variant = "callout", children,
}) => (
  <HideInSchema>
    <Paragraph className={className} variant={variant}>
      <Text className="callout_prefix">{prefix}</Text> {children}
    </Paragraph>
  </HideInSchema>
);

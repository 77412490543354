/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import {
  CompareProductProps,
  CompareProduct as BaseCompareProduct,
} from "gatsby-plugin-products/src/components/compare-product";
import { ComparisonPost, RelatedPost } from "gatsby-theme-affiliate-posts";
import _ from "lodash";
import { useProduct } from "gatsby-plugin-products";

// TODO refactor CompareProduct so we don't need to shadow it and have some janky workarounds here.

export const CompareProduct: React.FC<CompareProductProps> = ({
  children,
  addFullReviewLink = true,
  product,
  ...props
}) => {
  const comparedTo = useProduct();
  const productData = useProduct(product);

  return (
    <BaseCompareProduct product={productData} {...props} addFullReviewLink={false}>
      {children}
      {comparedTo && product && (
        <ComparisonPost product={comparedTo} withProduct={_.isString(product) ? product : product.key} />
      )}
      {addFullReviewLink && productData?.review && <RelatedPost prefix="Full review:" post={productData.review} />}
    </BaseCompareProduct>
  );
};

/** @jsx jsx */
import React from "react";
import { jsx, Themed } from "theme-ui";
import { Stylable } from "../../utils/stylable";

const AffiliateDisclosure: React.FC<Stylable> = ({ className }) => (
  <Themed.div className={className}>As an Amazon Associate, I earn from qualifying purchases.</Themed.div>
);

export default AffiliateDisclosure;

import _ from "lodash";
import React, { ReactNode, useContext } from "react";
import { Product } from "../types";

export type RoundupProduct = {
  product: Product;
  recommendation?: ReactNode;
  recDetails?: ReactNode;
};

type State = {
  roundupProducts: readonly RoundupProduct[];
  summaryFields?: readonly string[];
};
type Action = { type: "add"; payload: RoundupProduct };

const reducer = (state: State, { type, ...action }: Action): State => {
  switch (type) {
    case "add":
      if (!_.find(state.roundupProducts, (rp) => rp.product.key === action.payload.product.key)) {
        return { ...state, roundupProducts: [...state.roundupProducts, action.payload] };
      }
      return state;

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const RoundupContext = React.createContext<
  { state: State; addProduct: (roundupProduct: RoundupProduct) => void } | undefined
>(undefined);

interface RoundupProviderProps {
  roundupProducts?: readonly RoundupProduct[];
  summaryFields?: readonly string[];
}

export const useRoundupContext = () => useContext(RoundupContext);

export const useRoundupProducts = () => useRoundupContext()?.state.roundupProducts;

export const RoundupProvider: React.FC<RoundupProviderProps> = ({
  roundupProducts = [],
  summaryFields = [],
  children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, { roundupProducts, summaryFields });

  const addProduct = (roundupProduct: RoundupProduct) => {
    dispatch({ type: "add", payload: roundupProduct });
  };

  return <RoundupContext.Provider value={{ state, addProduct }}>{children}</RoundupContext.Provider>;
};

export const RoundupConsumer = RoundupContext.Consumer;

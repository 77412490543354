/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { PageProps } from "gatsby";
import { GlossaryTermDetails } from "../types";
import Layout from "./layout";
import { GlossaryTermArticle } from "../components/glossary-term-article";

export type GlossaryTermPageContext = {
  term: GlossaryTermDetails;
};

const GlossaryTermPage: React.FC<PageProps<object, GlossaryTermPageContext>> = ({ pageContext: { term } }) => (
  <Layout>
    <GlossaryTermArticle term={term} />
  </Layout>
);

export default GlossaryTermPage;

import _ from "lodash";
import React, { useContext } from "react";
import { FAQ } from "../types";

type State = {
  faqs: readonly FAQ[];
};
type Action = { type: "add"; payload: FAQ };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "add":
      return !_.find(state.faqs, (f) => f === action.payload)
        ? { ...state, faqs: [...state.faqs, action.payload] }
        : state;

    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};

const FAQContext = React.createContext<{ state: State; addFAQ: (faq: FAQ) => void } | undefined>(undefined);

export const useFAQContext = () => {
  const context = useContext(FAQContext);
  if (!context) {
    throw new Error("useFAQContext must be used in the context of a FAQProvider");
  }
  return context;
};

export const useFAQs = () => useFAQContext().state.faqs;

interface FAQProviderProps {
  faqs?: readonly FAQ[];
}

export const FAQProvider: React.FC<FAQProviderProps> = ({ faqs = [], children }) => {
  const [state, dispatch] = React.useReducer(reducer, { faqs });

  const addFAQ = (faq: FAQ) => {
    dispatch({ type: "add", payload: faq });
  };

  return <FAQContext.Provider value={{ state, addFAQ }}>{children}</FAQContext.Provider>;
};

export const FAQConsumer = FAQContext.Consumer;

/** @jsx jsx */
import { jsx } from "theme-ui";
import isAbsoluteUrl from "is-absolute-url";
import ArticleHero from "../article/article-hero";

type Props = {
  post: Pick<GatsbyTypes.PostFragmentFragment, "image" | "imageFile">;
};

// This assumes absolute URLs are product images, others are local; we don't show product images
// in the title block because we don't know how good they'll look. Show them in the body of the article
// instead.
// Will need revisiting if we want other non-local URLs to be hero images.
const HeroImage: React.FC<Props> = ({ post: { image, imageFile } }) =>
  // TODO add types so we don't need to drill all the way down into imageFile
  !image || !isAbsoluteUrl(image) ? (
    <ArticleHero imageUrl={image} imageFile={imageFile?.childImageSharp?.gatsbyImageData} alt="TODO" />
  ) : null;

export default HeroImage;

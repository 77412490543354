import _ from "lodash";
import { dimensions, PropertyDescriptions } from "gatsby-plugin-products/src/product-field-descriptions";
import { isQuantityWithNote } from "gatsby-plugin-products";

const brand = {
  brand: "Brand",
};

const power = {
  "data.power": "Power Type",
  "data.voltage": "Power - Voltage",
  "data.amps": "Power - Amps",
  "data.hp": "Power - HP",
};

const defaultPropertyDescriptions: PropertyDescriptions = {
  ...brand,
  ...power,
  "data.noLoadRPM": "No-load RPM",
  "data.ripRight": "Rip Capacity (Right)",
  "data.ripLeft": "Rip Capacity (Left)",
  "data.bladeDiameter": "Blade Diameter",
  "data.bladeType": "Blade Type",
  "data.maxBevel": "Maximum Bevel",
  "data.depthOfCut90": "Depth of Cut (at 90°)",
  "data.depthOfCut45": "Depth of Cut (at 45°)",
  "data.maxDado": [
    "Maximum Dado Width",
    ({ data: { maxDado } = {} }) =>
      isQuantityWithNote(maxDado) && maxDado.quantity?.value === 0 && !maxDado.note ? false : maxDado,
  ],
  "data.arborSize": "Arbor Size",
  "data.dustPortDiameter": "Dust Port Diameter",
  // "data.length": "Length",
  // "data.width": "Width",
  // "data.height": "Height",
  "custom.dimensions": ["Dimensions", dimensions()],
  "data.weight": "Weight",
  "data.bladeSide": "Blade - Side",
  "data.drive": "Drive Mechanism",
  "data.barStyle": "Bar Style",
  "data.barLength": "Bar Length",
  "data.barDepth": "Bar Depth",
  "data.barWidth": "Bar Width",
  "data.fenceLength": "Fence Length",
  "data.fenceStopPrecision": "Fence Stop Precision",
  "data.precision": "Precision",
  "data.stops": "Stops",
  "data.tslotAdapter": "T-slot Adapter",
  "data.thickness": "Thickness",
  "data.kerf": "Kerf",
  "data.motor": "Motor Type",
  "data.toothBevel": "Tooth Bevel",
  "data.toothCount": "Tooth Count",
  "data.toothConfig": "Tooth Config",
  "data.toothMaterial": "Tooth Material",
  "data.rake": "Rake",
  "data.expansionSlots": "Expansion Slots",
  "data.strokeLength": "Stroke Length",
  "data.strokesPerMin": "Strokes/Minute",
  "data.teethPerInch": "Teeth per Inch",
  "data.warranty": "Warranty",
  pros: "Pros",
  cons: "Cons",
  included: "Included Accessories",
  notes: "Other Notes",
};

const omit = (...fields: string[]) => _.omit(defaultPropertyDescriptions, fields);

const productCategoryOverrides: Record<string, PropertyDescriptions> = {
  "miter gauge": {
    "custom.barDimensions": ["Bar Dimensions", dimensions("bar")],
    ...omit("data.barDepth", "data.barLength", "data.barWidth"),
  },
  "table saw": {
    ...brand,
    ...power,
    "custom.tableDimensions": ["Table Dimensions", dimensions("table")],
    "custom.miterSlotDimensions": ["Miter Slot Dimensions", dimensions("miterSlot")],
    "data.miterSlotShape": "Miter Slot Shape",
    ...omit("data.tableWidth", "data.tableHeight", "data.miterSlotWidth", "data.miterSlotHeight"),
  },
};

export default (productCategory?: string) =>
  (productCategory && productCategoryOverrides[productCategory]) || defaultPropertyDescriptions;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { PureRoundupRecap, PureRoundupRecapProps, RoundupRecapProps, useRoundupProducts } from "gatsby-plugin-products";
import { InlineFAQ } from "gatsby-theme-faq";
import { InlineFAQProps } from "gatsby-theme-faq/src/components/inline-faq";

export type PureRoundupRecapFAQProps = PureRoundupRecapProps & InlineFAQProps;

export const PureRoundupRecapFAQ: React.FC<PureRoundupRecapFAQProps> = ({ title, render, headingLevel, ...props }) => (
  <InlineFAQ title={title} render={render} headingLevel={headingLevel}>
    <PureRoundupRecap {...props} />
  </InlineFAQ>
);

export type RoundupRecapFAQProps = RoundupRecapProps & InlineFAQProps;

export const RoundupRecapFAQ: React.FC<RoundupRecapFAQProps> = (props) => {
  const roundupProducts = useRoundupProducts();
  if (!roundupProducts) {
    return null;
  }
  return <PureRoundupRecapFAQ {...props} roundupProducts={roundupProducts} />;
};

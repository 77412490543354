import {
  getImage as gatsbyGetImage,
  IGatsbyImageData,
  ImageDataLike as GatsbyImageDataLike,
} from "gatsby-plugin-image";

// gatsby-plugin-image's ImageDataLike is overly restrictive, in that it requires FileNode to have all Node properties,
//  when we only need childImageSharp. This is a simple wrapper around ImageDataLike/getImage that is more loosely typed.
type IGatsbyImageDataParent = {
  gatsbyImageData: IGatsbyImageData;
};
type IChildImageSharpParent = {
  childImageSharp?: IGatsbyImageDataParent;
};
const isChildImageSharpParent = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  node: IChildImageSharpParent | any
): node is IChildImageSharpParent => Boolean(node?.childImageSharp);

export type ImageDataLike = GatsbyImageDataLike | IChildImageSharpParent;
export const getImage = (node: ImageDataLike): IGatsbyImageData | undefined =>
  isChildImageSharpParent(node) ? node?.childImageSharp?.gatsbyImageData : gatsbyGetImage(node);

/** @jsx jsx */
import React, { ReactNode, useEffect } from "react";
import { Product } from "../types";
import { useRoundupContext } from "./roundup-context";
import { useProduct } from "../use-product";

export type RecommendationDetails = {
  category?: string;
  details?: ReactNode;
};

export type RecommendationProps = RecommendationDetails & {
  product?: string | Product;
};

export const Recommendation: React.FC<RecommendationProps> = ({ product, category, details, children }) => {
  const productData = useProduct(product);
  if (!productData) {
    throw new Error(`Could not find product ${product}`);
  }
  if (details && children) {
    console.error("Recommendation should have only one of details or children; ignoring details.")
  }

  const { addProduct } = useRoundupContext() ?? {};

  useEffect(() => {
    if (addProduct) {
      // TODO
      addProduct({ product: productData, recommendation: category, recDetails: children ?? details });
    }
  });

  return null;
};

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { ProductPropsFrom, PureProductProps, getProductName, describeFields } from "gatsby-plugin-products";
import wrapPureProductComponent from "gatsby-plugin-products/src/components/wrap-pure-product-component";
import { InlineFAQ } from "gatsby-theme-faq";
import _ from "lodash";
import { isPlural } from "pluralize";

type ProductFieldFAQProps = {
  productName: string;
  label: string;
  value: React.ReactNode;
};

const ProductFieldFAQ: React.FC<ProductFieldFAQProps> = ({ productName, label, value }) => {
  const isAre = isPlural(label) ? "are" : "is";
  return (
    <InlineFAQ title={`What ${isAre} the ${label} of the ${productName}?`} render="hidden">
      The {label} of the {productName} {isAre}: {value}
    </InlineFAQ>
  );
};

export type PureProductFAQProps = PureProductProps & {
  excludeFields?: string[];
};

export const PureProductFAQ: React.FC<PureProductFAQProps> = ({ productData, excludeFields = ["brand"] }) => {
  if (!productData) {
    return null;
  }
  const productName = getProductName(productData);
  return (
    <>
      {describeFields(
        productData,
        (_key, label, value) => (
          <ProductFieldFAQ {...{ label: label.toLowerCase(), value, productName }} />
        ),
        { excludeFields }
      )}
    </>
  );
};

export type ProductFAQProps = ProductPropsFrom<PureProductFAQProps> & {};

export const ProductFAQ: React.FC<ProductFAQProps> = wrapPureProductComponent(PureProductFAQ);

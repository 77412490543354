import _ from "lodash";
import { ReactNode } from "react";
import { Product } from "./types";
import { isQuantityWithNote, quantityWithNoteToString } from "./units";

export type CustomValueFormatter = (product: Product, field: string) => ReactNode;
export type PropertyDescription = string | ((property: string) => string);
export type PropertyDescriptions = Record<string, PropertyDescription | [PropertyDescription, CustomValueFormatter]>;

export const defaultPropertyDescriptions: PropertyDescriptions = {
  brand: "Brand",
  pros: "Pros",
  cons: "Cons",
  included: "Included Accessories",
  notes: "Other Notes",
};

// Common helpers

export const dimensions =
  (prefix?: string) =>
  ({ data = {} }: Product) => {
    // Generally we'll have only one of width or depth, depending on how the dimension is described.
    const dims = ["width", "length", "depth", "height"];
    const dimensionValues = _.filter(
      _.map(dims, (dim) => {
        const field = _.camelCase(`${prefix || ""} ${dim}`);
        const value = data[field];
        if (isQuantityWithNote(value)) {
          return `${quantityWithNoteToString(value)} ${_.upperCase(dim[0])}`;
        }
        return null;
      })
    );
    return _.isEmpty(dimensionValues) ? null : _.join(dimensionValues, " × ");
  };

export default (_productCategory?: string) => defaultPropertyDescriptions;

/** @jsx jsx */
import React from "react";
import { Box, jsx } from "theme-ui";
import { BgImage } from "gbimage-bridge";
import { IGatsbyImageData } from "gatsby-plugin-image";
import GradientOverlay from "./gradient-overlay";

export interface BackgroundHeroProps {
  image: IGatsbyImageData;
  style?: React.CSSProperties;
}

const BackgroundHero: React.FC<BackgroundHeroProps> = ({ children, image, ...props }) => (
  <BgImage
    image={image}
    style={{
      backgroundColor: "transparent",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      alignItems: "center",
    }}
    {...props}
  >
    <GradientOverlay sx={{ position: "absolute" }} />
    <Box
      sx={{
        display: "flex",
        position: "relative",
        height: "100%",
        justifyContent: "space-around",
        alignItems: "center",
        flexFlow: "row wrap",
      }}
    >
      {children}
    </Box>
  </BgImage>
);

export default BackgroundHero;

/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { ReactNode, useEffect } from "react";
import _ from "lodash";
import { CheckProduct } from "./check-product";
import { FullReviewLink } from "./full-review-link";
import { asParagraphs } from "./paragraphs";
import { ProductProsCons } from "./product-pros-cons";
import { ProductImageLink } from "./product-image-link";
import { ProductLink } from "./product-link";
import { ProductNameType } from "./product-name";
import { ProductPropsFrom, PureProductProps } from "../types";
import { ProductProvider } from "./product-context";
import wrapPureProductComponent from "./wrap-pure-product-component";
import { DynamicHeading, HeadingLevel } from "./dynamic-heading";
import { ProductSummaryBox } from "./product-summary-box";
import { Recommendation } from "./recommendation";
import { useVariant } from "./variants";

export type ShortReviewVariant = 0 | 1 | 2;

export type PureShortReviewProps = PureProductProps & {
  nameType?: ProductNameType;
  useImage?: boolean;
  recommendation?: string;
  recDetails?: ReactNode;
  headingLevel?: HeadingLevel;
  variant?: ShortReviewVariant;
  className?: string;
};

export const PureShortReview: React.FC<PureShortReviewProps> = ({
  nameType,
  productData,
  useImage = true,
  children,
  variant = 0,
  className,
  headingLevel = 3,
  recommendation,
  recDetails,
}) => {
  if (!productData) {
    return null;
  }

  const summaryBox = variant > 0;
  const checkPriceFooter = !summaryBox;
  const reviewLink = productData.review ? <FullReviewLink /> : null;
  const content = asParagraphs(children, reviewLink);

  const CheckProductFooter = () => (
    <footer>
      <CheckProduct variant={variant} />
    </footer>
  );

  const Image: React.FC<{ className?: string }> = (props) => (
    <ProductImageLink sx={{ img: { height: "auto" } }} {...props} />
  );

  // TODO: find a better way to do this, don't hardcode Recommendation
  const firstParagraph = _.findIndex(
    content,
    (c) => !(React.isValidElement(c) && c.props.mdxType === "Recommendation")
  );

  if (summaryBox) {
    const box = <ProductSummaryBox key="summary" useImage={useImage} />;
    content.splice(firstParagraph + 1, 0, box);
  } else if (useImage) {
    const image = <Image key="image" sx={{ img: { width: "50%" } }} />;
    content.splice(firstParagraph + 1, 0, image);
  }

  return (
    <ProductProvider product={productData}>
      {recommendation && <Recommendation category={recommendation} details={recDetails} />}

      <section className={className} sx={{ marginBottom: "8" }}>
        <header>
          <DynamicHeading headingLevel={headingLevel} id={productData.key}>
            <ProductLink nameType={nameType} variant="styles.a" />
          </DynamicHeading>
        </header>

        {content}

        <ProductProsCons
          headingLevel={Math.min(headingLevel + 1, 6) as 2 | 3 | 4 | 5 | 6}
          variant={variant}
          prosHeader={variant === 2 ? "Positives" : "Pros"}
          consHeader={variant === 2 ? "Negatives" : "Cons"}
          sx={{ mx: [0, 0, 4], mb: 4 }}
        />

        {checkPriceFooter && <CheckProductFooter />}
      </section>
    </ProductProvider>
  );
};

export type ShortReviewProps = ProductPropsFrom<PureShortReviewProps>;
export const ShortReview: React.FC<ShortReviewProps> = wrapPureProductComponent(PureShortReview, ({ ...props }) => ({
  ...props,
  variant: useVariant("shortReview", props.variant),
}));

export interface PluginConfig {
  tags?: string[];
  configureIntrospection?: boolean;
  attributes?: string[];
  fieldName?: string;
  propagateToTypes?: string[];
  addResolvers?: null | {
    returnType: string;
    by?: string;
    always?: boolean
  };
}

export const withDefaults = (pluginOptions?: PluginConfig): Required<PluginConfig> => ({
  tags: [],
  configureIntrospection: false,
  attributes: [],
  fieldName: "",
  propagateToTypes: [],
  addResolvers: null,
  ...pluginOptions,
});

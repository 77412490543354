/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import _ from "lodash";
import { ProductPropsFrom, ProductWithAlternatives, PureProductProps } from "../types";
import wrapPureProductComponent from "./wrap-pure-product-component";
import { ProductLinks } from "./product-links";

export type PureProductAlternativesProps = PureProductProps<ProductWithAlternatives> & {
  prefix?: string;
  suffix?: string;
};

export const PureProductAlternatives: React.FC<PureProductAlternativesProps> = ({ productData, prefix, suffix }) => {
  const validAlternatives = _.filter(productData?.alternatives, (p) => !p.discontinued);
  if (_.isEmpty(validAlternatives)) {
    return null;
  }

  return (
    <>
      <ProductLinks products={validAlternatives} prefix={prefix} suffix={suffix} />
    </>
  );
};

export type ProductAlternativesProps = ProductPropsFrom<PureProductAlternativesProps>;
export const ProductAlternatives: React.FC<ProductAlternativesProps> =
  wrapPureProductComponent(PureProductAlternatives);

import _ from "lodash";
import React, { useContext } from "react";
import { GlossaryTerm, isGlossaryTerm } from "../types";
import termsRegexp from "../utils/terms-regexp";

type GlossaryContextType = {
  // Map of lower-cased term to its GlossaryTerm
  terms: Record<string, GlossaryTerm>;
  termRegexp: RegExp;
};
export const GlossaryContext = React.createContext({ terms: {}, termRegexp: new RegExp("") } as GlossaryContextType);

export const useGlossary = () => useContext(GlossaryContext);
export const useGlossaryTerm = (term: string | GlossaryTerm): GlossaryTerm | undefined => {
  const glossary = useGlossary();
  return isGlossaryTerm(term) ? term : glossary.terms[_.toLower(term)];
};
interface Props {
  terms?: readonly GlossaryTerm[];
}

export const GlossaryProvider: React.FC<Props> = ({ terms = [], children }) => (
  <GlossaryContext.Provider
    value={{
      terms: _.keyBy(terms, (term) => _.toLower(term.term)),
      termRegexp: termsRegexp(_.map(terms, "term")),
    }}
  >
    {children}
  </GlossaryContext.Provider>
);

export const GlossaryConsumer = GlossaryContext.Consumer;

import React, { useContext } from "react";

const SchemaMarkupContext = React.createContext(false);

export const useSchemaMarkupContext = () => useContext(SchemaMarkupContext);

export const SchemaMarkupProvider: React.FC<{ inSchemaMarkup: boolean }> = ({ inSchemaMarkup, children }) => (
  <SchemaMarkupContext.Provider value={inSchemaMarkup}>{children}</SchemaMarkupContext.Provider>
);

export const SchemaMarkupConsumer = SchemaMarkupContext.Consumer;

/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { useProfile } from "@reflexjs/gatsby-theme-profile";
import { MDXRenderer } from "gatsby-plugin-mdx";

type Props = {
  author: string;
};

const AuthorBio = ({ author }: Props): React.ReactNode => {
  const [profile] = useProfile(author);
  return (
    <Themed.div sx={{ backgroundColor: "secondary", px: "6", pt: "6", pb: "1" }}>
      <Themed.div sx={{ display: "flex", alignItems: "center", mb: "6" }}>
        {/* TODO  <Image src={profile.picture} width="85px" mr="6" /> */}
        <Themed.div>
          <Themed.h3>{profile.name}</Themed.h3>
          <Themed.h4 sx={{ marginBottom: "0" }}>{profile.excerpt}</Themed.h4>
        </Themed.div>
      </Themed.div>
      <MDXRenderer>{profile.body}</MDXRenderer>
    </Themed.div>
  );
};

export default AuthorBio;

import { ThemeUIStyleObject } from "theme-ui";
import { Variant } from "./types";

export type StylesFor<TVariant extends Variant> = {
  [key in TVariant]: ThemeUIStyleObject;
};

export function stylesFor<TVariant extends Variant>(
  variant: TVariant,
  ...styles: StylesFor<TVariant>[]
): ThemeUIStyleObject[] {
  return styles.map((style) => style[variant]);
}

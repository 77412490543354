/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { PageProps } from "gatsby";
import { GlossaryTerm } from "../types";
import { Glossary } from "../components";
import Layout from "./layout";

export type GlossaryPageData = {
  terms: { nodes: GlossaryTerm[] };
};

const GlossaryPage: React.FC<PageProps<GlossaryPageData> & { className?: string }> = ({ className, data }) => (
  <Layout>
    <Glossary className={className} terms={data.terms.nodes} />
  </Layout>
);

export default GlossaryPage;

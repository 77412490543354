/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { ProductProvider, ProductsProvider, useProduct, useProducts } from "gatsby-plugin-products";
import { PostsProvider, usePosts } from "gatsby-theme-affiliate-posts";
import { FAQProvider, useFAQs } from "gatsby-theme-faq";
import { GlossaryProvider, useGlossary } from "gatsby-theme-glossary";
import _ from "lodash";
import { jsx } from "theme-ui";

export const useDuplicateContexts = (): React.FC => {
  const products = useProducts();
  const product = useProduct();
  const { terms } = useGlossary();
  const { posts } = usePosts();
  const faqs = useFAQs();

  const DuplicateContextProvider: React.FC = ({ children }) => (
    <ProductsProvider products={products}>
      <ProductProvider product={product}>
        <FAQProvider faqs={faqs}>
          <GlossaryProvider terms={_.values(terms)}>
            <PostsProvider posts={[...posts.values()]}>{children}</PostsProvider>
          </GlossaryProvider>
        </FAQProvider>
      </ProductProvider>
    </ProductsProvider>
  );
  return DuplicateContextProvider;
};

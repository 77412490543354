import * as React from "react";
import { PageProps } from "gatsby";
import _ from "lodash";
import Layout from "../layout";
import { Post } from "./post";
import PostFooter from "./post-footer";
import SEO from "../seo";

type Props = PageProps<GatsbyTypes.PostQuery>;

const PostTemplate = ({ data: { post, ...data } }: Props): React.ReactNode => {
  if (!post) {
    return null;
  }
  return (
    <Layout pathname={post.slug}>
      <SEO {...post} title={_.isEmpty(post.pageTitle) ? post.title : post.pageTitle} />
      <Post post={post} {...data} />
      <PostFooter post={post} />
    </Layout>
  );
};

export default PostTemplate;

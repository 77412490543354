/* @jsx jsx */
import { Container, jsx } from "theme-ui";
import { ReactElement } from "react";
import ShareButtons from "../share-buttons";
import RelatedPosts from "./related-posts";
import AmazonNativeAd from "../ads";

type Props = {
  post: GatsbyTypes.PostFragmentFragment;
};

const PostsFooter = ({ post }: Props): ReactElement => (
  <Container>
    <ShareButtons post={post} sx={{ textAlign: "center" }} />
    {/* TODO: Make adId configurable. */}
    <AmazonNativeAd adId="f7496c96-1056-454d-86b3-34529a38617a" sx={{ marginTop: 4 }} />
    <RelatedPosts posts={post.related} />
  </Container>
);

export default PostsFooter;

/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Container, jsx, Paragraph, Themed } from "theme-ui";
import React, { ReactElement } from "react";
import CategoryBreadcrumbs from "../category-breadcrumbs";
import ArticleJsonLd from "../article/article-json-ld";
import HeroImage from "./hero-image";
import { ArticleRenderer } from "../article/article-renderer";

type PostProps = {
  post: GatsbyTypes.PostFragmentFragment;
};

const TitleBlock = ({ post: { category, excerpt, title, ...post } }: PostProps) => (
  <Container as="header" sx={{ textAlign: "center", mb: 7 }}>
    <CategoryBreadcrumbs category={category} />
    <Themed.h1 sx={{ my: "auto" }}>{title}</Themed.h1>
    <HeroImage post={post} />

    <Paragraph sx={{ fontSize: "lg", mt: 7, fontWeight: "semibold", width: "80%", mx: "auto" }}>{excerpt}</Paragraph>
  </Container>
);

export const Post = ({ post }: PostProps): ReactElement | null => {
  const { body, category, title, datetime } = post;

  return (
    <>
      <ArticleJsonLd date={datetime} title={title} about={category?.title} />
      <ArticleRenderer article={post}>
        <TitleBlock post={post} />
        {body ? <MDXRenderer post={post}>{body}</MDXRenderer> : null}
      </ArticleRenderer>
    </>
  );
};

/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import { filterProducts } from "./utils/filter-products";
import { useProductContext } from "./components/product-context";
import { isProduct, Product, ProductBase } from "./types";
import { useProducts } from "./use-products";

export function useProduct<TProduct extends ProductBase = Product> (
  productOrFilters?: _.ListIterateeCustom<Product, boolean> | Product
): TProduct | Product | undefined  {
  const productContext = useProductContext();
  const productMatchingFilters = _.first(useProducts(productOrFilters));

  if (isProduct(productOrFilters)) {
    // If a product was explicitly provided, use it.
    return productOrFilters;
  }

  if (productOrFilters) {
    // Try to match against the specific product in ProductContext, if any, then fall back to check run against all available products.
    if (productContext) {
      const matchedContextProduct = _.first(filterProducts([productContext], productOrFilters));
      if (matchedContextProduct) {
        return matchedContextProduct;
      }
    }

    // This will be undefined if no products matched the filter.
    return productMatchingFilters;
  }

  // No filter, and no product explicitly provided, so use the ProductContext, if any.
  return productContext;
};

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import { RoundupProvider } from "./roundup-context";

export type RoundupProps = {
  summaryFields?: readonly string[];
};

export const Roundup: React.FC<RoundupProps> = ({
  summaryFields = [],
  children,
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <>
      <RoundupProvider summaryFields={summaryFields}>{children}</RoundupProvider>
    </>
  );
};

/** @jsx jsx */
import { Heading, HeadingProps, jsx } from "theme-ui";
import React from "react";

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type DynamicHeadingProps = HeadingProps & {
  headingLevel?: HeadingLevel;
  variant?: string;
};

export const DynamicHeading: React.FC<DynamicHeadingProps> = ({
  headingLevel = 1,
  variant = `styles.h${headingLevel}`,
  children,
  ...props
}) =>
  children ? (
    <Heading as={`h${headingLevel}`} sx={{ variant }} {...props}>
      {children}
    </Heading>
  ) : null;

import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Container } from "theme-ui";

type Props = {
  body?: string;
};

const Page = ({ body }: Props): React.ReactElement | null => (
  <Container sx={{ py: [8, 12, 14] }}>{body ? <MDXRenderer>{body}</MDXRenderer> : null}</Container>
);

export default Page;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React from "react";
import _ from "lodash";
import { useGlossary } from "./glossary-context";
import { Term } from "./term";

// eslint-disable-next-line import/prefer-default-export,@typescript-eslint/no-explicit-any
export const TermifyChildren: React.FC<{ tag: string }> = ({ tag: Tag, children, ...props }: any) => {
  const { terms, termRegexp } = useGlossary();

  // We only want to transform the first instance of each term in a paragraph.
  const transformed = new Set<string>();

  const transformedChildren = _.flatMap(_.isArray(children) ? children : [children], (child, childIndex) => {
    if (_.isString(child)) {
      return _.map(child.split(termRegexp), (matchedString, index) => {
        const term = terms[_.toLower(matchedString)];
        if (term) {
          // Only handle term once, no matter how many variants appear.
          const primaryTerm = term.primary?.term ?? term.term;
          if (!transformed.has(primaryTerm)) {
            transformed.add(primaryTerm);
            return (
              <Term term={term} key={`${childIndex}.${index}`}>
                {matchedString}
              </Term>
            );
          }
        }
        return matchedString;
      });
    }
    return child;
  });

  return <Tag {...props}>{transformedChildren}</Tag>;
};

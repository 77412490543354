/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import { Helmet } from "react-helmet-async";
import { helmetJsonLdProp } from "react-schemaorg";
import { jsx, SxProp } from "theme-ui";
import { BreadcrumbList } from "schema-dts";
import { buildAbsoluteURL } from "url-toolkit";
import Link from "../link";
import Breadcrumbs from "./breadcrumbs";
import { useSiteMetadata } from "../sitemetadata";

type Props = SxProp & {
  category?: GatsbyTypes.CategoryFragmentFragment;
  className?: string;
};

const CategoryBreadcrumbs = ({ category, className }: Props): React.ReactElement | null => {
  const siteMetadata = useSiteMetadata();
  if (!category) {
    return null;
  }
  // TODO if current page, do not wrap in link
  return (
    <>
      <Breadcrumbs
        className={className}
        sx={{
        }}
        crumbs={category.path.map((p) => (
          <Link to={p.slug} key={p.key} color="inherit">
            {p.title}
          </Link>
        ))}
      />
      <Helmet
        script={[
          helmetJsonLdProp<BreadcrumbList>({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            name: "Breadcrumbs",
            description: "Breadcrumbs",
            itemListElement: category.path.map((p, index) => ({
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                "@id": buildAbsoluteURL(siteMetadata.siteUrl, p.slug),
                name: p.title,
              },
              position: index + 1,
            })),
          }),
        ]}
      />
    </>
  );
};

export default CategoryBreadcrumbs;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, Text } from "theme-ui";
import React from "react";
import { Product, ProductPropsFrom, wrapPureProductComponent } from "gatsby-plugin-products";
import { get } from "@theme-ui/css";
import { useTheme } from "@emotion/react";
import { NavMenuPopup } from "../nav-menu";
import ComparisonsNavMenu from "./comparisons-nav-menu";

export type PureComparisonsLinkProps = {
  productData?: Product;
  className?: string;
  ignoreSlug?: string;
  maxGrid?: number;
  variant?: string;
  children?: React.ReactNode;
};

export const PureComparisonsLink: React.FC<PureComparisonsLinkProps> = ({
  productData,
  className,
  children,
  variant = "styles.comparison_link",
  ...props
}) => {
  // TODO: not pure
  const [state, setState] = React.useState({ showMenu: false });
  const theme = useTheme();

  const style = get(theme, variant);

  return (
    <>
      <Text
        onClick={() => setState((prev) => ({ ...prev, showMenu: !prev.showMenu }))}
        sx={{ ...style, gridArea: "menu" }}
        className={className}
      >
        {children ?? "other products"}
      </Text>
      <NavMenuPopup show={state.showMenu} onClose={() => setState((prev) => ({ ...prev, showMenu: false }))}>
        <ComparisonsNavMenu product={productData} {...props} />
      </NavMenuPopup>
    </>
  );
};

export type ComparisonsLinkProps = ProductPropsFrom<PureComparisonsLinkProps>;
const ComparisonsLink: React.FC<ComparisonsLinkProps> = wrapPureProductComponent(PureComparisonsLink);

export default ComparisonsLink;

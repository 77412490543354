import _ from "lodash";
import React, { useContext } from "react";
import { Post } from "../types";

type PostsContextType = {
  // Map of slugs to Posts
  posts: Map<string, Post>;
  renderRelated: boolean;
};
export const PostsContext = React.createContext({ posts: new Map() } as PostsContextType);

export const usePosts = () => useContext(PostsContext);

export const usePost = (filter: Post | string): Post | undefined => {
  const { posts } = usePosts();
  const post = _.isString(filter) ? posts.get(_.toLower(filter)) : filter;
  if (!post) {
    console.error(`usePost: No post found: ${String(filter)}`);
  }
  return post;
};

interface Props {
  posts?: readonly Post[];
  renderRelated?: boolean;
}

export const PostsProvider: React.FC<Props> = ({ posts = [], renderRelated = true, children }) => (
  <PostsContext.Provider
    value={{
      posts: new Map(_.map(posts, (post) => [_.toLower(post.slug), post])),
      renderRelated,
    }}
  >
    {children}
  </PostsContext.Provider>
);

export const PostsConsumer = PostsContext.Consumer;

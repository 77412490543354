import { isRecord } from "ts-is-record";

export type GlossaryTerm = {
  term: string;
  definition: string;
  path?: string;
  title: string;
  primary?: Pick<GlossaryTerm, "term">;
  page?: boolean;
  slug: string;
};

export type GlossaryTermDetails = GlossaryTerm & {
  body: string;
  variants?: GlossaryTerm[];
};

export function isGlossaryTerm(v: unknown): v is GlossaryTerm {
  return isRecord(v) && typeof v.term === "string" && typeof v.definition === "string";
}

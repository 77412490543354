import { Component, ReactNode } from "react";
import Tocbot from "tocbot";

export type TocbotComponentProps = {
  replacements?: Record<string, string>;
};

class TocbotComponent extends Component<TocbotComponentProps> {
  componentDidMount(): void {
    Tocbot.init({
      tocSelector: "#toc",
      contentSelector: "article",
      headingSelector: "h1, h2, h3",
      scrollSmooth: false,
      orderedList: false,
      // eslint-disable-next-line react/destructuring-assignment
      headingLabelCallback: (s: string) => this.props.replacements?.[s] ?? s
      //   listItemClass: "",
    });
  }

  componentDidUpdate(): void {
    Tocbot.refresh();
  }

  componentWillUnmount(): void {
    Tocbot.destroy();
  }

  render(): ReactNode {
    return null;
  }
}

export default TocbotComponent;

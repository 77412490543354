/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Grid, jsx, Link } from "theme-ui";
import React from "react";
import { FAQSection } from "gatsby-theme-faq";
import { getProductName } from "gatsby-plugin-products";
import _ from "lodash";
import TableOfContents from "./table-of-contents";
import AffiliateDisclaimer from "./affiliate-disclaimer";
import { ArticleContextProvider } from "./article-context-provider";
import { Article } from "./types";
import { useDuplicateContexts } from "../duplicate-contexts";
import { isDevelopment } from "../../utils/netlify-env";

export type ArticleRendererProps = {
  article: Article;
};

const CopyArticle = () => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link
    onClick={() => {
      const range = document.createRange();
      const articleElement = document.getElementsByTagName("article")[0];
      window.getSelection()?.removeAllRanges();
      range.selectNodeContents(articleElement);
      window.getSelection()?.addRange(range);
      document.execCommand("copy");
    }}
    sx={{pt: 4}}
  >
    Copy article [DEBUG]
  </Link>
);
export const ArticleRenderer: React.FC<ArticleRendererProps> = ({ article, children }) => {
  const { title, childReferencedProducts, reviewed } = article;

  if (!title) {
    return null;
  }

  // Tidy up table of contents by relabeling first paragraph as "Introduction" rather than the article title,
  //  and by replacing any headings that are a full product name with a shorter version.
  const tocReplacements = {
    [title]: "Introduction",
    ..._.reduce(
      childReferencedProducts?.products,
      (acc, product) => {
        acc[getProductName(product, "full")] = getProductName(product);
        return acc;
      },
      {} as Record<string, string> as any
    ),
  };

  const needsAffiliateDisclaimer = !_.isEmpty(childReferencedProducts?.products) || reviewed;

  return (
    <>
      {needsAffiliateDisclaimer && <AffiliateDisclaimer />}
      <Grid
        gap="0"
        sx={{
          gridAutoFlow: "dense",
          pt: "4",
          my: "0",
          gridTemplateColumns: (theme: any /* TODO Theme causes type errors */) => [
            "minmax(2ch, 1fr) minmax(0, 70ch) minmax(2ch, 1fr)",
            "",
            "",
            `minmax(2ch, 1fr) minmax(0, 30ch) minmax(0, ${theme.space?.[5] ?? 0}) minmax(30ch, 900px) ${
              theme.space?.[5] ?? 0
            } 30ch minmax(2ch, 1fr)`,
          ],
        }}
      >
        <div sx={{ gridColumn: ["2/3", null, null, "6/7"] }}>
          <TableOfContents replacements={tocReplacements} />
          {isDevelopment && <CopyArticle />}
        </div>

        {/* FAQProvider contains article, so any InlineFAQs in the article will get registered. */}
        <ArticleContextProvider {...article}>
          <article sx={{ gridColumn: ["2/3", null, null, "4/5"], pt: "10px", pb: "0" }}>
            {children}
            <FAQSection useContexts={useDuplicateContexts} />
          </article>
        </ArticleContextProvider>
      </Grid>
    </>
  );
};

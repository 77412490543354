/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, Themed } from "theme-ui";
import React from "react";
import _ from "lodash";
import { GlossaryTerm } from "../types";
import { GlossaryDefinition } from "./glossary-definition";

export type GlossarySectionProps = {
  heading: string;
  terms: GlossaryTerm[];
};

// eslint-disable-next-line arrow-body-style
export const GlossarySection: React.FC<GlossarySectionProps> = ({ heading, terms }) => {
  return (
    <section>
      <Themed.h2>{_.upperCase(heading)}</Themed.h2>
      <ul>
        {_.map(_.sortBy(terms, "term"), (term) => (
          <li key={`term_${term.term}`}>
            <GlossaryDefinition term={term} />
          </li>
        ))}
      </ul>
    </section>
  );
};

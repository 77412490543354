import React, { useContext } from "react";
import { Variant } from "./types";

// export interface Variants {
//   [component: string]: Variant;
// }

export type Variants = {
  [component: string]: Variant;
};

export const VariantContext = React.createContext(undefined as Variants | undefined);

export type VariantProviderProps = {
  variants?: Variants;
};

export const useVariantContext = () => useContext(VariantContext);

// TODO: handle nested providers
export const VariantProvider: React.FC<VariantProviderProps> = ({ children, variants = {} }) => (
  <VariantContext.Provider value={variants}>{children}</VariantContext.Provider>
);

export const VariantConsumer = VariantContext.Consumer;

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "theme-ui";
import React, { ReactNode } from "react";
import _ from "lodash";
import flattenChildren from "react-keyed-flatten-children";

type Props = {
  appending?: ReactNode;
};

const asParagraph = (x: any, force = false) => (React.isValidElement(x) && !force ? x : <p>{x}</p>);

export function asParagraphs(children: ReactNode, appending?: ReactNode): ReactNode[] {
  // If a single paragraph, append to it. If multiple paragraphs, the appended text will be a separate paragraph.
  if (_.isString(children)) {
    return [
      <p key=".0">
        {children} {appending}
      </p>,
    ];
  }

  const content = flattenChildren(children);
  if (appending) {
    content.push(asParagraph(appending, true));
  }

  return content.map((child, index) =>
    // eslint-disable-next-line react/no-array-index-key
    React.cloneElement(asParagraph(child), { key: `.${index}` })
  );
}

// Ensure children are wrapped in <P> if they are naked strings, optionally appending an eleemnt.
// If children is a single string, append the element directly to the string in the same paragraph.
// If multiple children, append the element as a standalone paragraph.
export const Paragraphs: React.FC<Props> = ({ children, appending }) => <>{asParagraphs(children, appending)}</>;
